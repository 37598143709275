import React from "react";
import { Searchbar } from "../Searchbar/Searchbar";
import { Select, Button, Flex, Box } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

type Props = {
  addButton?: boolean;
  handleSearchTerm?: (text: string) => void;
  searchButton?: boolean;
  sortBy?: string;
  sortOptions?: { value: string; label: string }[];
  textValue?: string;
  handleSortBy?: (value: string) => void;
};

const Filters = ({
  addButton = true,
  handleSearchTerm,
  searchButton = false,
  sortBy,
  sortOptions = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "most-popular", label: "Most Popular" },
    { value: "least-popular", label: "Least Popular" },
    { value: "published", label: "Published" },
    { value: "unpublished", label: "Unpublished" },
    { value: "a-z", label: "A-Z" },
    { value: "z-a", label: "Z-A" },
  ],
  textValue,
  handleSortBy,
}: Props) => {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      justifyContent={{ base: "center", md: "space-between" }}
      alignItems="center"
      mb={{ base: "1rem", md: "2rem" }}
      flexWrap="wrap"
      w="full"
    >
      <Box minW={{ base: "100%", md: "40vw" }}>
        <Searchbar
          placeholder="Search your Training Programs"
          SearchTerm={handleSearchTerm}
          searchButton={searchButton}
          textValue={textValue}
        />
      </Box>

      <Select
        w={{ base: "100%", md: "15vw" }}
        mb={{ base: "1rem", md: "0" }}
        mt={{ base: "1rem", md: "0" }}
        placeholder="Sort by"
        value={sortBy}
        onChange={(e) => (handleSortBy ? handleSortBy(e.target.value) : null)}
      >
        {sortOptions.map((elt) => (
          <option value={elt.value}>{elt.label}</option>
        ))}
      </Select>
      {addButton && (
        <Button
          px="1rem"
          variant="primary"
          leftIcon={<AddIcon />}
          as={Link}
          to="/create-program"
        >
          Add a Training Program
        </Button>
      )}
    </Flex>
  );
};

export default Filters;
