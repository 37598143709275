/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
import { greeting } from "../../../app/functions";
import HomeOfTrainings from "../../../components/HomeOfTrainings/HomeOfTrainings";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../store/store";

export default function UserHome() {
  const trainingData = useAppSelector(
    (state: RootState) => state.trainingProgramData.trainingPrograms
  );
  const [hasAtleast1TP, sethasAtleast1TP] = useState(true);
  // useEffect(() => {
  //   if(trainingData){

  //   if (trainingData.length >= 1) {
  //     sethasAtleast1TP(true);
  //   } else {
  //     sethasAtleast1TP(false);
  //   }
  // }

  // }, [trainingData.length, hasAtleast1TP]);
  const navigate = useNavigate();
  const handleCreateProgram = () => {
    navigate("/create-program");
  };
  return (
    <Box
      fontFamily="Montserrat"
      // onClick={() => sethasAtleast1TP(!hasAtleast1TP)}
      className="animate__animated animate__fadeIn"
      // maxW={"5xl"}
      w="100%"
    >
      {hasAtleast1TP ? (
        <HomeOfTrainings />
      ) : (
        <Stack
          textAlign={"center"}
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontFamily="Montserrat"
            textTransform="capitalize"
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            {greeting()}{" "}
            <Text as={"span"} color={useColorModeValue("#0C3186", "#9fb5e8")}>
              Kratos
            </Text>
          </Heading>
          <Text color={useColorModeValue("gray.500", "gray.50")} maxW={"3xl"}>
            Empower your expertise and inspire learning. Create engaging
            training programs that shape the future of education.
          </Text>
          <Stack spacing={6} direction={"row"}>
            <Button
              zIndex={1}
              display={{ base: "none", md: "inline-flex" }}
              fontSize={"sm"}
              fontWeight={600}
              color={"white"}
              bg={"#082D84"}
              px="30px"
              _hover={{ bg: "#1944ab" }}
              variant="primary"
              onClick={handleCreateProgram}
            >
              Create Training Program
            </Button>
            <Button
              variant="primaryOutline"
              as={Link}
              to="/learn-about-creation"
              px={6}
            >
              Learn more
            </Button>
          </Stack>
          <Flex m="0 auto" position="fixed" bottom="28%" left="50%" w={"full"}>
            {/* <Illustration
            height={{ sm: '24rem', lg: '28rem' }}
            mt={{ base: 12, sm: 16 }}
          /> */}
            <Text fontSize="sm">No training program created yet.</Text>
          </Flex>
        </Stack>
      )}
    </Box>
  );
}
