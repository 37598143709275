import React, { useState, useRef, useEffect } from "react";
import { Box, Text, HStack, IconButton, VStack, Image } from "@chakra-ui/react";
import { MdPause, MdPlayArrow } from "react-icons/md";
import buildFuture from "../../assets/videos/buildTheFuture.mp4";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import bringTeamTogether from "../../assets/videos/bringTeamTogether.mp4";
import seeYourIdeas from "../../assets/videos/seeYourIdeas.mp4";
import "./Carousel.css";

type Video = {
  title: string;
  description: string; // Added video description
  source: string;
};

const videos: Video[] = [
  {
    title: "Empower Your E-Learning Vision",
    description: "Unlock the potential to turn your e-learning visions into reality with our innovative tools.",
    source: buildFuture,
  },
  {
    title: "Visualize Ideas and Concepts",
    description: "Effortlessly visualize and simplify complex e-learning ideas and concepts for enhanced understanding.",
    source: bringTeamTogether,
  },
  {
    title: "Collaborative Teaching",
    description: "Unite your team members, regardless of their locations, to foster collaborative e-learning experiences.",
    source: seeYourIdeas,
  },
];

function Carousel() {
  const [currentVideo, setCurrentVideo] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(true); // Track video play state

  // Function to play the next video with a delay
  const playNextVideo = () => {
    if (currentVideo < videos.length - 1) {
      setCurrentVideo(currentVideo + 1);
    } else {
      setCurrentVideo(0);
    }
  };

  // Function to play the previous video
  const playPreviousVideo = () => {
    if (currentVideo > 0) {
      setCurrentVideo(currentVideo - 1);
    } else {
      setCurrentVideo(videos.length - 1);
    }
  };

  // Function to toggle play/pause
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Use useEffect to add a delay before playing the next video
  useEffect(() => {
    const delay = 8000; // 4 seconds
    const timer = setTimeout(() => {
      playNextVideo();
    }, delay);

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, [currentVideo, playNextVideo]);

  return (
    <Box>
      <Box
        pl="40px"
        pr="40px"
        m="0 auto"
        maxW="1100px"
        mt="24px"
        className="carousel-container"
      >
        <Box
          minH="380px"
          maxH="380px"
          m="0 auto"
          overflow="hidden"
          className="video-container"
        >
          <video
            ref={videoRef}
            controls={false}
            autoPlay
            muted
            src={videos[currentVideo].source}
            // onEnded={playNextVideo}
          ></video>
        </Box>

        <div className="video-details">
          <div className="texts">
            <Text fontSize="23px" fontWeight={700} as="h2">
              {videos[currentVideo].title}
            </Text>
            <Text>
              {videos[currentVideo].description}
            </Text>
          </div>
          <VStack>
            <HStack spacing={4}>
              <IconButton
                borderRadius="50%"
                icon={<ChevronLeftIcon />}
                aria-label="Previous"
                onClick={playPreviousVideo}
              />
              <IconButton
                borderRadius="50%"
                icon={isPlaying ? <MdPause /> : <MdPlayArrow />}
                aria-label="Pause/Play"
                onClick={togglePlayPause}
              />
              <IconButton
                icon={<ChevronRightIcon />}
                borderRadius="50%"
                aria-label="Next"
                onClick={playNextVideo}
              />
            </HStack>
            <Text fontSize="11px">
              {currentVideo + 1} of {videos.length}
            </Text>
          </VStack>
        </div>
      </Box>
    </Box>
  );
}

export default Carousel;
