// >> src/features/trainingProgram.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Define the initial state
const initialState = {
  isModalOpen: false,
};

// Create the jobData slice
export const utilitySlice = createSlice({
  name: "utilitySlice",
  initialState,
  reducers: {
    // Define reducers here
    toggleModal: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload ?? !state.isModalOpen;
    },
  },
});

// Export actions
export const { toggleModal } = utilitySlice.actions;

export default utilitySlice.reducer;
