import React, { Component } from "react";
import empty from "../../../assets/images/empty-mailbox-v2.png";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

function LiahtutorProfile() {
  return (
    <Box
      p="2rem"
      fontSize="sm"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      m="0 auto"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      textAlign="left"
    >
      <Flex justifyContent="space-between">
        <Box w="48%">
          <Box w="100%">
            <Text as="label" fontWeight={600}>
              First Name:
            </Text>
            <Input
              variant="outline"
              defaultValue="Kratos Orion"
              h="50"
              w="100%"
              type="text"
            />
          </Box>
          <Box mt="2rem">
            <Text as="label" fontWeight={600}>
              Last Name:
            </Text>
            <Input variant="outline" type="text" defaultValue="Maruh" h="50" />
          </Box>
          <Box mt="2rem">
            <Text as="label" fontWeight={600}>
              Headline:
            </Text>
            <Input
              variant="outline"
              type="text"
              placeholder="Tutor at Liah Technologies"
              h="50"
            />
          </Box>
          <Box mt="2rem">
            <Text as="label" fontWeight={600}>
              Biography:
            </Text>
            <Textarea />
            <Text fontSize="12px" mt=".8rem">
              To help learners learn more about you, your bio should reflect
              your Credibility, Empathy, Passion, and Personality. Your
              biography should have at least 50 words, links and coupon codes
              are not permitted.
            </Text>
          </Box>
          <Box mt="2rem">
            <Text as="label" fontWeight={600}>
              Language:
            </Text>
            <Select>
              <option value="en">English (CM)</option>
              <option value="cm">French (CM)</option>
            </Select>
          </Box>
        </Box>

        <Box w="48%">
          <Box w="100%">
            <Text as="label" fontWeight={600}>
              Website:
            </Text>
            <Input
              variant="outline"
              placeholder="https://my-website.com"
              h="50"
              w="100%"
              type="text"
            />
          </Box>
          <Box mt="2rem">
            <Text as="label" fontWeight={600}>
              X (Formerly known as Twitter):
            </Text>
            <InputGroup h="fit-content" w="100%">
            <InputLeftElement
                borderRight="1px solid gainsboro"
                h="50"
                w="fit-content"
                px=".5rem"
              >
                <Text>https://twitter.com/</Text>
              </InputLeftElement>
              <Input
                variant="outline"
                h="50"
                pl="35%"
                placeholder="Username"
              />
              
            </InputGroup>
          </Box>
          <Box mt="2rem">
            <Text as="label" fontWeight={600}>
              Facebook:
            </Text>
            <InputGroup h="fit-content" w="100%">
            <InputLeftElement
                borderRight="1px solid gainsboro"
                h="50"
                w="fit-content"
                px=".5rem"
              >
                <Text>https://facebook.com/</Text>
              </InputLeftElement>
              <Input
                variant="outline"
                h="50"
                pl="39%"
                placeholder="Username"
              />
              
            </InputGroup>
          </Box>
          <Box mt="2rem">
            <Text as="label" fontWeight={600}>
              LinkedIn:
            </Text>
            <InputGroup h="fit-content" w="100%">
            <InputLeftElement
                borderRight="1px solid gainsboro"
                h="50"
                w="fit-content"
                px=".5rem"
              >
                <Text>https://linkedin.com/</Text>
              </InputLeftElement>
              <Input
                variant="outline"
                h="50"
                pl="36%"
                placeholder="Resource ID"
              />
              
            </InputGroup>
          </Box>
          <Box mt="2rem">
            <Text as="label" fontWeight={600}>
              Youtube:
            </Text>
            <InputGroup h="fit-content" w="100%">
            <InputLeftElement
                borderRight="1px solid gainsboro"
                h="50"
                w="fit-content"
                px=".5rem"
              >
                <Text>https://youtube.com/</Text>
              </InputLeftElement>
              <Input
                variant="outline"
                h="50"
                pl="36%"
                placeholder="Username"
              />
              
            </InputGroup>
          </Box>
        </Box>
      </Flex>
      <Box h="4vh" />
      <Button variant="primary">Save</Button>
    </Box>
  );
}

export default LiahtutorProfile;
