import React from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  CloseButton,
  Button,
} from "@chakra-ui/react";

interface QuizManagementProps {
  quizzes: Quiz[];
  onDeleteQuestion: (index: number) => void;
}

const QuizManagement: React.FC<QuizManagementProps> = ({
  quizzes,
  onDeleteQuestion,
}) => {
  const handleDeleteQuestion = (index: number) => {
    onDeleteQuestion(index);
  };

  return (
    <VStack spacing={4} align="stretch">
      {quizzes.map((quiz, index) => (
        <Box
          key={index}
          p={4}
          borderWidth="1px"
          borderRadius="md"
          w="100%"
          position="relative"
        >
          <Text fontSize="lg">{quiz.question}</Text>
          <VStack spacing={2} align="stretch">
            {quiz.possibleAnswers.map((opt, optionIndex) => (
              <HStack key={optionIndex}>
                <Text>{`Option ${optionIndex + 1}: ${opt}`}</Text>
              </HStack>
            ))}
          </VStack>
          <CloseButton
            position="absolute"
            top={2}
            right={2}
            onClick={() => handleDeleteQuestion(index)}
          />
        </Box>
      ))}
      {quizzes.length === 0 && (
        <Text color="gray.500">No questions added yet.</Text>
      )}
      {/* <Button
        colorScheme="red"
        onClick={() => {
          onDeleteQuestion(-1); // Add a negative index to indicate adding a new question
        }}
      >
        Add Question
      </Button> */}
    </VStack>
  );
};

export default QuizManagement;
