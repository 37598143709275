import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Textarea,
  VStack,
  HStack,
  Spacer,
  CloseButton,
  Checkbox,
  RadioGroup,
  Radio,
  useToast,
} from "@chakra-ui/react";

interface QuizBuilderProps {
  onAddQuestion: (question: Quiz) => void;
}

interface Question {
  question: string;
  options: string[];
  answer: string;
  possibleAnswers: string[];
}

const QuizBuilder: React.FC<QuizBuilderProps> = ({ onAddQuestion }) => {
  const [question, setQuestion] = useState<string>("");
  const [answer, setAnswer] = useState<string | undefined>();
  const [options, setOptions] = useState<string[]>(["", "", "", ""]);
  const [possibleAnswers, setPossibleAnswers] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  const toast = useToast();

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
    setPossibleAnswers(newOptions);
  };

  const handleAddQuestion = () => {
    const trimmedQuestion = question.trim();
    const trimmedOptions = options.map((opt) => opt.trim());

    if (trimmedQuestion === "" || trimmedOptions.some((opt) => opt === "")) {
      // Don't add question if question or options are empty
      return;
    }
    if (!answer) {
      toast({
        title: "Select Answer",
        description: "Select the correct answer",
        status: "warning",
        isClosable: true,
      });
      return;
    }
    const newQuestion: Quiz = {
      question: trimmedQuestion,
      options: options,
      answer,
      possibleAnswers: options,
    };

    onAddQuestion(newQuestion);

    // Clear input fields
    setQuestion("");
    setOptions(["", "", "", ""]);
    setAnswer(undefined);
    setPossibleAnswers(["", "", "", ""]);
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
    setPossibleAnswers(newOptions);
  };

  return (
    <Box p={4} borderWidth="1px" borderRadius="md">
      <VStack spacing={4} align="stretch">
        <Textarea
          placeholder="Enter the question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <RadioGroup
          onChange={(e) => {
            let index = Number(e);
            const arr = options.map((elt) => elt);
            const answervalue = arr[index];
            setAnswer(answervalue);
          }}
        >
          {possibleAnswers.map((opt, index) => (
            <HStack key={index}>
              <Input
                mt="0.5em"
                placeholder={`Option ${index + 1}`}
                value={opt}
                onChange={(e) => handleOptionChange(index, e.target.value)}
              />
              <CloseButton
                size="sm"
                onClick={() => handleRemoveOption(index)}
              />
              <Radio value={index.toString()}></Radio>
            </HStack>
          ))}
        </RadioGroup>
        <Button
          variant="outline"
          colorScheme="telegram"
          onClick={handleAddQuestion}
        >
          Add Question
        </Button>
      </VStack>
    </Box>
  );
};

export default QuizBuilder;
