import { useState } from "react";
import empty from "../../../assets/images/empty-mailbox-v2.png";
import {
  Box,
  Image,
  Stack,
  Text,
  Switch,
  FormControl,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import Forum from "./Forum";

interface EmptyForumInterface {
  handleToggle: (toggle: boolean) => void
}
const EmptyForum = ({handleToggle}: EmptyForumInterface) => {
  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="center"
    >
     
          {" "}
          <Stack alignItems="center" m="0 auto">
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FormLabel fontSize="sm" htmlFor="demo-forum" mb="0">
                Enable demo forum?
              </FormLabel>
              <Switch
                onChange={() => handleToggle(true)}
                id="demo-forum"
              />
            </FormControl>
            <Image w="400px" src={empty} alt="No Q&A" />
            <Text fontWeight={700}>No questions yet</Text>
          </Stack>
          <Box m="0 auto" w="40%">
            <Text fontSize="sm">
              Q&A is a forum where your students can ask questions, hear your
              responses, and respond to one another. Here’s where you’ll see
              your courses’ Q&A threads
            </Text>
          </Box>
    </Box>
  )
}
function QnA() {
  const [demoForum, setDemoForum] = useState(false);
  function handleToggle(toggled: boolean) {
    setDemoForum(toggled)
  }
  if (demoForum) {
    return <Forum />;
  } else
  return <EmptyForum handleToggle={handleToggle} />;
}

export default QnA;
