import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import QnA from "./QnA/QnA";
import Messages from "./Messages/Messages";
import Assignments from "./Assignments/Assignments";
import Announcements from "./Announcements/Announcements";

interface TabProps {
  title: string;
  active: boolean;
  onSelect: (title: string) => void;
}

function Tab({ title, active, onSelect }: TabProps) {
  const backgroundColor = useColorModeValue(
    "rgba(255, 255, 255, 0.6)",
    "gray.800"
  );
  return (
    <Box
      p="1rem"
      backgroundColor={active ? backgroundColor : "transparent"}
      borderRadius="md"
      _hover={{
        cursor: "pointer",
        backgroundColor,
      }}
      onClick={() => onSelect(title)}
    >
      {title}
    </Box>
  );
}

function Communication() {
  const [activeTab, setActiveTab] = useState("Q&A");

  const handleTabSelect = (tabTitle: string) => {
    setActiveTab(tabTitle);
  };

  return (
    <Box pt="4rem" textAlign="center" m="0 auto">
      <Box h="2vh" />
      <Flex
        position="fixed"
        top="15%"
        left="46%"
        zIndex={3}
        fontSize="sm"
        border="1px solid gainsboro"
        w="fit-content"
        borderRadius="md"
        m="0 auto"
        boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
      >
        <Tab
          title="Q&A"
          active={activeTab === "Q&A"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Messages"
          active={activeTab === "Messages"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Assignments"
          active={activeTab === "Assignments"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Announcements"
          active={activeTab === "Announcements"}
          onSelect={handleTabSelect}
        />
      </Flex>
      <Box h="2vh" />
      {activeTab === "Q&A" && <QnA />}
      {activeTab === "Messages" && <Messages />}
      {activeTab === "Assignments" && <Assignments />}
      {activeTab === "Announcements" && <Announcements />}
    </Box>
  );
}

export default Communication;
