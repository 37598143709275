import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import anonymous from "../../../assets/images/anonymous_3.png";

function ProfilePicture() {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      // Generate a temporary URL for the selected image
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="left"
    >
      <Box mt="1rem" mb="1rem" fontSize="sm">
        <Text fontWeight={600}>Image preview</Text>
        <Text>Minimum 200x200 pixels, Maximum 6000x6000 pixels</Text>

        <Flex justifyContent="center" mt="1rem" h="40vh" p="1rem" border="1px solid gainsboro" borderRadius="md" w="100%">
          {/* Display the uploaded image or a placeholder image */}
          {selectedImage ? (
            <Image objectFit="cover" borderRadius="md" h="100%" src={selectedImage} alt="Profile pic" />
          ) : (
            <Image h="100%" src={anonymous} alt="Profile pic" />
          )}
        </Flex>
            <Box h="2vh" />
        {/* Input for image selection */}
        <Input
          type="file"
          h="50"
          alignContent="center"
          alignItems="center"
          p=".7rem"
          _hover={{ cursor: "pointer" }}
          accept="image/*" // Allow only image files
          onChange={handleImageChange}
        />
      </Box>

      {/* Button to save the uploaded image */}
      <Button variant="primary">
        Save
      </Button>
    </Box>
  );
}

export default ProfilePicture;
