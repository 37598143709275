import { Box } from "@chakra-ui/react";
import React from "react";
// import { Logo } from "../components/common/Logo";
import { Outlet } from "react-router-dom";
import { HeaderRoutes } from "../App";

type Props = {};

const AuthLayout = (props: Props) => {
  return (
    <Box>
      <Box
        h={{
          base: "0px",
          md: "6vh",
        }}
      >
        <HeaderRoutes />
      </Box>
      <Box
        minH={{
          base: "0px",
          md: "94vh",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthLayout;
