import { mode, whiten, darken } from "@chakra-ui/theme-tools";

export const ButtonStyles = {
  //  style the base or default style
  baseStyle: {
    fontSize: "sm",
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: (props: any) => ({
      bg: "primary",
      fontSize: "sm",
      color: "white",
      _hover: {
        bg: mode("#1944ab", "#0b2050")(props),
        boxShadow: "md",
      },
    }),
    primaryOutline: (props: any) => ({
      bg: "transparent",
      border: "1px solid",
      color: mode("primary", "white")(props),
      _hover: {
        transform: "scale(1.02)",
        border: "1px solid",
        boxShadow: "md",
      },
    }),
    secondary: (props: any) => ({
      bg: "secondary",
      color: "white",
      _hover: {
        bg: mode("#1944ab", "#0b2050")(props),
        boxShadow: "md",
      },
    }),
    tertiary: () => ({
      bg: "transparent",
      border: "1px solid #FFFFFF",
      color: "#FFFFFF",
      textTransform: "uppercase",
      _hover: {
        transform: "scale(1.02)",
        padding: "3px",
        border: "1px solid",
        boxShadow: "md",
      },
    }),
    special1: (props: any) => ({
      bg: "transparent",
      border: "1px solid gainsboro",
      color: mode("black", "white")(props),
      _hover: {
        transform: "scale(1.02)",
        border: "2px solid",
        boxShadow: "md",
      },
    }),
  },

  //  default values for `size` and `variant`
  defaultProps: {},
};
