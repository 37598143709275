import React from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

interface SearchbarProps {
  placeholder: string;
  SearchTerm?: (text: string) => void;
  searchButton?: boolean;
  textValue?: string;
}
export const Searchbar = ({
  placeholder,
  SearchTerm,
  searchButton,
  textValue,
}: SearchbarProps) => {
  return (
    <>
      <InputGroup borderRadius="md" size="md">
        <InputLeftElement
          pointerEvents="none"
          children={<Search2Icon color="gray.600" />}
        />
        <Input
          fontSize="sm"
          variant="outline"
          type="text"
          placeholder={placeholder}
          borderRadius="md"
          value={textValue}
          onChange={(e) =>
            SearchTerm ? SearchTerm(e.target.value.trim()) : null
          }
        />
        {!searchButton && (
          <InputRightAddon p={0} border="none">
            <Button
              variant="outline"
              size="md"
              borderLeftRadius={0}
              borderRightRadius="md"
            >
              Search
            </Button>
          </InputRightAddon>
        )}
      </InputGroup>
    </>
  );
};
