import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Image,
  Input,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import videoFile from "../../assets/videos/buildTheFuture.mp4";

import React, { useContext, useRef, useState } from "react";
import QuillEditor from "../QuillEditor/QuillEditor";
import { FaChalkboardTeacher } from "react-icons/fa";

import LinesEllipsis from "react-lines-ellipsis";
import QuizBuilder from "../QuizBuilder/QuizBuilder";
import QuizManagement from "../QuizBuilder/QuizManagement";
import CreateTrainingProgramContext from "../../store/Context";
import { AddIcon } from "@chakra-ui/icons";
import VideoCard from "../video/VideoCard";
import Videoplayet from "../../assets/images/videoPlayer.png";
import ReactPlayer from "react-player/lazy";
interface Props {
  moduleTitle: string;
  courseTitle: string | undefined;
}
const LessonFields = ({ courseTitle, moduleTitle }: Props) => {
  const [video, setVideo] = useState<string>("");
  const [videos, setVideos] = useState<string[]>([]);
  const [videoPath, setVideoPath] = useState("");
  const Context = useContext(CreateTrainingProgramContext);
  const course = Context?.program.courses.find(
    (elt) => elt.title === courseTitle
  );
  // ?.modules.find((elt) => elt.title === moduleTitle)?.lessons;
  const leessonList = course?.modules?.find(
    (elt) => elt.title === moduleTitle
  )?.lessons;
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [lesson, setLesson] = useState<Lesson>({
    description: "",
    notes: [],
    quizzes: [],
    title: "",
    videos: [],
  });
  const toast = useToast();
  const [toSetContent, setToSetContent] = useState(false);
  // const [canAdd, setCanAdd] = useState(true);
  const [displayBuilder, setDispBuilder] = useState(false);
  const [lemon, setLemon] = useState<CreateLessonState>({
    id: "",
    name: "",
    content: {
      notes: [],
      quizzes: [],
      videos: [],
    },
  });
  const [lessonNotes, setLessonNotes] = useState<string[]>([]);
  const handleNotesChange = (notes: string[]) => {
    setLessonNotes(notes);
    setLemon({
      ...lemon,
      content: {
        ...lemon.content,
        notes: lessonNotes,
      },
    });
  };
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  // Function to add a new quiz question
  const addQuestion = (newQuestion: Quiz) => {
    setQuizzes((prevQuizzes) => [...prevQuizzes, newQuestion]);
  };
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // Function to delete a quiz question by index
  const deleteQuestion = (index: number) => {
    setQuizzes((prevQuizzes) => {
      if (index === -1) {
        // Adding a new question
        return [
          ...prevQuizzes,
          { question: "", options: [], possibleAnswers: [], answer: "" },
        ];
      } else {
        // Deleting an existing question
        return prevQuizzes.filter((_, i) => i !== index);
      }
    });
  };
  const handleSaveLesson = () => {
    //title and description are required
    if (!lesson.title) {
      toast({
        title: "Empty Fields",
        description: "Lesson most have a title and description",
        status: "error",
        isClosable: true,
      });
      return;
    }
    Context?.dispatch({
      payload: {
        courseTitle,
        moduleTitle,
        lesson: {
          title: lesson.title,
          description: lesson.description,
          quizzes: quizzes,
          notes: lessonNotes,
          videos: videos,
        },
      },
      type: "addLesson",
    });
    toast({
      title: "Lesson Added",
      description: "Lesson has been added to the module",
      status: "success",
      isClosable: true,
    });
    //Clear the fields
    setLesson({
      notes: [],
      title: "",
      description: "",
      quizzes: [],
      videos: [],
    });
    setQuizzes([]);
    setLessonNotes([]);
    setVideos([]);
  };

  const handleAddALesson = () => {
    //Clear the fields
    setLesson({
      notes: [],
      title: "",
      description: "",
      quizzes: [],
      videos: [],
    });
    setQuizzes([]);
    setLessonNotes([]);
    setVideos([]);
  };
  const handleVideoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setVideo(e.target.files ? e.target.files[0] : null);
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setVideoPath(URL.createObjectURL(file));
      // setVideos((prevVideos) => [...prevVideos, URL.createObjectURL(file)]);
      setVideo(URL.createObjectURL(file));
    }
  };

  const handleDropVideo = (id: number) => {
    // setVideos((prevVideos) => prevVideos.filter((_, index) => index !== id));
    setVideo("");
  };

  return (
    <Box>
      <Stack mt="1rem" className="animate__animated animate__fadeInUp">
        <Stack minW="25%">
          <Input
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            ) => {
              setLesson({ ...lesson, title: e.target.value });
            }}
            value={lesson.title}
            w="100%"
            minH="59px"
            type="text"
            placeholder="Enter the name of your Lesson"
          />
        </Stack>
        <Center
          h={{
            base: "10em",
            md: "20em",
            lg: "30em",
          }}
          border={"1px"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          rounded="lg"
          flexDirection={"column"}
        >
          <Center>
            <label
              htmlFor="videoInput"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
                height: "100%",
              }}
            >
              <Image
                h="5em"
                objectFit="cover"
                title="Upload Video"
                src={Videoplayet}
                alt="Upload video"
              />{" "}
              <Text fontSize={"16px"} color={"gray.500"} textAlign={"center"}>
                Drag and drop or{"  "}
                <span
                  style={{
                    color: "blue",
                  }}
                >
                  select
                </span>{" "}
                a video
              </Text>
            </label>
            <Input
              ref={hiddenFileInput}
              id="videoInput"
              style={{ display: "none" }} // Make the file input element invisible
              type="file"
              accept="video/*"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleVideoUpload(e)
              }
            />
          </Center>
        </Center>
        <Flex wrap={"wrap"} maxW={"full"} gap={"0.5em"}>
          {video && (
            <VideoCard
              videoFile={video}
              id={0}
              dropVideo={handleDropVideo}
              key={video}
            />
          )}
        </Flex>

        <Box
          className="animate__animated animate__fadeIn"
          display={displayBuilder ? "block" : "none"}
        >
          <QuizBuilder onAddQuestion={addQuestion} />
          <QuizManagement quizzes={quizzes} onDeleteQuestion={deleteQuestion} />
        </Box>
        <Button
          onClick={() => setDispBuilder(!displayBuilder)}
          leftIcon={<FaChalkboardTeacher />}
        >
          Toggle Build Quiz Section
        </Button>
        <Box mt="1rem" mb="1rem">
          {lessonNotes.length > 0 ? (
            <Flex
              p="1rem"
              className="animate__animated animate__fadeIn"
              border="dashed 1px gainsboro"
              flexDir="column"
              maxW="80%"
            >
              {lessonNotes.map((l) => {
                return (
                  <Text
                    as={LinesEllipsis}
                    text={l}
                    maxLine="6"
                    ellipsis="..."
                    trimRight
                    fontSize="12px"
                    basedOn="letters"
                    color="gray.500"
                    fontFamily="monospace"
                    textTransform="lowercase"
                  />
                );
              })}
            </Flex>
          ) : null}
          <Text>Notes</Text>
          <QuillEditor onNotesChange={handleNotesChange} />
        </Box>
      </Stack>

      <Flex>
        {/* Button to save the coure */}
        <Button
          mt="1rem"
          ml="auto"
          colorScheme="blue"
          variant="solid"
          onClick={handleSaveLesson}
        >
          Save Lesson
        </Button>
      </Flex>
      <Box>
        <Button
          leftIcon={<AddIcon />}
          mt="2rem"
          variant="outline"
          onClick={handleAddALesson}
          colorScheme="telegram"
          w={{
            base: "fit-content",
            md: "8em",
          }}
        >
          Add a Lesson
        </Button>
      </Box>
      <Stack>
        {leessonList &&
          leessonList
            .slice()
            .reverse()
            .map((lesson, index) => {
              return (
                <Flex
                  mt="1rem"
                  borderRadius="md"
                  boxShadow="rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px"
                  alignItems="center"
                  gap="1rem"
                  justifyContent="space-between"
                >
                  <Flex>
                    <Badge
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {index + 1}
                    </Badge>
                    <Card p="1rem">{lesson.title}</Card>
                  </Flex>
                </Flex>
              );
            })}
      </Stack>
    </Box>
  );
};

export default LessonFields;
