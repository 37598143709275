import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import Overview from "./Overview/Overview";
import Students from "./Students/Students";
import Reviews from "./Reviews/Reviews";
import ProgramEngagement from "./ProgramEngagement/ProgramEngagement";
import TrafficAndConversion from "./TrafficAndConversion/TrafficAndConversion";

interface TabProps {
  title: string;
  active: boolean;
  onSelect: (title: string) => void;
}

function Tab({ title, active, onSelect }: TabProps) {
  const backgroundColor = useColorModeValue(
    "rgba(255, 255, 255, 0.6)",
    "gray.800"
  );
  return (
    <Box
      p="1rem"
      backgroundColor={active ? backgroundColor : "transparent"}
      borderRadius="md"
      _hover={{
        cursor: "pointer",
        backgroundColor,
      }}
      onClick={() => onSelect(title)}
    >
      {title}
    </Box>
  );
}


function Performance() {
  const [activeTab, setActiveTab] = useState("Overview");

  const handleTabSelect = (tabTitle: string) => {
    setActiveTab(tabTitle);
  };

  return (
    <Box textAlign="center" m="0 auto">
      <Box h="2vh" />
      <Flex
        fontSize="sm"
        border="1px solid gainsboro"
        w="fit-content"
        borderRadius="md"
        m="0 auto"
        boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
      >
        <Tab
          title="Overview"
          active={activeTab === "Overview"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Students"
          active={activeTab === "Students"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Reviews"
          active={activeTab === "Reviews"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Program Engagement"
          active={activeTab === "Program Engagement"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Traffic and Conversion"
          active={activeTab === "Traffic and Conversion"}
          onSelect={handleTabSelect}
        />
      </Flex>
      <Box h="2vh" />
      {activeTab === "Overview" && <Overview />}
      {activeTab === "Students" && <Students />}
      {activeTab === "Reviews" && <Reviews />}
      {activeTab === "Program Engagement" && <ProgramEngagement />}
      {activeTab === "Traffic and Conversion" && <TrafficAndConversion />}
    </Box>
  );
}

export default Performance;
