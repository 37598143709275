import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Select,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

interface stackContentProps {
  title: string;
  value: string;
  thisMonthValue: string;
  active: boolean;
  handleTabClick: (tab: string) => void;
}
// Component for displaying individual stack (tab) content
function StackContent({
  title,
  active,
  value,
  thisMonthValue,
  handleTabClick,
}: stackContentProps) {
  return (
    <Stack
      color={active ? "inherit" : "gray"}
      _hover={{
        cursor: "pointer",
      }}
      onClick={() => handleTabClick(title)}
      borderBottom={active ? "3px solid gainsboro" : "none"}
    >
      <Text>{title}</Text>
      <Text fontSize="40px" fontWeight={700}>
        {value}
      </Text>
      <Text>{thisMonthValue} this month</Text>
    </Stack>
  );
}

// Component for the data range control
function DataRangeControl() {
  return (
    <Flex w="fit-content" float="right" gap="1rem" alignItems="center">
      <Text textAlign="center">Data range:</Text>
      <Select w="fit-content" fontSize="sm" variant="flushed" fontWeight={600}>
        <option value="last12months">Last 12 months</option>
        <option value="last7days">Last 7 days</option>
        <option value="alltime">All time</option>
      </Select>
    </Flex>
  );
}

function Overview() {
  const [activeTab, setActiveTab] = useState("Total revenue");

  const tabContents: { [key: string]: React.ReactNode } = {
    "Total Revenue": "No data to display",
    "Total Enrollments": <div>Put your graph or content here</div>,
    "Tutor Rating": "No ratings this month",
  };
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="center"
    >
      <Text mb="1rem" fontSize="sm">Get top insights about your performance</Text>

      <Box
        borderRadius="md"
        fontSize="sm"
        textAlign="left"
        m="0 auto"
        w="80vw"
        border="1px solid gainsboro"
        h="70vh"
      >
        <Flex
          p=".6rem"
          pl="2rem"
          pb={0}
          gap="4rem"
          borderBottom="1px solid gainsboro"
        >
          {/* Display stack content using StackContent component */}
          <StackContent
            handleTabClick={handleTabClick}
            active={activeTab === "Total revenue"}
            title="Total revenue"
            value="XAF 0.00"
            thisMonthValue="XAF 0.00"
          />
          <StackContent
            handleTabClick={handleTabClick}
            active={activeTab === "Total enrollments"}
            title="Total enrollments"
            value="0"
            thisMonthValue="0"
          />
          <StackContent
            handleTabClick={handleTabClick}
            active={activeTab === "Tutor rating"}
            title="Tutor rating"
            value="0.00"
            thisMonthValue="0 ratings"
          />
        </Flex>

        <Box textAlign="center" h="50vh">
          <DataRangeControl /> {/* Render DataRangeControl component */}
          {activeTab === "Total revenue" && (
            <Text pt="4rem">No data to display</Text>
          )}
          {activeTab === "Total enrollments" && (
            <Text pt="4rem">No data to displayd</Text>
          )}
          {activeTab === "Tutor rating" && (
            <Text pt="4rem">No data to displayss</Text>
          )}
          {/* <Text pt="4rem">No data to display</Text> */}
        </Box>

        <Box pt=".6rem" textAlign="center" borderTop="1px solid gainsboro">
          <Flex
            fontSize="md"
            as={Link}
            to="/userzone"
            w="fit-content"
            m="0 auto"
            gap="1rem"
            color="primary"
            alignItems="center"
          >
            <Text textAlign="center">Get the report</Text>
            <FaAngleRight />
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}

export default Overview;
