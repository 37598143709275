const rootEndpoint = "http://localhost:3000/tutor";
const endpoints = [
  {
    name: "New Tutor SignUp/Register endpoint",
    description: "Used to Register a new tutor",
    method: "POST",
    url: rootEndpoint + "/auth",
  },
  {
    name: "Training Program Register endpoint",
    description: "Used to send training program data to server",
    method: "POST",
    url: rootEndpoint + "training-program",
  },
  {
    name: "Send automatic email",
    description: "Used to send automatic email to applicant",
    method: "POST",
    url: rootEndpoint + "email.php",
  },
  {
    name: "Internship Applicant Register endpoint",
    description: "Used to send intern data from Intern application to admin",
    method: "POST",
    url: rootEndpoint + "registerIntern.php",
  },
  {
    name: "Volunteer Applicant Register endpoint",
    description:
      "Used to send volunteer data from Volunteer application to admin",
    method: "POST",
    url: rootEndpoint + "registerVolunteer.php",
  },
  {
    name: "Get all Posted Jobs",
    description: "Used to get all the posted jobs",
    method: "GET",
    url: rootEndpoint + "postedJobs.php",
  },
  {
    name: "Get all Posted Internships",
    description: "Used to get all the posted Internships",
    method: "GET",
    url: rootEndpoint + "postedInternship.php",
  },
  {
    name: "Get all Posted Volunteerism opportunities",
    description: "Used to get all the posted Volunteerism",
    method: "GET",
    url: rootEndpoint + "postedvolunteer.php",
  },
];

export default endpoints;
