import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
  ModalCloseButton,
  useDisclosure,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../app/hooks";
import { toggleModal } from "../../features/utitlitySlice";
import authService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  onOpen?: boolean;
};

// const BackDrop = () => {
//   //   const { isModal } = useSelector((state: RootState) => state.modal);
//   const dispatch = useAppDispatch();

//   const closeModal = () => {
//     dispatch(toggleModal(false));
//   };
//   return (
//     <div
//       className="fixed min-h-screen h-screen z-10 top-0 left-0 w-full  bg-[#81b29a67]"
//       onClick={closeModal}
//     ></div>
//   );
// };

const Overley = ({ isOpen, onClose, onOpen }: Props) => {
  const toast = useToast();
  const navigate = useNavigate();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const closeModal = () => {
    dispatch(toggleModal(false));
  };
  const SignOut = () => {
    authService.logout();
    toast({
      title: "Sign out",
      description: "Signed out successfully",
      status: "warning",
      duration: 9000,
      isClosable: true,
    });
    closeModal();
    navigate("/");
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Are you sure?</ModalHeader>

        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={closeModal}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={SignOut}>
            Sign out
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Overley;
