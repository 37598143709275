import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import Notfound from "../../Pages/NotFound/Notfound";
import { Searchbar } from "../Searchbar/Searchbar";
import CourseImage from "../../assets/images/creator.jpg";
import CourseDetail from "./CourseDetail";
import { animateScroll as scroll } from "react-scroll";
import style from "./TrainingProgramDetail.module.css";
import Pagination from "../Paginations/Pagination";
const imge =
  "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
type Props = {};

const TrainingProgramDetail = (props: Props) => {
  const { programTitle, lessonTitle } = useParams<{
    programTitle: string;
    lessonTitle: string | undefined;
  }>();
  const [searchText, setSearchTerm] = useState("");
  const program = useAppSelector((state) => {
    const program = state.trainingProgramData.trainingPrograms.find(
      (elt) =>
        elt.title.toLowerCase() ===
        programTitle?.toLowerCase().split("-").join(" ")
    );
    return program;
  });
  const [firstPage, setFirstPage] = useState(0);
  const handleSearchTerm = (text: string) => {
    setSearchTerm(text);
  };
  const [courses, setCourses] = useState<Course[] | undefined>();
  const [selectedCourse, setSelectedCourse] = useState<Course>();

  useEffect(() => {
    setCourses(program?.courses);
  }, [program]);

  useEffect(() => {
    const courses = program?.courses.filter((course) =>
      course.title.toLowerCase().includes(searchText.toLowerCase())
    );

    setCourses(courses);
    setSelectedCourse(undefined);
  }, [searchText, program]);

  const handleNext = () => {
    console.log(courses);
    //Check if the next page is not empty
    if (courses?.length && firstPage + 4 <= courses.length) {
      setFirstPage(firstPage + 4);
    }
  };
  const handlePrevious = () => {
    //Check if the next page is not empty
    if (courses?.length && firstPage - 4 >= 0) {
      setFirstPage(firstPage - 4);
    }
  };

  if (!program) {
    return <Notfound />;
  }

  return (
    <Box w={"full"}>
      <Box bg="gray.100" h={{ base: "8em", md: "15vh" }} w="full ">
        <Image src={imge} h="full" w="full" objectFit="cover" />
      </Box>{" "}
      <VStack
        w="full"
        minH={{
          md: "60vh",
        }}
      >
        <Flex
          my="2em"
          w="full"
          justifyContent={{
            base: "unset",
            md: "space-between",
          }}
          flexDir={{
            base: "column",
            md: "row",
          }}
          alignItems={"center"}
        >
          <Heading>Courses</Heading>{" "}
          <Text color={"gray.400"} size={"lg"}>
            {program.category.toLowerCase().split("-").join(" ")}
          </Text>
          <Box>
            <Searchbar
              SearchTerm={handleSearchTerm}
              placeholder="Search a course"
            />
          </Box>
        </Flex>{" "}
        <Flex w="full">
          {" "}
          <Box
            p="unset"
            m="unset"
            w={{
              md: lessonTitle ? "50%" : "full",
            }}
            className={style["courses_container"]}
          >
            <Flex
              // mt={{ base: "unset", md: "1em" }}
              flexWrap={"wrap"}
              gap="1.5em"
              // justifyContent={'space-around'}
            >
              {!courses ||
                (!courses?.length && (
                  <Center
                    h={{
                      md: "50vh",
                    }}
                    w="full"
                  >
                    Not course found
                  </Center>
                ))}
              {courses?.slice(firstPage, firstPage + 4).map((course) => (
                <Card
                  key={course.title}
                  onClick={(e) => {
                    setSelectedCourse(course);
                    //Scroll to the rendered component
                    scroll.scrollToBottom({
                      duration: 2000,
                      // delay: 100,
                      smooth: true,
                    });
                  }}
                  border={
                    selectedCourse === course
                      ? "1px solid gainsboro"
                      : "inherit"
                  }
                  _hover={{
                    cursor: "pointer",
                    boxShadow: "lg",
                  }}
                  p="0"
                  w={{
                    // base: "8em",
                    md: "12em",
                  }}
                  // bg="white"
                >
                  <CardBody>
                    <Image
                      h="100%"
                      w="100%"
                      borderRadius="md"
                      objectFit="cover"
                      // boxShadow="md"
                      src={CourseImage}
                      alt={`${course.title}-course`}
                    />
                    <Text>{course.title.slice(0, 15) + "..."}</Text>
                  </CardBody>
                  <CardFooter display={"flex"} w="full" justifyContent={"end"}>
                    <Button variant="primary">View More</Button>
                  </CardFooter>
                </Card>
              ))}
            </Flex>
            {courses?.length && (
              <Pagination
                currentPagination={firstPage / 4 + 1}
                next={handleNext}
                previous={handlePrevious}
              />
            )}
            <Box>
              {courses?.length && selectedCourse ? (
                <CourseDetail course={selectedCourse} />
              ) : !courses?.length ? null : (
                <Center>
                  <Text>Select Course to see detail</Text>
                </Center>
              )}
            </Box>
          </Box>
          {lessonTitle && (
            <Box
              // mt="1.5em"
              pl={{ md: "1.5em" }}
              w={{
                md: "50%",
              }}
              borderLeft={"gray.300"}
              className="animate__animated animate__fadeIn"
            >
              <Outlet />
            </Box>
          )}
        </Flex>
      </VStack>
    </Box>
  );
};

export default TrainingProgramDetail;
