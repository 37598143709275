import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  user: User;
}

interface User {
  firstName: string;
  lastName: string;
  email: string;
  profilePicture?: string;
  tel: string;
  bio?: string;
  socials: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    linkedin?: string;
    github?: string;
    website?: string;
  };
}

const initialState: AuthState = {
  user: {
    firstName: "Kratos",
    lastName: "Orion",
    email: "tutor@liah.io",
    tel: "2222222",
    socials: {
      facebook: "https://www.facebook.com/kratos.orion.1",
      twitter: "https://twitter.com/kratos_orion",
      instagram: "https://www.instagram.com/kratos_orion/",
      linkedin: "https://www.linkedin.com/in/kratos-orion-2a5a8b1b7/",
    },
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signin(state, action: PayloadAction<{ user: User }>) {
      state.user = action.payload.user;
    },
    signout(state) {},
  },
});

export const { signin, signout } = authSlice.actions;
export default authSlice.reducer;
