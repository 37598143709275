/* eslint-disable class-methods-use-this */
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import endpoints from "../api/endpoints";

const API_URL = `${endpoints[0].url}`;
// const API_URL = 'http://localhost:3000/auth/';
interface LoginResponse {
  access_token: string;
  // Add other fields if present in the response
}
class AuthService {
  async signin(email: string, password: string) {
    const response = await axios.post<LoginResponse>(`${API_URL}/login`, {
      email,
      password,
    });
    if (response) {
      const decoded = jwtDecode(response.data.access_token);
      console.log(decoded)
      localStorage.setItem("user", JSON.stringify(decoded));
    }
    return response.data;
  }

  logout() {
    localStorage.removeItem("user");
  }

  signup(tel: number, name: string, email: string, password: string) {
    return axios.post(`${API_URL}/signup`, {
      tel,
      name,
      email,
      password,
    });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }
}
const authService = new AuthService();

export default authService;
