import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  Image,
  Heading,
} from "@chakra-ui/react";
import collab from "../../assets/images/ValueProps_Collaboration_v4.svg";
import clarity from "../../assets/images/ValueProps_Clarity_v4.svg";
import alignment from "../../assets/images/ValueProps_Alignment_v4.svg";
import "./SeeAndBuild.css";

function SeeAndBuild() {
  return (
    <Box
      w="100%"
      p="120px 0"
      mt="0"
      bg={useColorModeValue("#F2F3F5", "gray.900")}
      as="section"
    >
      <Box maxW="90rem" h="100%" m="0 auto" pos="relative" p="1rem">
        <div>
          <Flex alignItems="center" textAlign="center" flexDir="column">
            <Text
              fontSize="40px"
              fontWeight={700}
              lineHeight={1}
              mb="24px"
              mt="0"
              color="#1071E5"
              as="h2"
            >
              Shape the Future of E-Learning Together.
            </Text>
            <Text
              fontSize="20px"
              fontWeight={400}
              lineHeight={1.35}
              mt={0}
              mb="40px"
            >
              Empower your teaching with virtual whiteboarding, intelligent
              diagramming, and cloud-based visualization.
            </Text>
          </Flex>
          <Box>
            <Box className="illustrations">
              <Box className="featuredGridItem">
                <Image
                  mb="24px"
                  src={collab}
                  alt="Collaboration illustration"
                />
                <Box className="textContainer">
                  <Heading
                    as="h2"
                    lineHeight={1}
                    fontSize="20px"
                    mb="20px"
                    fontFamily="Montserrat"
                    mt={0}
                    fontWeight={700}
                  >
                    Collaboration
                  </Heading>
                  <Text
                    fontWeight={400}
                    lineHeight={1.35}
                    fontSize="16px"
                    mt={0}
                    mb="20px"
                  >
                    Foster collaborative learning, even when you can't be in the
                    same room. Enhance the quality of virtual lessons and
                    discussions.
                  </Text>
                </Box>
              </Box>
              <Box className="featuredGridItem">
                <Image mb="24px" src={clarity} alt="Clarity illustration" />
                <Box className="textContainer">
                  <Heading
                    as="h2"
                    lineHeight={1}
                    fontSize="20px"
                    mb="20px"
                    mt={0}
                    fontFamily="Montserrat"
                    fontWeight={700}
                  >
                    Clarity
                  </Heading>
                  <Text
                    fontWeight={400}
                    lineHeight={1.35}
                    fontSize="16px"
                    mt={0}
                    mb="20px"
                  >
                    Visualize complex educational concepts to simplify
                    understanding. Compare different teaching methods and their
                    impact on student learning.
                  </Text>
                </Box>
              </Box>
              <Box className="featuredGridItem">
                <Image mb="24px" src={alignment} alt="Alignment illustration" />
                <Box className="textContainer">
                  <Heading
                    as="h2"
                    lineHeight={1}
                    fontSize="20px"
                    fontFamily="Montserrat"
                    mb="20px"
                    mt={0}
                    fontWeight={700}
                  >
                    Alignment
                  </Heading>
                  <Text
                    fontWeight={400}
                    lineHeight={1.35}
                    fontSize="16px"
                    mt={0}
                    mb="20px"
                  >
                    Ensure all your educational projects are aligned with a
                    shared vision. Unify your team's teaching approach and focus
                    on delivering effective e-learning experiences.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );
}

export default SeeAndBuild;
