import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, HStack, IconButton, VStack } from "@chakra-ui/react";
import React from "react";

type Props = {
  next?: () => void;
  previous?: () => void;
  currentPagination?: number;
};

const Pagination = ({ next, previous, currentPagination }: Props) => {
  return (
    <Flex justifyContent={"flex-end"} mt="1em">
      <VStack>
        <HStack spacing={4}>
          <IconButton
            borderRadius="50%"
            icon={<ChevronLeftIcon />}
            aria-label="Previous"
            onClick={previous}
          />
          <span>{currentPagination}</span>{" "}
          <IconButton
            icon={<ChevronRightIcon />}
            borderRadius="50%"
            aria-label="Next"
            onClick={next}
          />
        </HStack>
      </VStack>
    </Flex>
  );
};

export default Pagination;
