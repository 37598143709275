import {
  Box,
  Center,
  Flex,
  Image,
  Input,
  Progress,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useRef, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
// import { ImageUploaderProps } from "../../types";
import ImageFolder from "../../assets/images/ImageFolder.jpg";
import { FaTimesCircle } from "react-icons/fa";

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageSelect,
  selectImage,
}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  // Programatically click the hidden file input element

  // when the Button component is clicked
  const handleClick = (event: any) => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };
  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      selectImage(file);
      // Ctx?.handleTrainingProgBasicInfo({ selectedImage: file });
      onImageSelect(file); // Passing the selected image to the parent component
    }
    // removeImage = () => {
    //   setSelectedImage(null);
    // };
  };
  const clearImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      {!selectedImage && (
        <Center>
          <Center flexDirection={"column"} p={"1em"}>
            <Box
              mb="1rem"
              _hover={{
                cursor: "pointer",
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;",
              }}
              borderRadius="md"
              w="fit-content"
              h="100px"
              boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
            >
              <Image
                h="100%"
                objectFit="cover"
                title="Upload Program Cover Image"
                onClick={handleClick}
                src={ImageFolder}
                alt="Upload image"
              />
            </Box>
            <Text
              as={LinesEllipsis}
              text="Upload Program Cover Image"
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
              fontSize="sm"
              color="gray.500"
            />
            <Input
              ref={hiddenFileInput}
              style={{ display: "none" }} // Make the file input element invisible
              type="file"
              accept="image/*"
              onChange={handleImageSelect}
            />

            {uploadProgress !== null && (
              <Box>
                <Text>Uploading ...</Text>
                <Progress value={uploadProgress} />
              </Box>
            )}
          </Center>

          {/* {selectedImage && (
            <Box maxW="170px">
              <Text
                as={LinesEllipsis}
                text={selectedImage.name}
                maxLine="3"
                ellipsis="..."
                trimRight
                basedOn="letters"
                fontSize="sm"
                color="gray.500"
              />
            </Box>
          )} */}
        </Center>
      )}
      {selectedImage && (
        <Flex position={"relative"}>
          <Image
            src={URL.createObjectURL(selectedImage)}
            alt="Selected"
            maxH="200px"
            objectFit="cover"
            borderRadius="md"
            boxShadow="rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;"
          />
          <FaTimesCircle color="tomato" size="1.5em" onClick={clearImage} />
        </Flex>
      )}
    </>
  );
};

export default ImageUploader;
