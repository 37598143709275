import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  useColorModeValue,
  useToast,
  Progress,
} from "@chakra-ui/react";
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FiLogOut } from "react-icons/fi";

import CreateTPForm from "./CreateTPForm";
import CreateCourse from "./CreateCourse";
import PreviewtTrainingProgram from "./previewtTrainingProram/PreviewtTrainingProgram";
import { useNavigate } from "react-router-dom";
import CreateTrainingProgramContext, {
  CreateTPProvider,
} from "../../store/Context";
import trainingProgram, {
  addNewTrainingProgram,
} from "../../features/trainingProgram";

const CreateTrainingProg = () => {
  const toast = useToast();
  const [hasBgunPutingCourse, setHasBgunPutingCourse] = useState(false);
  const Context = useContext(CreateTrainingProgramContext);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const trainingProgram = Context?.program;

  return (
    <Center
      py={6}
      pos={hasBgunPutingCourse ? "relative" : "inherit"}
      right="0"
      w="full"
    >
      <Box
        className="animate__animated animate__fadeIn"
        minW={{
          base: "145px",
          lg: Context?.step! <= 1 ? "max" : "75%",
        }}
        w={
          Context?.step! <= 1
            ? "max"
            : // : Context?.isSave && complete1 && Context?.step! !== 4
            Context?.isSave && Context?.step! === 4
            ? {
                base: "max",
                lg: "fit-content",
              }
            : {
                base: "max",
                lg: "fit-content",
              }
        }
        // w='500'
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue("white", "gray.900")}
        boxShadow="rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"
        rounded={"md"}
        p={6}
        overflow="visible"
      >
        <Progress
          borderRadius="md"
          hasStripe
          value={Context?.progress}
          mb="5%"
          maxW="79vw"
          isAnimated
        ></Progress>
        {Context?.step! <= 1 ? (
          <CreateTPForm />
        ) : Context?.isSave && Context?.step! <= 3 ? (
          <CreateCourse />
        ) : (
          Context?.program! &&
          Context?.step! === 4 && <PreviewtTrainingProgram />
        )}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  // setStep(Context?.step! - 1);
                  // setProgress(progress - progress);
                  if (Context?.step === 4) {
                    Context?.dispatch({
                      payload: {
                        value: Context?.progress - 100 / 3,
                      },
                      type: "progress",
                    });
                    Context?.dispatch({
                      payload: {
                        step: Context?.step - 2,
                      },
                      type: "step",
                    });
                  }
                  Context?.dispatch({
                    payload: {
                      value: Context?.progress - 100 / 3,
                    },
                    type: "progress",
                  });
                  Context?.dispatch({
                    payload: {
                      step: Context?.step - 1,
                    },
                    type: "step",
                  });
                }}
                isDisabled={Context?.step! < 3}
                colorScheme="blue"
                variant="solid"
                w="7rem"
                mr="5%"
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  if (Context?.step! <= 1) {
                    Context?.dispatch({
                      payload: {
                        step: 2,
                      },
                      type: "step",
                    });
                  }
                  if (Context?.step! >= 2 && !Context?.program.courses.length) {
                    toast({
                      title: "Can't Proceed",
                      description: "Save a coures to proceed",
                      status: "error",
                      isClosable: true,
                    });
                    return;
                  }
                  if (Context?.step! >= 2) {
                    Context?.dispatch({
                      payload: {
                        step: 3,
                      },
                      type: "step",
                    });
                    return;
                  }
                }}
                isDisabled={
                  (Context?.step! <= 1 && !Context?.isSave) ||
                  Context?.step! === 4
                }
                colorScheme="blue"
                variant="outline"
                minW="7rem"
                mr="5%"
              >
                Next
              </Button>
            </Flex>
            {Context?.step! === 3 && (
              <Button
                minW="7rem"
                rightIcon={<FiLogOut />}
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  // setStep(4);
                  Context?.dispatch({
                    payload: {
                      step: 4,
                    },
                    type: "step",
                  });
                  Context?.dispatch({
                    payload: {
                      value: 100,
                    },
                    type: "progress",
                  });
                }}
              >
                Preview
              </Button>
            )}
            {Context?.step! === 4 && (
              <Button
                minW="7rem"
                rightIcon={<FiLogOut />}
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  dispatch(addNewTrainingProgram(Context?.program!));
                  toast({
                    title: "Training Program created.",
                    description: "Your program has been successfully created",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                  setTimeout(() => {
                    navigate(
                      `/program/${trainingProgram?.title
                        .split(" ")
                        .join("-")}`,
                      {
                        // state: {
                        //   title: trainingProgram?.title,
                        // },
                      }
                    );
                  }, 2000);
                }}
              >
                Save and exit
              </Button>
            )}
          </Flex>
        </ButtonGroup>
      </Box>
    </Center>
  );
};

export default CreateTrainingProg;
