import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";

import React from "react";
import Lesson from "../Lesson/Lesson";
import { useParams } from "react-router-dom";
import { BsPencil } from "react-icons/bs";

type Props = {
  course: Course;
};

function CourseDetail({ course }: Props) {
  const { programTitle } = useParams<{
    lessonTitle?: string;
    programTitle?: string;
  }>();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [edit, setEdit] = React.useState("");
  const handleSave = () => {
    setIsModalOpen(false);
    toast({
      title: "Saved",
      description: "Your changes have been saved.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleEdit = (text: string) => {
    setIsModalOpen(true);
    setEdit(text);
  };
  return (
    <Box>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <ModalHeader></ModalHeader>
          <ModalBody>
            <Input value={edit} onChange={(e) => setEdit(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" onClick={handleSave}>
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex alignItems={"center"} gap="0.5em">
        <Text my="1em">{course.title}</Text>
        <BsPencil onClick={() => handleEdit(course.title)} />
      </Flex>
      <Tabs variant="enclosed">
        <TabList>
          {course.modules.map((module) => (
            <Tab key={module.title}>{module.title}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {course.modules.map((module) => (
            <TabPanel key={module.title}>
              {module.lessons.map((lesson) => (
                <Lesson
                  key={lesson.title}
                  lessonTitle={lesson.title}
                  lesson={lesson}
                  path={`/program/${programTitle!
                    .toLowerCase()
                    .split(" ")
                    .join("-")}/${lesson.title
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`}
                  courseTitle={course.title}
                  moduleTitle={module.title}
                />
              ))}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default CourseDetail;
