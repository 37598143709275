import { Box, keyframes } from "@chakra-ui/react";
import React from "react";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

const IsTyping = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box w="5px" h="5px" bg="primary" borderRadius="50%" mx="1" animation={`${pulse} 1s ease-in-out infinite 0.2s`} />
      <Box w="5px" h="5px" bg="primary" borderRadius="50%" mx="1" animation={`${pulse} 1s ease-in-out infinite 0.4s`} />
      <Box w="5px" h="5px" bg="primary" borderRadius="50%" mx="1" animation={`${pulse} 1s ease-in-out infinite 0.6s`} />
      <Box w="5px" h="5px" bg="primary" borderRadius="50%" mx="1" animation={`${pulse} 1s ease-in-out infinite 0.8s`} />
    </Box>
  );
};

export default IsTyping;
