import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.scss";
import { Button } from "@chakra-ui/react";

const QuillEditor: React.FC<{
  text?: string;
  width?: string;
  onNotesChange: (notes: string[]) => void;
}> = ({ onNotesChange, text, width }) => {
  const [editorHtml, setEditorHtml] = useState<string>(text ? text : "");
  const [notes, setNotes] = useState<string>('');

  const handleNotesCapture = () => {
    const currentNote = editorHtml; // Get the current note
    // setNotes((prevNotes) => [...prevNotes, currentNote]); // Add it to the notes array
    // setEditorHtml(""); // Clear the editor for the next note
    // onNotesChange([...notes, currentNote]); // Pass the updated notes array
    onNotesChange([currentNote]); // Pass the updated notes array
  };

  const [isMaximized, setIsMaximized] = useState(false);
  const toggleMaximized = () => {
    setIsMaximized(!isMaximized);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      ["bold", "italic", "underline", "strike", "code"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "code",
    "list",
    "bullet",
    "link",
    "image",
    "font",
  ];

  return (
    <div
      className={`quill-editor ${isMaximized ? "maximized" : ""}`}
      style={{
        width: width ? width : "unset",
      }}
    >
      <div className="toolbar">
        {/* Add your toolbar buttons here */}
        <button
          className={`maximise-btn ${isMaximized ? "minimise-btn" : ""}`}
          onClick={toggleMaximized}
        >
          {isMaximized ? "Minimize Editor" : "Maximize Editor"}
        </button>
      </div>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        style={{ height: "400px", width: width ? width : "100%" }}
        value={editorHtml}
        onChange={setEditorHtml}
      />
      <Button colorScheme="blue" onClick={handleNotesCapture}>
        Save Notes
      </Button>
    </div>
  );
};

export default QuillEditor;
