import React, { Component, useState } from "react";
import empty from "../../../assets/images/empty-mailbox-v2.png";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useAppSelector } from "../../../app/hooks";
import isEmail from "validator/es/lib/isEmail";

function AccountSecurity() {
  const [emailIsDisable, setEmailIsDisable] = useState(true);
  const [passwordIsDisable, setPasswordIsDisable] = useState(true);
  const { email } = useAppSelector((state) => state.auth.user);
  const toast = useToast();

  const [inputs, setInput] = useState({
    email: email,
    password: "",
  });
  const [isChange, setIsChange] = useState(false);

  const toggleInput = (field: string) => {
    if (field === "email") {
      setEmailIsDisable(!emailIsDisable);
      //focus on input
    } else if (field === "password") {
      setPasswordIsDisable(!passwordIsDisable);
    }
  };
  const handleCredentialUpdate = () => {
    // if (!isChange) return;
    //update credentials
    if (inputs.email === email) {
      toast({
        title: "Email is the same",
        description: "Please enter a new email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    //Validate email
    if (!isEmail(inputs.email)) {
      toast({
        title: "Invalid email",
        description: "Please enter a valid email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  };
  
  return (
    <Box
      p="2rem"
      fontSize="sm"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="50vw"
      m="0 auto"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      textAlign="left"
    >
      <Box>
        <Text as="label" fontWeight={600}>
          Email:
        </Text>
        <InputGroup h="fit-content" w="100%">
          <Input
            variant="outline"
            disabled={emailIsDisable}
            h="50"
            placeholder="kratos.orion@gmail.com"
            value={inputs.email}
            onChange={(e) => {
              setIsChange(true);
              setInput({ ...inputs, email: e.target.value });
            }}
          />
          <InputRightElement
            backgroundColor="gray.200"
            borderRightRadius="md"
            h="50"
          >
            <EditIcon
              color="blue.500"
              onClick={toggleInput.bind(null, "email")}
            />
          </InputRightElement>
        </InputGroup>
      </Box>
      <Box mt="2rem">
        <Text as="label" fontWeight={600}>
          Password:
        </Text>
        <InputGroup h="fit-content" w="100%">
          <Input
            variant="outline"
            type="password"
            disabled={passwordIsDisable}
            h="50"
            placeholder="*********"
            value={inputs.password}
            onChange={(e) => {
              setIsChange(true);
              setInput({ ...inputs, password: e.target.value });
            }}
          />
          <InputRightElement
            backgroundColor="gray.200"
            borderRightRadius="md"
            h="50"
          >
            <EditIcon
              color="blue.500"
              onClick={toggleInput.bind(null, "password")}
            />
          </InputRightElement>
        </InputGroup>
      </Box>
      <Flex mt="2rem" justifyContent={"flex-end"}>
        <Button
          isDisabled={!isChange}
          variant="solid"
          onClick={handleCredentialUpdate}
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
}

export default AccountSecurity;
