import {
  Box,
  Input,
  Text,
  Button,
  Center,
  VStack,
  Image,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

import image from "./assets/profile.png";
import upload from "./assets/upload-cloud.png";
import { Link, useNavigate } from "react-router-dom";
import { SendRequest } from "../../../../api/sendRequest";

type Props = {};

const Photo = (props: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileSize, setFileSize] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setFileSize(e.target.files[0]?.size);
    }
  };

  const handleUploadFile = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!file) return navigate("/onboarding/certification");

    if (fileSize > 3145728)
      //Max file size is set to 3MB
      return toast({
        status: "warning",
        title: "Fail",
        description: "File should be atmost 3MB",
      });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const base64String = reader.result?.toString() || "";
      localStorage.setItem("photo", base64String);
    };
    const formData = new FormData();

    formData.append("file", file);

    //Upload file
    const res = await SendRequest({
      method: "POST",
      url: "/photo",
      data: {
        formData,
      },
    });
    // navigate("/onboarding/certification");

    if (res.error)
      return toast({
        status: "error",
        title: "Upload Fail",
        description: res.error.message,
        isClosable: true,
      });
    navigate("/onboarding/certification");
  };
  return (
    <Center
      w={"full"}
      flex={"flex"}
      className="animate__animated animate__fadeIn"
    >
      <VStack
        w={{
          base: "90%",
          md: "50%",
        }}
      >
        <Text
          color="gray.500"
          fontSize={{
            base: "md",
            md: "lg",
          }}
          fontWeight={{
            base: "normal",
            md: "bold",
          }}
          mt={{
            base: "1em",
            md: "0",
          }}
          pt={{
            base: "3em",
            md: "em",
          }}
        >
          Profile Photo
        </Text>
        <Box
          as="p"
          fontSize={{
            base: "sm",
            md: "md",
          }}
          color="gray.500"
          w={{
            base: "75%",
            md: "50%",
          }}
        >
          Try to make your photo look professional. It will help you to get most
          students.
        </Box>
        <Box py="0.5em" flex={"flex"} alignItems={"center"}>
          <Input
            type="file"
            id="profileInput"
            accept="image/png, image/jpeg"
            hidden
            onChange={(e) => handleFileChange(e)}
          />
          <Button
            as="label"
            htmlFor="profileInput"
            bg="blue.500"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={useColorModeValue("white", "gray.900")}
          >
            Upload
          </Button>
          <Text
            as="span"
            fontSize={{
              base: "sm",
              md: "md",
            }}
            px="0.5em"
            color="gray.500"
          >
            Maximun 3MB
          </Text>
        </Box>
        <Box
          h={{
            base: "300px",
            md: "300px",
          }}
          w={{
            base: "300px",
            md: "300px",
          }}
          border={"3px dashed gray"}
          rounded={"3xl"}
          borderColor={"gray.300"}
          overflow={"hidden"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {file ? (
            <Image
              src={URL.createObjectURL(file)}
              h="full"
              w="full"
              overflow={"hidden"}
              outline={"none"}
            />
          ) : (
            <Center flexDir={"column"}>
              <Image
                src={upload}
                h={{
                  base: "50px",
                  md: "100px",
                }}
                w={{
                  base: "50px",
                  md: "100px",
                }}
                overflow={"hidden"}
                outline={"none"}
              />
              <Text
                fontSize={{
                  base: "sm",
                  md: "md",
                }}
                color="gray.500"
              >
                Upload an Image of you
              </Text>
            </Center>
          )}
        </Box>
        <VStack
          gap={"0.5em"}
          w={{
            base: "full",
            md: "300px",
          }}
          mt="2em"
          mb={"4em"}
        >
          <Button
            // bg="blue.500"
            w="full"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"blue.500"}
            _hover={{
              bg: "blue.500",
              color: "white",
              borderColor: "blue.500",
            }}
            borderStyle={"solid"}
            borderColor={"blue.400"}
            borderWidth={"2px"}
            bg={useColorModeValue("white", "gray.900")}
            as={Link}
            to={"/onboarding/certification"}
            onClick={(e) => handleUploadFile(e)}
          >
            Next
          </Button>
          <Button
            w="full"
            bg="blue.500"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"white"}
            _hover={{
              bg: "blue.200",
            }}
            isDisabled
          >
            Back
          </Button>
        </VStack>
      </VStack>
      <Box
        w="50%"
        justifyContent={"start"}
        alignContent={"center"}
        //Hidden on small screens
        display={{
          base: "none",
          md: "flex",
        }}
      >
        <Image
          src={image}
          // h={{
          //   base: "300px",
          //   md: "500px",
          // }}
          // w={{
          //   base: "300px",
          //   md: "500px",
          // }}
        />
      </Box>
    </Center>
  );
};

export default Photo;
