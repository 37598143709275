import React, { useContext, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../store/store";
import {
  Box,
  Button,
  Card,
  Center,
  Collapse,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import TPImg from "../../assets/images/ImageFolder.jpg";
import LinesEllipsis from "react-lines-ellipsis";
import CreateTrainingProgramContext from "../../store/Context";

type Props = {};

const CreateCourseHeader = (props: Props) => {
  const Context = useContext(CreateTrainingProgramContext);
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <Card
      className="animate__animated animate__fadeIn delay-5s"
      pos="inherit"
      top="20vh"
      left="20vw"
      minH="30vh"
      w="full"
    >
      {/* <Flex alignItems="center" flexDir="column-reverse">
        <Flex alignItems="center" flexDir="column-reverse" w="full">
          <Flex gap="2px" textAlign="center" w="fit-content">
            <Text
              as={LinesEllipsis}
              text={Context?.program.price.toString()}
              //   maxLine="3"
              //   ellipsis="..."
              trimRight
              basedOn="letters"
              fontSize="sm"
              color="gray.300"
            />
            <Text fontSize="sm" color="gray.300" as="span">
              XAF
            </Text>
          </Flex>
          <Text color="gray.300" fontSize="sm">
            Training Program
          </Text>
          <Box maxW="170px"  bg='red' w="fit-content">
            <Text
              textAlign="center"
              w="fit-content"
              fontSize="34px"
              fontWeight={700}
              as={LinesEllipsis}
              text={Context?.program.title}
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
              color="gray.500"
            />
          </Box>
        </Flex>

        <Box h={{ base: "auto", lg: "20vh" }} w={{ base: "auto", lg: "100%" }}>
          {Context?.program.image ? (
            <Image
              h="100%"
              w="100%"
              borderRadius="md"
              objectFit="cover"
              boxShadow="md"
              src={URL.createObjectURL(Context?.program.image)}
              alt={`trainingprogram${Context?.program.title}-cover`}
            />
          ) : (
            <Image
              h="100%"
              w="100%"
              borderRadius="md"
              objectFit="cover"
              boxShadow="md"
              src={TPImg}
              alt={`trainingprogram${Context?.program.title}-cover`}
            />
          )}
        </Box>
      </Flex> */}
      <Flex>
        <Box
          h={{ base: "6em", lg: "20vh" }}
          w={{ base: "full", lg: "100%" }}
          overflow={"hidden"}
        >
          {Context?.program.image ? (
            <Image
              h="100%"
              w="100%"
              borderRadius="md"
              objectFit="cover"
              boxShadow="md"
              src={URL.createObjectURL(Context?.program.image)}
              alt={`trainingprogram${Context?.program.title}-cover`}
            />
          ) : (
            <Image
              h="100%"
              w="100%"
              borderRadius="md"
              objectFit="cover"
              boxShadow="md"
              src={TPImg}
              alt={`trainingprogram${Context?.program.title}-cover`}
            />
          )}
        </Box>
      </Flex>
      <Box p="0.5em">
        <Collapse in={toggle} startingHeight={50} animateOpacity>
          {Context?.program.description}
        </Collapse>
        <Flex justifyContent={"right"}>
          <Button onClick={handleToggle}>
            Show {toggle ? "more" : "less"}
          </Button>
        </Flex>
      </Box>
      <Center w="full">
        <Text
          w="fit-content"
          fontSize="34px"
          fontWeight={700}
          as={LinesEllipsis}
          text={Context?.program.title}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
          color="gray.500"
        />
      </Center>
      <Center gap="2px" textAlign="center" flexDirection={"column"}>
        <Flex>
          <Text
            as={LinesEllipsis}
            text={Context?.program.price.toString()}
            maxLine="3"
            ellipsis="..."
            trimRight
            basedOn="letters"
            fontSize="sm"
            color="gray.300"
          />
          <Text fontSize="sm" color="gray.300" as="span">
            XAF
          </Text>
        </Flex>
        <Text color="gray.300" fontSize="sm">
          Training Program
        </Text>
      </Center>
    </Card>
  );
};

export default CreateCourseHeader;
