// >>src/components/CreateTrainingProg/CreateTrainingProg.ts
import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { FaMoneyBillWave, FaSave, FaTimesCircle } from "react-icons/fa";
import ImageUploader from "../ImageUploader/ImageUploader";

import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { saveTrainingProgramData } from "../../features/trainingProgram";
import xaf from "../../assets/images/xaf.png";
import { makeId } from "../../app/functions";
import CreateTrainingProgramContext from "../../store/Context";

interface Props {
  setComplete1: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSave: React.Dispatch<React.SetStateAction<boolean>>;
  handleProgress: (type: number) => void;
  setCurrentTrainingProgram: React.Dispatch<
    React.SetStateAction<{
      title: string;
      price: string | number;
      image: File;
      Course: Course[];
      id: string;
      published: {
        status: "unpublished";
        date: null;
      };
    } | null>
  >;
}

const categories = [
  {
    name: "Select Cateory",
    value: "",
  },
  {
    name: "Software Development",
    value: "software-development",
  },
  {
    name: "Web Design",
    value: "web-design",
  },
  {
    name: "Marketing",
    value: "marketing",
  },
  {
    name: "Business",
    value: "business",
  },
  {
    name: "Others",
    value: "others",
  },
];
const CreateTPForm = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();

  const Context = useContext(CreateTrainingProgramContext);
  // const trainingData = useAppSelector(
  //   (state: RootState) => state.trainingProgramData.data
  // );

  const [state, setState] = useState<TrainingProgram>({
    price: 0,
    image: null,
    id: makeId(),
    title: "",
    description: "",
    courses: [],
    category: "",
    published: {
      status: "unpublished",
      date: null,
    },
  });

  const handleSelectImage = (image: File) => {
    Context?.dispatch({
      payload: { image },
      type: "programImage",
    });
    setState((prevState) => {
      // if (prevState) {
      return {
        ...prevState,
        image: image,
      };
    });
  };

  const formatCurrency = (input: string) => {
    // Remove non-numeric characters
    const numericInput = input.replace(/[^0-9]/g, "");
    // Format with commas
    const formattedInput = numericInput.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedInput;
  };
  /*{ ...state, price: formattedInput }*/
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawInput = e.target.value;
    // Remove commas for processing
    const numericInput = rawInput.replace(/,/g, "");
    // Format the numeric input
    const formattedInput = formatCurrency(numericInput);
    // dispatcher({ type: "programTitle", payload: "tiit" });
    Context?.dispatch({
      payload: { price: rawInput },
      type: "programPrice",
    });
    setState((prevState) => {
      return {
        ...prevState,
        price: Number(rawInput),
      };
    });
  };

  const handleSaveTrainingProgram = async () => {
    // Remove commas and convert price back to a number for storing in Redux
    if (!state) {
      toast({
        title: "All fields is Required",
        description: "Please all the fields",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const priceWithoutCommas = state?.price.toString().replace(/,/g, "");
    const price = parseFloat(priceWithoutCommas);
    if (!state.price) {
      toast({
        title: "Price is Required",
        description: "Please fill in the Price field",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (priceWithoutCommas && priceWithoutCommas.length < 1) {
      toast({
        title: "Price is Required",
        description: "Please fill in the Price field",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (state.title.length < 1) {
      toast({
        title: "Title is Required",
        description: "Please Enter the Title of the Training Program",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (state.title.length < 5) {
      toast({
        title: "Title is too short",
        description:
          "Please Enter a Title of the Training Program longer than 5 characters",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!state.image) {
      toast({
        title: "Upload a Training Program Image",
        description: "It is required to upload a program image",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!state.description) {
      toast({
        title: "Description is required",
        description: "Please field the description of the program ",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (state.category === "") {
      toast({
        title: "Category is required",
        description: "Please field the category of the program ",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    //Save Program to database
    // const response = await trainingProgramService.saveCourses();
    Context?.dispatch({
      payload: {
        program: {
          ...state,
          courses: [],
        },
      },
      type: "saveProgram",
    });
    // setIsSave(true);
    Context?.dispatch({
      payload: {
        value: 100 / 3,
      },
      type: "progress",
    });
    // setComplete1(true);
    // Reset the state
    toast({
      title: "Saved state.",
      description: "You have saved the above fields",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    Context?.dispatch({
      payload: { step: 1 },
      type: "step",
    });
    Context?.dispatch({
      payload: { save: true },
      type: "save",
    });
  };

  const selectImage = (image: File | null) => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedImage: image,
      };
    });
  };

  return (
    <>
      <Heading fontWeight="bold" fontFamily="Montserrat">
        Create Training Program
      </Heading>
      <Text
        fontSize="sm"
        color={"gray.400"}
        maxW={{ base: "auto", lg: "29vw" }}
      >
        Designing educational content is a breeze with our platform. Start your
        training program today and join our community of educators making a
        difference.
      </Text>
      <Box mt="1rem" mb="1rem">
        <>
          <Flex
            flexDir={{ base: "column", lg: "inherit" }}
            gap="1rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack minW={{ base: "100%", lg: "70%" }}>
              <Input
                fontSize="sm"
                // value={state ? state.title : ""}
                // defaultValue={
                //   trainingData[0]?.title.length > 0
                //     ? trainingData[0]?.title
                //     : ""
                // }
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
                ) => {
                  setState((prevStat) => {
                    return { ...prevStat, title: e.target.value };
                  });
                }}
                w="100%"
                minH="50px"
                type="text"
                placeholder={`Enter your title here`}
              />
            </Stack>
            <Stack minW={{ base: "100%", lg: "28%" }}>
              <InputGroup minH="50px" w="100%">
                <Input
                  fontSize="sm"
                  // value={state ? state.price : ""}
                  onChange={handlePriceChange}
                  type="number"
                  minH="50px"
                  data-type="currency"
                  placeholder="Price (XAF)"
                />
                <InputRightAddon
                  minH="50px"
                  children={
                    state && state.price ? (
                      <Image h="24px" src={xaf} />
                    ) : (
                      <FaMoneyBillWave />
                    )
                  }
                />
              </InputGroup>
            </Stack>
          </Flex>
          <Stack minW={{ base: "100%", lg: "70%" }} mt={"0.5em"}>
            <Textarea
              placeholder={`Enter your description here`}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setState((prevStat) => {
                  return { ...prevStat, description: e.target.value };
                });
              }}
            />
          </Stack>
          <Flex gap="1.5em" mt={"0.5em"} alignItems={"center"}>
            <Text color={"gray.400"}>Category</Text>
            <Select
              fontSize="sm"
              variant="flushed"
              fontWeight={600}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setState((prevStat) => {
                  return { ...prevStat, category: e.target.value };
                });
              }}
            >
              {categories.map((category) => (
                <option value={category.value} color={"gray.600"}>
                  {category.name}
                </option>
              ))}
            </Select>
          </Flex>
        </>
      </Box>
      {/* <Box position="relative" h="100px"> */}
      <Box
        my="1em"
        borderRadius="lg"
        border="1px"
        borderColor="gray.200"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <>
          <ImageUploader
            onImageSelect={handleSelectImage}
            selectImage={selectImage}
          />
          {/* {state.selectedImage && (
            <Flex position={"relative"}>
              <Image
                src={URL.createObjectURL(state.selectedImage)}
                alt="Selected"
                maxH="200px"
                objectFit="cover"
                borderRadius="md"
                boxShadow="rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;"
              />
              <FaTimesCircle color="tomato" size="1.5em" onClick={clearImage} />
            </Flex>
          )} */}
        </>
      </Box>
      {/* </Box> */}

      <Button
        disabled={
          !state ||
          !state.title ||
          !state.price ||
          !state.image ||
          !state.category
        }
        leftIcon={<FaSave />}
        fontSize="sm"
        variant="solid"
        onClick={() => {
          handleSaveTrainingProgram();
        }}
      >
        Save Training Program
      </Button>
    </>
  );
};

export default CreateTPForm;
