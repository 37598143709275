import axios from "axios";

interface requestProps {
  method: string;
  url: string;
  data?: any;
  params?: any;
}
export const baseURL = "";
export const SendRequest = async (arg: requestProps) => {
  try {
    const response = await axios({
      method: arg.method,
      url: baseURL + arg.url,
      data: arg.data,
      params: { ...arg.params },
    });
    return response.data;
  } catch (error) {
    return {
      error: error,
      data: null,
      message: "Something went wrong",
    };
  }
};
