import React, { useContext, useEffect, useState } from "react";
import {
  Stack,
  Input,
  Textarea,
  Flex,
  Text,
  RadioGroup,
  Radio,
  Box,
  Button,
  Badge,
  Card,
  useToast,
} from "@chakra-ui/react";
import { FaLemon, FaSave } from "react-icons/fa";
import { AiTwotoneDelete } from "react-icons/ai";
import LessonFields from "../LessonFields";
import DodgingButton from "../../DodginButton/DodgingBtn";
import { AddIcon } from "@chakra-ui/icons";
import CreateTrainingProgramContext from "../../../store/Context";

type Props = {
  // module: CreateModuleSingleState;
  // setModule: React.Dispatch<React.SetStateAction<CreateModuleSingleState>>;
  lemonArr: CreateLessonState[];
  lemon: CreateLessonState;
  setLemon: React.Dispatch<React.SetStateAction<CreateLessonState>>;
  displayBuilder: boolean;
  setDispBuilder: React.Dispatch<React.SetStateAction<boolean>>;
  quizzes: Quiz[];
  editing: boolean;
  handleAddALesson: () => void;
  handleSaveLesson: (name: string) => void;
  handleEditLesson: (name: string) => void;
  // handleDeleteModule: (name: string) => void;
  addQuestion: (newQuestion: Quiz) => void;
  deleteQuestion: (index: number) => void;
  handleNotesChange: (notes: string[]) => void;
  lessonNotes: string[];
  course: Course | null;
  courseTitle: string;
};

const AddModule = (props: Props) => {
  const toast = useToast();
  const { course, setDispBuilder, courseTitle, lessonNotes } = props;
  const [onSave, setOnSave] = useState(false);
  const [module, setModule] = useState<Module>({
    title: "",
    description: "",
    lessons: [],
  });
  const [moduleList, setModuleList] = useState<Module[]>([]);

  const [wantLesson, setWantLesson] = useState("No");
  const Context = useContext(CreateTrainingProgramContext);

  useEffect(() => {
    if (Context?.program.courses) {
      const course = Context?.program.courses.find(
        (course) => course.title === courseTitle
      );
      if (course) {
        setModuleList(course.modules);
      }
    }
  }, [Context?.program.courses, courseTitle]);

  const toggleLesson = (e: string) => {
    //Check if the user has saved the module before adding a lesson
    if (!onSave || !module.title || !module.description)
      return toast({
        title: "Module not saved",
        description: "Please save the module before adding a lesson",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    setWantLesson(e);
  };
  const handleSaveModule = () => {
    if (!module.description || !module.title) {
      toast({
        title: "Add Module Failed",
        description: "All fields are required",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setOnSave(true);
    //Save in the module
    Context?.dispatch({
      payload: {
        courseTitle: course?.title,
        module,
      },
      type: "addModule",
    });
    // Clear the fields

    toast({
      title: "Module Saved",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleAddModule = () => {
    //Clear the fields

    setDispBuilder(false);
    setDispBuilder(true);
    setModule({
      title: "",
      description: "",
      lessons: [],
    });
  };

  return (
    <>
      <Stack mt="1rem" className="animate__animated animate__fadeInUp">
        <Stack minW="25%">
          <Input
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
            ) =>
              setModule((prev) => {
                return { ...prev, title: e.target.value };
              })
            }
            value={module.title}
            w="100%"
            minH="59px"
            type="text"
            placeholder="Enter the name of your module"
          />
        </Stack>
        <Stack>
          <Textarea
            minW="70%"
            minH="100px"
            value={module.description}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setModule((prev) => {
                return { ...prev, description: e.target.value };
              })
            }
            w="100%"
            placeholder="Description"
          />
        </Stack>
        <Flex>
          {/* Button to save the coure */}
          <Button
            mt="1rem"
            ml="auto"
            colorScheme="blue"
            variant="solid"
            onClick={handleSaveModule}
          >
            Save Module
          </Button>
        </Flex>
        {/* Lesson Entering Block */}
        <Flex
          flexDirection="column"
          borderRadius="md"
          p="1rem"
          boxShadow="rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px"
        >
          <Flex gap="1rem" alignItems="center">
            <Flex
              alignItems="center"
              borderRadius="50%"
              bg="gainsboro"
              boxShadow="rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px"
              justifyContent="center"
              height="2rem"
              width="2rem"
            >
              <FaLemon />
            </Flex>
            <Text fontWeight={400} fontSize="21px">
              Lesson(s)
            </Text>
          </Flex>
          <Text mt="1rem" fontSize="12px">
            Add a Lesson? *
          </Text>
          <RadioGroup
            mt="1rem"
            onChange={(e) => {
              toggleLesson(e);
            }}
            value={wantLesson}
          >
            <Stack fontSize="14px" gap="1rem" direction="row">
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Stack>
          </RadioGroup>

          {wantLesson === "Yes" && (
            <Box w="100%">
              <LessonFields
                moduleTitle={module.title}
                courseTitle={course?.title}
              />
            </Box>
          )}
        </Flex>
      </Stack>
      <Button
        leftIcon={<AddIcon />}
        mt="2rem"
        variant="outline"
        colorScheme="telegram"
        w={{
          base: "fit-content",
          md: "8em",
        }}        onClick={handleAddModule}
      >
        Add a Module
      </Button>
      <Stack>
        {moduleList.length &&
          moduleList
            .slice()
            .reverse()
            .map((module, index) => {
              return (
                <Flex
                  mt="1rem"
                  borderRadius="md"
                  boxShadow="rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px"
                  alignItems="center"
                  gap="1rem"
                  justifyContent="space-between"
                >
                  <Flex w="full"  >
                    <Badge
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    > 
                      {index + 1}
                    </Badge>
                    <Card p="1rem" w="full"     >
                      {module.title}
                    </Card>
                  </Flex>
                </Flex>
              );
            })}
      </Stack>
    </>
  );
};

export default AddModule;
