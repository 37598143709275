import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Image,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function PrivacySettings() {
  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="left"
    >
      <Stack>
        <Checkbox
          checked
          fontSize="sm"
          
          defaultChecked
          size="md"
          colorScheme="blue"
        >
          <Text>Show your profile to logged-in users</Text>
        </Checkbox>
        <Checkbox
          checked
          fontSize="sm"
          
          defaultChecked
          size="md"
          colorScheme="blue"
        >
          <Text>Show Training programs you are handling on your profile page</Text>
        </Checkbox>
      </Stack>
      <br />
      <br />
      <Button variant="primary">Save</Button>
    </Box>
  );
}
