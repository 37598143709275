"use client";
import {
  Text,
  Flex, useColorModeValue, useBreakpointValue,
  Image
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LiahLogo from "../../assets/images/Liah.a2f10f1b.svg";

export const Logo = (props: any) => {
  return (
    <Flex
      textAlign={useBreakpointValue({ base: "center", md: "left" })}
      fontFamily={"Montserrat"}
      as={Link}
      to="/"
      alignItems="center"
      fontWeight={800}
      justifyContent="left"
      color={useColorModeValue("#1944ab", "white")}
    >
      <Image
        border={useColorModeValue("1px solid gainsboro", "1px solid white")}
        p="2px"
        h="20px"
        w="20px"
        borderRadius="50%"
        boxShadow="lg"
        objectFit="contain"
        src={LiahLogo} />
      <Text mt="1px" as="span">
        Liahtutor
      </Text>
    </Flex>
  );
};
