import {
  Button,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <Flex
      alignItems="center"
      py={{ base: 20, md: 36 }}
      flexDir="column"
      w="100%"
      bg={useColorModeValue("#F2F3F5", "gray.900")}
      textAlign="center"
    >
      <Heading as="h1" 
        fontWeight={700}
        lineHeight={1}
      fontFamily="Montserrat"

        marginBottom="40px"
        fontSize="50px">Revolutionizing E-Learning Collaboration</Heading>
      <Text
        marginBottom="24px"
        fontSize="20px"
        lineHeight={1.35}
        >
        Liahtutor pioneers visual collaboration for e-learning tutors and empowers them to create training programs with ease.
      </Text>
      <Button
      zIndex={1}
        as={Link}
        display={{ base: "none", md: "inline-flex" }}
        fontSize={"sm"}
        fontWeight={600}
        color={"white"}
        bg={"#082D84"}
        px="30px"
        to={"/userzone"}
        _hover={{
          bg: "#1944ab",
        }}
      >
        Try Now
      </Button>
    </Flex>
  );
}
