import {
  Box,
  Text,
  Button,
  Center,
  VStack,
  Image,
  useColorModeValue,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import image from "./assets/bio.png";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SendRequest } from "../../../../api/sendRequest";

type Props = {};

const Bio = (props: Props) => {
  const [bio, setBio] = useState("");

  const toast = useToast();
  const navigate = useNavigate();

  const saveBio = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!bio)
      return toast({
        status: "warning",
        title: "Empty",
        description: "Boi is required",
      });

    const res = await SendRequest({
      method: "POST",
      url: "/updateprofile",
      data: {
        bio,
      },
    });

    if (res.error)
      return toast({
        status: "error",
        title: "Fail",
        description: res.error.message,
        isClosable: true,
      });

    navigate("/onboarding/review");
  };
  return (
    <Center
      w={"full"}
      flex={"flex"}
      className="animate__animated animate__fadeIn"
    >
      <VStack
        w={{
          base: "90%",
          md: "50%",
        }}
      >
        <Text
          color="gray.500"
          fontSize={{
            base: "lg",
            md: "lg",
          }}
          fontWeight={{
            base: "normal",
            md: "bold",
          }}
          mt={{
            base: "1em",
            md: "0",
          }}
          pt={{
            base: "3em",
            md: "em",
          }}
        >
          Talk About Yourself
        </Text>
        <Box
          as="p"
          fontSize={{
            base: "sm",
            md: "md",
          }}
          color="gray.500"
          w={{
            base: "90%",
            md: "80%",
          }}
        >
          Say something cool about your self. Keeping it professional will boost
          confidence of your potential students{" "}
        </Box>

        <Box
          w={{
            base: "90%",
            md: "80%",
          }}
        >
          <Textarea
            minH={{
              base: "10em",
              md: "15em",
            }}
            fontSize={{
              md: "1em",
            }}
            onChange={(e) => {
              setBio(e.target.value);
              localStorage.setItem("bio", e.target.value);
            }}
            color={useColorModeValue("gray.500", "gray.400")}
          />
        </Box>

        <VStack
          gap={"0.5em"}
          w={{
            base: "full",
            md: "80%",
          }}
          mt="2em"
          mb={"4em"}
        >
          <Button
            // bg="blue.500"
            w="full"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"blue.500"}
            _hover={{
              bg: "blue.500",
              color: "white",
              borderColor: "blue.500",
            }}
            borderStyle={"solid"}
            borderColor={"blue.400"}
            borderWidth={"2px"}
            bg={useColorModeValue("white", "gray.900")}
            as={Link}
            to={"/onboarding/review"}
            onClick={(e) => saveBio(e)}
          >
            Next
          </Button>
          <Button
            as={Link}
            to={"/onboarding/social"}
            w="full"
            bg="blue.500"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"white"}
            _hover={{
              bg: "blue.200",
            }}
          >
            Back
          </Button>
        </VStack>
      </VStack>
      <Box
        w="50%"
        justifyContent={"start"}
        alignContent={"center"}
        //Hidden on small screens
        display={{
          base: "none",
          md: "flex",
        }}
      >
        <Image
          src={image}
          h={{
            base: "300px",
            md: "500px",
          }}
          w={{
            base: "300px",
            md: "500px",
          }}
        />
      </Box>
    </Center>
  );
};

export default Bio;
