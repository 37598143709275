import {
  Box,
  Input,
  Text,
  Button,
  Center,
  VStack,
  Image,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import image from "./assets/Social.png";
import React, { useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaFacebook,
  FaLinkedin,
  FaGlobe,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { SendRequest } from "../../../../api/sendRequest";

type Props = {};

const socialMedia = [
  {
    name: "X",
    icon: <FaTwitter />,
    placeholder: "@johndoe",
  },
  {
    name: "Facebook",
    icon: <FaFacebook />,
    placeholder: "johndoe",
  },
  {
    name: "linkedin",
    icon: <FaLinkedin />,
    placeholder: "@johndoecoder",
  },
  {
    name: "Youtube",
    icon: <FaYoutube />,
    placeholder: "@johndoecoder",
  },
  {
    name: "Web",
    icon: <FaGlobe />,
    placeholder: "www.johndoe.com",
  },
];

const SocialInput = ({
  icon,
  name,
  valueHandler,
  placeholder,
}: {
  name: string;
  icon: React.ReactNode;
  valueHandler: React.Dispatch<{
    value: string;
    type: string;
  }>;
  placeholder?: string;
}) => (
  <Box
    display={"flex"}
    w="full "
    alignItems={"center"}
    h={{
      base: "2em",
      md: "2.5em",
    }}
  >
    {" "}
    <Box
      as="label"
      htmlFor={name}
      h="full"
      minW={{
        base: "3em",
        md: "3.5em",
      }}
      roundedLeft={"lg"}
      bg={useColorModeValue("blue.50", "gray.700")}
      alignItems={"center"}
      display={"flex"}
      justifyContent={"center"}
    >
      {icon}
    </Box>
    <Input
      type="text"
      roundedLeft={"none"}
      placeholder={placeholder}
      color={useColorModeValue("gray.500", "gray.400")}
      onChange={(e) =>
        valueHandler({ type: name.toLowerCase(), value: e.target.value })
      }
      id={name}
      h="full"
      w="full"
    ></Input>
  </Box>
);

const reducer = (
  state: {
    x: string;
    facebook: string;
    linkedin: string;
    web: string;
    youtube: string;
  },
  action: {
    value: string;
    type: string;
  }
) => {
  switch (action.type) {
    case "x": {
      localStorage.setItem(
        "social",
        JSON.stringify({ ...state, x: action.value })
      );
      return { ...state, x: action.value };
    }
    case "facebook": {
      localStorage.setItem(
        "social",
        JSON.stringify({ ...state, facebook: action.value })
      );
      return { ...state, facebook: action.value };
    }
    case "linkedin": {
      localStorage.setItem(
        "social",
        JSON.stringify({ ...state, linkedin: action.value })
      );
      return { ...state, linkedin: action.value };
    }
    case "youtube": {
      localStorage.setItem(
        "social",
        JSON.stringify({ ...state, youtube: action.value })
      );
      return { ...state, youtube: action.value };
    }
    case "web": {
      localStorage.setItem(
        "social",
        JSON.stringify({ ...state, web: action.value })
      );
      return { ...state, web: action.value };
    }
    default:
      return state;
  }
};

const initialValues = {
  x: "",
  facebook: "",
  linkedin: "",
  web: "",
  youtube: "",
};

const Social = (props: Props) => {
  const [socials, setSocials] = useReducer(reducer, initialValues);
  const toast = useToast();
  const navigate = useNavigate();

  const SaveSocials = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!socials.facebook && !socials.x && !socials.linkedin && !socials.web)
      return navigate("/onboarding/bio", {
        state: {},
      });

    const res = await SendRequest({
      method: "POST",
      url: "/social",
      data: {
        ...socials,
      },
    });

    if (res.error)
      return toast({
        status: "error",
        title: "Fail",
        description: res.error.message,
        isClosable: true,
      });
  };
  return (
    <Center
      w={"full"}
      flex={"flex"}
      className="animate__animated animate__fadeIn"
    >
      <VStack
        w={{
          base: "90%",
          md: "50%",
        }}
      >
        <Text
          color="gray.500"
          fontSize={{
            base: "md",
            md: "lg",
          }}
          fontWeight={{
            base: "normal",
            md: "bold",
          }}
          mt={{
            base: "1em",
            md: "0",
          }}
          pt={{
            base: "3em",
            md: "em",
          }}
        >
          Social Media
        </Text>
        <Box
          as="p"
          fontSize={{
            base: "sm",
            md: "md",
          }}
          color="gray.500"
          w={{
            base: "75%",
            md: "80%",
          }}
        >
          Adding your social media handles help students to link with you out of
          Liahtutor. This help to expan the learning community.
        </Box>
        <VStack
          w={{
            base: "100%",
            md: "80%",
          }}
        >
          {socialMedia.map((elt) => (
            <SocialInput
              key={elt.name}
              placeholder={elt.placeholder}
              icon={elt.icon}
              name={elt.name}
              valueHandler={setSocials}
            />
          ))}
        </VStack>
        <Box w="80%">
          <Text
            fontSize={{
              base: "xs",
              md: "sm",
            }}
            textAlign={"right"}
            color={useColorModeValue("gray.400", "white")}
          >
            You could vote to skip this section, It could be done later in
            Profile
          </Text>
        </Box>

        <VStack
          gap={"0.5em"}
          w={{
            base: "full",
            md: "80%",
          }}
          mt="2em"
          mb={"4em"}
        >
          <Button
            // bg="blue.500"
            w="full"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"blue.500"}
            _hover={{
              bg: "blue.500",
              color: "white",
              borderColor: "blue.500",
            }}
            borderStyle={"solid"}
            borderColor={"blue.400"}
            borderWidth={"2px"}
            bg={useColorModeValue("white", "gray.900")}
            as={Link}
            to={"/onboarding/bio"}
            onClick={(e) => SaveSocials(e)}
          >
            Next
          </Button>
          <Button
            as={Link}
            to={"/onboarding/certification"}
            w="full"
            bg="blue.500"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"white"}
            _hover={{
              bg: "blue.200",
            }}
          >
            Back
          </Button>
        </VStack>
      </VStack>
      <Box
        w="50%"
        justifyContent={"start"}
        alignContent={"center"}
        //Hidden on small screens
        display={{
          base: "none",
          md: "flex",
        }}
      >
        <Image
          src={image}
          h={{
            base: "300px",
            md: "500px",
          }}
          w={{
            base: "300px",
            md: "500px",
          }}
        />
      </Box>
    </Center>
  );
};

export default Social;
