import {
  Badge,
  Box,
  Card,
  CardBody,
  CardFooter,
  Center,
  Flex,
  Icon,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CardLayout from "../ui/CardLayout";
import { DeleteIcon, SettingsIcon } from "@chakra-ui/icons";
// import videoFile from "../../assets/videos/buildTheFuture.mp4";
import ReactPlayer from "react-player";
import { BsPauseFill, BsPlayFill, BsFillStopFill } from "react-icons/bs";
type Props = {
  videoFile: string;
  id: number;
  dropVideo: (id: number) => void;
  cardHeight?: { base?: string; md?: string };
  cardWidth?: { base?: string; md?: string };
  settings?: boolean;
};

const VideoCard = ({
  videoFile,
  dropVideo,
  id,
  cardHeight,
  cardWidth,
  settings,
}: Props) => {
  const [play, setPlay] = useState(false);
  const [subtitles, setSubtitles] = useState<string | undefined>();
  const onPlay = () => {
    setPlay(true);
  };
  const onPause = () => {
    setPlay(false);
  };

  const handleAddSubtitles = (files: FileList | null) => {
    if (files) {
      const file = files[0];
      console.log(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result;
        setSubtitles(text?.toString());
      };
      reader.readAsText(file);
    }
  };
  return (
    <Box>
      <Flex w='full'>
        <Card
          w={cardWidth ? { ...cardWidth } : { base: "full", md: "20vw" }}
          h={cardHeight ? { ...cardHeight } : { base: "10em", md: "20vh" }}
          boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px;"
          borderRadius="lg"
          _hover={{
            // cursor: "pointer",
            transform: "scale(1.005)",
            boxShadow:
              "rgba(38, 70, 159, 0.02) 0px 1px 3px 0px, rgba(12, 74, 135, 0.15) 0px 0px 0px 1px",
          }}
          //   w="30vw"
        >
          <CardBody w={"full"} h="80%" p="0">
            {/* <Image
                  h="100%"
                  w="100%"
                  borderRadius="md"
                  objectFit="cover"
                  // boxShadow="md"
                  src={Img}
                  alt={`trainingprogram`}
                /> */}
            <ReactPlayer
              url={videoFile}
              width={"100%"}
              height={"100%"}
              // onPause={onPause}
              // onPlay={onPlay}
              subtitles={subtitles}
              playing={play}
              // controls={true}
            />

            {/* <Text>{course.title}</Text> */}
          </CardBody>
          <CardFooter h="20%" p={cardWidth ? "0.5" : "0.5em"}>
            <Flex
              w="full"
              alignItems={"center"}
              justifyContent={"space-between"}
              rounded={"lg"}
              overflow={"hidden"}
            >
              <Flex
                p="0.5em"
                bg={useColorModeValue("gray.100", "gray.700")}
                // ml="0.5em"
                w="fit"
                rounded={"lg"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Badge>{id + 1}</Badge>
              </Flex>
              <Flex
                w="fit"
                bg={useColorModeValue("gray.100", "gray.700")}
                p="0.5em"
                rounded={"lg"}
              >
                {play ? (
                  <Icon as={BsPauseFill} onClick={onPause} />
                ) : (
                  <Icon as={BsPlayFill} onClick={onPlay} />
                )}
                {/* <Icon as={BsPauseFill} onClick={onPause} />
                <Icon as={BsPlayFill} onClick={onPlay} /> */}
                <Icon as={BsFillStopFill} />
              </Flex>
              <Flex
                gap={"0.5em"}
                p="0.5em"
                mr={cardWidth ? "unset" : "0.5em"}
                rounded={"lg"}
                bg={useColorModeValue("gray.100", "gray.700")}
              >
                <Box>
                  {/* hidden file input */}
                  <input
                    type="file"
                    id="subtitlefile"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setSubtitles(undefined);
                      handleAddSubtitles(e.target.files);
                    }}
                    accept=".srt"
                  />
                  <label htmlFor="subtitlefile">
                    {subtitles ? (
                      <Badge color={"green.300"}>EN</Badge>
                    ) : (
                      <Badge>CC</Badge>
                    )}
                  </label>
                </Box>
                {settings && <SettingsIcon />}{" "}
                <DeleteIcon
                  onClick={() => dropVideo(id)}
                  _hover={{
                    color: "red",
                  }}
                />
              </Flex>
            </Flex>
          </CardFooter>
        </Card>
      </Flex>
    </Box>
  );
};

export default VideoCard;
