import { Box, Button, Center, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import NotFound from "./assets/notfound.png";
import { Helmet } from "react-helmet-async";
// import {  } from "react-router-dom";

type Props = {};

const Notfound = (props: Props) => {
  //Return a fully styled notfound page using chakra ui
  return (
    <Box h="100vh">
      <Helmet>
        <title>Not Found | Liahtutor</title>
        <meta
          name="description"
          content="The page you are requesting doesn't exist or moved to a new location"
        />
      </Helmet>
      <Center h="inherit" flexDirection={"column"}>
        <Image src={NotFound}></Image>
        <Text my='1em'>Page not Found</Text>
        <Link href="/">
          <Button>Return To Home</Button>
        </Link>{" "}
      </Center>
    </Box>
  );
};

export default Notfound;
