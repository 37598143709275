import {
  Box,
  Text,
  Flex,
  Icon,
  Button,
  HStack,
  Badge,
  Avatar,
  useBreakpointValue,
  useColorModeValue,
  Spacer,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaHashtag, FaGlobeAfrica } from "react-icons/fa";
import { TiPin } from "react-icons/ti";
import { GoDash } from "react-icons/go";

import { Searchbar } from "../../Searchbar/Searchbar";
import { avatars } from "../../CourseSimple/AddingModule";
import IsTyping from "../../common/IsTyping";
import ForumTextArea from "../../common/ForumTextArea";
import { MdClose } from "react-icons/md";

const threads = [
  {
    name: "React beginner tips",
    createdAt: "9:04AM",
    createdBy: "Kratos Orion",
    members: ["Kratos Orion, Obi Sanega, Komeh Williams, Titus Ndichafa"],
    image: BrandonAvatar,
  },
  {
    name: "Info about Adobe",
    createdAt: "12: 14AM",
    createdBy: "Marion kelsea",
    members: ["Lucas Kelechi, Zinabu Nissi, Osidengeh Namondo, Marion Kelsea"],
    image: BrandonAvatar,
  },
];
interface threadInterface {
  thread: {
    name: string;
    createdAt: string;
    createdBy: string;
    members: string[];
    image: (avatarSize: string | undefined) => JSX.Element;
  };
}
const Thread = ({ thread }: threadInterface) => {
  const displayMembers = thread.members.slice(0, 1); // Get the first two members
  const remainingCount = thread.members.length - displayMembers.length;

  return (
    <Box>
      <HStack mb="1rem" alignItems="left" color="gray.500">
        <VStack alignItems="left">
          <Text>{thread.name}</Text>
          {displayMembers.map((member) => (
            <Text as="span" fontWeight={300} key={member}>
              {member}
            </Text>
          ))}
          {remainingCount > 0 && (
            <Text as="span" fontWeight={500}>
              {` and ${remainingCount} ${
                remainingCount === 1 ? "other" : "others"
              }`}
            </Text>
          )}
        </VStack>
        <Tooltip label="close thread">
          <Button variant="unstyled" leftIcon={<MdClose />} />
        </Tooltip>
      </HStack>
      <HStack>
        {thread.image("sm")}
        <Text fontWeight={400} color="gray.500">
          Created by <Text as="strong">{thread.createdBy}</Text>
        </Text>
      </HStack>
      <Flex
        mb="2rem"
        mt="2rem"
        gap="1rem"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box w="40vw" border="1px solid gainsboro" />
        <Badge textTransform="full-size-kana" fontWeight={500}>
          December 10
        </Badge>
        <Box w="40vw" border="1px solid gainsboro" />
      </Flex>

      <Flex gap="1rem" alignItems="top" fontSize="sm">
        <Avatar
          key={avatars[1].name}
          name={avatars[1].name}
          border="1px solid gainsboro"
          src={avatars[1].url}
          size={"sm"}
          position={"relative"}
          zIndex={2}
          _before={{
            content: '""',
            width: "full",
            height: "full",
            rounded: "full",
            transform: "scale(1.125)",
            position: "absolute",
            zIndex: -1,
            top: 0,
            left: 0,
          }}
        />
        <VStack alignItems="left">
          <Flex gap="1rem" alignItems="center">
            <Text fontWeight={700}>Kratos Orion(Me)</Text>
            <Text fontSize="10px" color="gray.500">
              8:05PM
            </Text>
          </Flex>
          <Text fontWeight={400}>
            <p>Hi everyone 🍾</p>
            <p>I'd like to start this thread to discuss React dev tips</p>
          </Text>
        </VStack>
      </Flex>
      <Text mt="1rem" fontWeight={400}>2 replies on <Text as="strong">Kratos Orion</Text></Text>
    </Box>
  );
};
const Forum = () => {
  const avatarSize = useBreakpointValue({ base: "sm", md: "md" });
  const [showThreads, setShowThreads] = useState(false);
  const [activeThread, setActiveThread] = useState<threadInterface>();
  // Sample data for demonstration
  const [questions, setQuestions] = useState([
    {
      id: 1,
      title: "How to start learning React?",
      author: "User123",
      content: "I want to begin learning React. Any tips?",
      responses: [
        {
          id: 1,
          author: "Expert101",
          content: "Start with the official documentation!",
        },
        // More responses...
      ],
    },
    // More questions...
  ]);

  const [newQuestion, setNewQuestion] = useState({ title: "", content: "" });

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setNewQuestion({ ...newQuestion, [name]: value });
  };

  const handleAddQuestion = () => {
    // Implement logic to add a new question to the forum
    const id = questions.length + 1;
    const author = "CurrentLoggedInUser"; // Get the current logged-in user
    const question = { ...newQuestion, id, author, responses: [] };
    setQuestions([...questions, question]);
    setNewQuestion({ title: "", content: "" });
  };

  const handleAddResponse = (questionId: number, response: any) => {
    // Implement logic to add a response to a question
    const updatedQuestions = questions.map((q) => {
      if (q.id === questionId) {
        return { ...q, responses: [...q.responses, response] };
      }
      return q;
    });
    setQuestions(updatedQuestions);
  };

  return (
    <Box mt="1rem" textAlign="left" fontSize="sm">
      <Box w="100%">
        <HStack mb="1rem" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap=".5rem">
            <Icon as={FaGlobeAfrica} boxSize={6} color="primary" />
            <Text as="h1" fontSize="xl" fontWeight={700}>
              Forum
            </Text>
          </Flex>
          <Flex gap="1rem" alignItems="center">
            <Icon _hover={{ cursor: "pointer" }} as={TiPin} boxSize={6} />
            <Button
              onClick={() => setShowThreads(true)}
              fontSize="sm"
              leftIcon={<FaHashtag />}
              variant="special1"
            >
              Thread
            </Button>
            {showThreads && (
              <Box
                pos="absolute"
                // eslint-disable-next-line react-hooks/rules-of-hooks
                background={useColorModeValue(
                  "rgba(255, 255, 255, .9)",
                  "rgba(71, 71, 74, 0.9)"
                )}
                className="animate__animated animate__fadeIn"
                borderRadius="lg"
                h="fit-content"
                onMouseLeave={() => setShowThreads(false)}
                top="25%"
                right="15%"
                p="1rem"
                zIndex={3}
                w={{ base: "95%", md: "30vw" }}
              >
                <VStack alignItems="left">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text fontWeight={600}>Threads</Text>
                    <Box minW={{ base: "100%", md: "14vw" }}>
                      <Searchbar
                        searchButton
                        placeholder="Search for Thread Name"
                      />
                    </Box>
                  </Flex>
                  <Text color="gray.500">All threads(2)</Text>
                  {threads.map((thread) => {
                    return (
                      <Flex
                        onClick={() => setActiveThread({ thread: thread })}
                        pos="inherit"
                        zIndex={2}
                        mt=".6rem"
                        mb="1rem"
                        _hover={{
                          border: "1px inset white",
                          cursor: "pointer",
                        }}
                        p=".5rem"
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        background={useColorModeValue(
                          "rgba(255, 255, 255, .3)",
                          "rgba(255, 255, 255, 0.06)"
                        )}
                        backdropFilter="blur(7px)"
                        w="100%"
                        border="1px solid gainsboro"
                        borderRadius="lg"
                        className="animate__animated animate__fadeIn"
                        gap="1rem"
                        alignItems="center"
                      >
                        {BrandonAvatar("sm")}

                        <Box w="100%">
                          <HStack
                            w="100%"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Text>{thread.name}</Text>
                            <Spacer />
                            <Text fontSize="10px" color="gray.500">
                              {thread.createdAt}
                            </Text>
                          </HStack>
                          <Text>Fiona is typing ...</Text>
                        </Box>
                      </Flex>
                    );
                  })}
                </VStack>
              </Box>
            )}

            <Box fontSize="sm" minW={{ base: "100%", md: "10vw" }}>
              <Searchbar searchButton placeholder="Search in Forum" />
            </Box>
          </Flex>
        </HStack>
      </Box>

      <HStack
        background={useColorModeValue(
          "rgba(255, 255, 255, .3)",
          "rgba(255, 255, 255, 0.06)"
        )}
        backdropFilter="blur(7px)"
        borderRadius="lg"
        p="1rem"
        pt="0"
        borderTop="1px dashed gainsboro"
        borderTopRadius={0}
        alignItems="top"
      >
        <Box mt="4rem" w="77%">
          <Flex
            mb="2rem"
            gap="1rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box w="40vw" border="1px solid gainsboro" />
            <Badge textTransform="full-size-kana" fontWeight={500}>
              Today
            </Badge>
            <Box w="40vw" border="1px solid gainsboro" />
          </Flex>

          <Box>
            {/* Silas has asked a question */}
            <Flex gap="1rem" alignItems="center" fontSize="sm">
              <Avatar
                key={avatars[1].name}
                name={avatars[1].name}
                border="1px solid gainsboro"
                src={avatars[1].url}
                size={avatarSize}
                position={"relative"}
                zIndex={2}
                _before={{
                  content: '""',
                  width: "full",
                  height: "full",
                  rounded: "full",
                  transform: "scale(1.125)",
                  position: "absolute",
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}
              />
              <VStack alignItems="left">
                <Flex gap="1rem" alignItems="center">
                  <Text fontWeight={700}>Silas Agbor</Text>
                  <Text fontSize="10px" color="gray.500">
                    1 day ago
                  </Text>
                </Flex>
                <Text>
                  {questions[0].title} {questions[0].content}
                </Text>
              </VStack>
            </Flex>

            <Flex mt="1.2rem" alignItems="center" fontWeight={600} gap="1rem">
              <FaHashtag /> <GoDash /> Kratos Orion{" "}
              <Text fontWeight={400}>started a thread:</Text> React beginner
              tips
            </Flex>
            {/* Brandon replying in thread */}
            <Flex mt="2rem" gap="1rem" alignItems="top" fontSize="sm">
              {BrandonAvatar(avatarSize)}
              <VStack alignItems="left">
                <Flex gap="1rem" alignItems="center">
                  <Text fontWeight={700}>Kratos Orion</Text>
                  <Text fontSize="10px" color="gray.500">
                    8:05PM
                  </Text>
                </Flex>
                <Text>
                  <p>Hi everyone 🍾</p>
                  <p>I'd like to start this thread to discuss React dev tips</p>
                </Text>
                <Flex
                  pos="inherit"
                  zIndex={2}
                  mt=".6rem"
                  mb="1rem"
                  _hover={{
                    border: "1px inset white",
                    cursor: "pointer",
                  }}
                  p=".5rem"
                  background={useColorModeValue(
                    "rgba(255, 255, 255, .3)",
                    "rgba(255, 255, 255, 0.06)"
                  )}
                  backdropFilter="blur(7px)"
                  w="fit-content"
                  border="1px solid gainsboro"
                  borderRadius="lg"
                  className="animate__animated animate__fadeIn"
                  gap="1rem"
                  alignItems="center"
                >
                  {BrandonAvatar("sm")}
                  <Text fontWeight={500} color="primary">
                    3 messages
                  </Text>
                  <Text>View Threads</Text>
                </Flex>
                <Box
                  position="absolute"
                  top={{ base: "50%", md: "60%", lg: "50%" }}
                  left="15%"
                  transform="translateX(50%, -50%)"
                  width="100px"
                  height="12.2vh"
                  border="1px solid gainsboro"
                  borderTop="none"
                  borderRight="none"
                  borderRadius="10px"
                  zIndex="1"
                />
              </VStack>
            </Flex>

            {/* Kome has has joined */}
            <Flex gap="1rem" alignItems="top" fontSize="sm">
              <Avatar
                key={avatars[2].name}
                name={avatars[2].name}
                border="1px solid gainsboro"
                src={avatars[2].url}
                size={avatarSize}
                position={"relative"}
                zIndex={2}
                _before={{
                  content: '""',
                  width: "full",
                  height: "full",
                  rounded: "full",
                  transform: "scale(1.125)",
                  position: "absolute",
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}
              />
              <VStack alignItems="left">
                <Flex gap="1rem" alignItems="center">
                  <Text fontWeight={700}>Kome Mathias</Text>
                  <Text fontSize="10px" color="gray.500">
                    12:01AM
                  </Text>
                </Flex>
                <Text>joined #Forum</Text>
                <Flex
                  pos="inherit"
                  zIndex={2}
                  _hover={{
                    border: "1px inset white",
                    cursor: "pointer",
                  }}
                  p=".5rem"
                  background={useColorModeValue(
                    "rgba(255, 255, 255, .3)",
                    "rgba(255, 255, 255, 0.06)"
                  )}
                  backdropFilter="blur(7px)"
                  w="fit-content"
                  border="1px solid gainsboro"
                  borderRadius="lg"
                  className="animate__animated animate__fadeIn"
                  gap="1rem"
                  alignItems="center"
                >
                  👋 Wave to say Hi
                </Flex>
              </VStack>
            </Flex>

            <Flex mt="2rem" gap="1rem" alignItems="top" fontSize="sm">
              {BrandonAvatar(avatarSize)}
              <VStack alignItems="left">
                <Flex gap="1rem" alignItems="center">
                  <Text fontWeight={700}>Kratos Orion</Text>
                  <Text fontSize="10px" color="gray.500">
                    8:05PM
                  </Text>
                </Flex>
                <Box>
                  <Text>
                    Welcome
                    <Text as="span" color="primary" fontWeight={700}>
                      {" "}
                      @Kome Mathias{" "}
                    </Text>
                    We are happy to have you here.
                  </Text>
                </Box>
              </VStack>
            </Flex>
            {/* ________ */}
            <HStack mt="2rem">
              <IsTyping /> <Text>Fiona is typing...</Text>
            </HStack>
            <Box mt="2rem">
              <ForumTextArea />
            </Box>
          </Box>
        </Box>
        {/* The right side */}
        {/* ________----_______ */}
        <Box
          p="1rem"
          fontWeight={600}
          w="23%"
          h="100%"
          borderLeft="1px solid gainsboro"
        >
          {activeThread ? (
            <Thread thread={activeThread.thread} />
          ) : (
            <Box>
              <Text color="gray.500">All members(12)</Text>
              <Box h="1rem" />
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Kratos Orion (Me)</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Tony Olumelu</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Obi Parker</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Christian Zapata</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Ebountat Manyor</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Miriam Fongeh</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Jim Hamrish</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Maria Deby</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Nfonyor Besong</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Micheal Obina</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Ayuk Maruh</Text>
              </HStack>
              <HStack _hover={{ cursor: "pointer" }} mb="1.5rem">
                {BrandonAvatar("sm")} <Text> Obi Wan Kenobi</Text>
              </HStack>
            </Box>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default Forum;
function BrandonAvatar(avatarSize: string | undefined) {
  return (
    <Avatar
      key={avatars[4].name}
      name={avatars[4].name}
      border="1px solid gainsboro"
      src={avatars[3].url}
      size={avatarSize}
      position={"relative"}
      zIndex={2}
      _before={{
        content: '""',
        width: "full",
        height: "full",
        rounded: "full",
        transform: "scale(1.125)",
        position: "absolute",
        zIndex: -1,
        top: 0,
        left: 0,
      }}
    />
  );
}
