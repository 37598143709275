import {
  Box,
  Checkbox,
  Flex,
  Image,
  Link,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function CloseAccount() {
  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="left"
    >
      <Box m="0 auto" w="50%">
        <Text fontWeight={600}>Tutors:</Text>
        <Text fontSize="sm">
          You are a tutor of at least one published or draft taining program.
          Please unpublish and delete the training program.{" "}
          <Link textDecor="underline">Get instructions</Link> for further
          assistance. Afterwards, you will then have the option to close your
          account.
        </Text>
      </Box>
    </Box>
  );
}
