import React, { useEffect, useReducer, useState } from "react";
import {
  Center,
  Text,
  VStack,
  Button,
  Box,
  Image,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  useToast,
  HStack,
  useColorModeValue,
  Input,
  position,
} from "@chakra-ui/react";

import image from "./assets/3d-business-graduated-student-holding-diploma.png";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { SendRequest } from "../../../../api/sendRequest";

type Props = {};

const certificate = [
  "Bachelor's Degree",
  "Master's Degree",
  "PhD",
  "Professional Certificate",
  "Diploma",
  "Associate Degree",
  "High School Diploma",
  "Other",
];

const field = [
  "Accounting",
  "Agriculture",
  "Architecture",
  "Business",
  "Computer Science",
  "Criminal Justice",
  "Education",
  "Engineering",
  "Finance",
  "Healthcare",
  "Humanities",
  "Law",
  "Marketing",
  "Mathematics",
  "Nursing",
  "Psychology",
  "Sciences",
  "Social Sciences",
  "Statistics",
  "Other",
];

/**
 *
 * @param state
 * @param action
 * @returns
 */
const reducer = (
  state: {
    category: string;
    certificate: string;
    description: string;
    institution: string;
    position: Position[] | [];
  },
  action: {
    value: string;
    type: string;
    position?: Position;
  }
) => {
  switch (action.type) {
    case "field or category": {
      localStorage.setItem(
        "certificate",
        JSON.stringify({
          ...state,
          category: action.value,
        })
      );
      return { ...state, category: action.value };
    }
    case "certificate": {
      localStorage.setItem(
        "certificate",
        JSON.stringify({
          ...state,
          certificate: action.value,
        })
      );
      return { ...state, certificate: action.value };
    }
    case "description": {
      localStorage.setItem(
        "certificate",
        JSON.stringify({
          ...state,
          description: action.value,
        })
      );
      return { ...state, description: action.value };
    }
    case "institution": {
      localStorage.setItem(
        "certificate",
        JSON.stringify({
          ...state,
          institution: action.value,
        })
      );
      return { ...state, institution: action.value };
    }
    case "position": {
      localStorage.setItem(
        "certificate",
        JSON.stringify({
          ...state,
          position: action.position ? [action.position] : [...state.position],
        })
      );
      return {
        ...state,
        position: action.position ? [action.position] : [...state.position],
      };
    }
    default:
      return state;
  }
};

export interface Position {
  description: string;
  position: string;
  from: string;
  to: string;
}
const initialValues = {
  category: "",
  certificate: "",
  description: "",
  institution: "",
  position: [],
};

const OptionField = ({
  field,
  certificates,
  setValue,
}: {
  field: string;
  certificates: string[];
  setValue: React.Dispatch<{
    value: string;
    type: string;
  }>;
}) => {
  const handleValue = (value: string) =>
    setValue ? setValue({ type: field.toLowerCase(), value: value }) : null;
  return (
    <FormControl id={field.toLowerCase()}>
      <FormLabel
        color="gray.500"
        fontSize={{
          base: "sm",
          md: "md",
        }}
        fontWeight={{
          base: "normal",
          md: "bold",
        }}
      >
        {field}
      </FormLabel>
      <Select
        placeholder="Select"
        onChange={(e) => handleValue(e.target.value)}
      >
        {certificates.map((item) => (
          <option key={item}>{item}</option>
        ))}
      </Select>{" "}
      <ErrorMessage name="name" component="div" className="error" />
    </FormControl>
  );
};

const TextAreaField = ({
  field,
  placeholder,
  setValue,
  setPosition,
}: {
  field: string;
  placeholder: string;
  setPosition?: React.Dispatch<React.SetStateAction<Position>>;
  setValue?: React.Dispatch<{
    value: string;
    type: string;
    position?: Position;
  }>;
}) => {
  const handleValue = (value: string) => {
    if (setValue) {
      return setValue({ type: field.toLowerCase(), value: value });
    }
    if (setPosition) {
      setPosition((prevState: Position) => {
        localStorage.setItem(
          "position",
          JSON.stringify({
            ...prevState,
            description: value,
          })
        );
        return {
          ...prevState,
          description: value,
        };
      });
    }
  };

  return (
    <FormControl id={field.toLowerCase()}>
      <FormLabel
        color="gray.500"
        fontSize={{
          base: "sm",
          md: "md",
        }}
        fontWeight={{
          base: "normal",
          md: "bold",
        }}
      >
        {field}
      </FormLabel>
      <Textarea
        placeholder={placeholder}
        onChange={(e) => handleValue(e.target.value)}
      />

      <ErrorMessage name="name" component="div" className="error" />
    </FormControl>
  );
};

const Certification = (props: Props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [certificateData, setCertificateData] = useReducer(
    reducer,
    initialValues
  );
  const [position, setPosition] = useState<Position>({
    position: "",
    description: "",
    from: "",
    to: "",
  });
  const [isCertificate, setIsCertificate] = useState<boolean>(false);
  const [isPosition, setIsPosition] = useState<boolean>(false);

  useEffect(() => {
    if (
      certificateData.category &&
      certificateData.certificate &&
      certificateData.description &&
      certificateData.institution
    )
      setIsCertificate(true);

    if (
      position.position &&
      position.description &&
      position.from &&
      position.to
    )
      setIsPosition(true);
  }, [certificateData, position]);

  const saveCertificate = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!isCertificate && !isPosition) {
      console.log(isCertificate, isPosition);
      return toast({
        status: "warning",
        title: "Fail",
        description: "Provide atleast a certificate or position history",
      });
    }

    const res = await SendRequest({
      method: "POST",
      url: "/certificate",
      data: {
        ...certificateData,
      },
    });
    navigate("/onboarding/social");

    if (res.error)
      return toast({
        status: "error",
        title: "Fail",
        description: res.error.message,
      });
    navigate("/onboarding/social");
  };

  // Define Yup validation schema
  const validationSchema = Yup.object({
    tel: Yup.number().required("Required"),
    name: Yup.string(),
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
  });

  //Get an array of dates from 1970 to current year
  const dates = Array.from(
    new Array(new Date().getFullYear() - 1970),
    (val, index) => index + 1970
  );

  return (
    <Center className="animate__animated animate__fadeIn" w={"full"}>
      <VStack
        w={{
          base: "90%",
          md: "50%",
        }}
      >
        <Text
          color="gray.500"
          fontSize={{
            base: "md",
            md: "lg",
          }}
          fontWeight={{
            base: "normal",
            md: "bold",
          }}
          pt={{
            base: "2em",
            md: "1em",
          }}
        >
          Certification
        </Text>
        <Box
          as="p"
          fontSize={{
            base: "sm",
            md: "md",
          }}
          color="gray.500"
          w={{
            base: "75%",
            md: "75%",
          }}
        >
          To boost your credibility, tell us about your certifications and
          education.
        </Box>
        <Box
          w={{
            base: "full",
            md: "80%",
          }}
        >
          <Formik
            initialValues={{ name: "Sasuke" }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {(props) => (
              <Form
                style={{
                  padding: "0",
                }}
              >
                <Box
                  gap={{
                    base: "0.5em",
                    md: "1em",
                  }}
                  display={"flex"}
                  flexDir={"column"}
                  w="full"
                >
                  <OptionField
                    certificates={field}
                    field="Field or Category"
                    setValue={setCertificateData}
                  />
                  <OptionField
                    certificates={certificate}
                    field="Certificate"
                    setValue={setCertificateData}
                  />
                  <TextAreaField
                    placeholder="Tell us more about your certificate"
                    field="Description"
                    setValue={setCertificateData}
                  />{" "}
                  <TextAreaField
                    placeholder="Tell us who issued your certificate"
                    field="Institution"
                    setValue={setCertificateData}
                  />
                  <Text
                    color="gray.500"
                    fontSize={{
                      base: "md",
                      md: "lg",
                    }}
                    fontWeight={{
                      base: "normal",
                      md: "bold",
                    }}
                    pt={{
                      base: "2em",
                      md: "1em",
                    }}
                  >
                    Professional History
                  </Text>{" "}
                  <Box
                    as="p"
                    fontSize={{
                      base: "sm",
                      md: "md",
                    }}
                    color="gray.500"
                    w="100%"
                  >
                    To boost your credibility, tell us about your certifications
                    and education.
                  </Box>
                  <Box w="full">
                    <FormLabel
                      color="gray.500"
                      fontSize={{
                        base: "sm",
                        md: "md",
                      }}
                      fontWeight={{
                        base: "normal",
                        md: "bold",
                      }}
                      htmlFor="position"
                    >
                      Position
                    </FormLabel>
                    <Input
                      type="text"
                      // roundedLeft={"none"}
                      placeholder={"What position did you hold?"}
                      // color={useColorModeValue("gray.500", "gray.400")}
                      p={{
                        base: "12px",
                        md: "16px",
                      }}
                      onChange={(e) => {
                        setPosition({
                          ...position,
                          position: e.target.value,
                        });
                        localStorage.setItem(
                          "position",
                          JSON.stringify({
                            ...position,
                            position: e.target.value,
                          })
                        );
                      }}
                      id={"position"}
                      h="full"
                      w="full"
                    ></Input>
                    <Box mt="0.5em">
                      <TextAreaField
                        placeholder="Describe your position"
                        field="Description"
                        // setValue={setCertificateData}
                        setPosition={setPosition}
                      />{" "}
                    </Box>{" "}
                    <HStack
                      mt="0.5em"
                      w="full"
                      justifyContent={"space-between"}
                    >
                      <Box w="45%">
                        <FormControl id="description">
                          <FormLabel
                            color="gray.500"
                            fontSize={{
                              base: "sm",
                              md: "md",
                            }}
                            fontWeight={{
                              base: "normal",
                              md: "bold",
                            }}
                          >
                            From
                          </FormLabel>
                          <Select
                            placeholder="Select"
                            onChange={(e) => {
                              setPosition({
                                ...position,
                                from: e.target.value,
                              });
                              localStorage.setItem(
                                "position",
                                JSON.stringify({
                                  ...position,
                                  from: e.target.value,
                                })
                              );
                            }}
                          >
                            {dates.map((item) => (
                              <option key={item}>{item}</option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </FormControl>
                      </Box>
                      <Box w="45%">
                        <FormControl id="description">
                          <FormLabel
                            color="gray.500"
                            fontSize={{
                              base: "sm",
                              md: "md",
                            }}
                            fontWeight={{
                              base: "normal",
                              md: "bold",
                            }}
                          >
                            To
                          </FormLabel>
                          <Select
                            placeholder="Select"
                            onChange={(e) => {
                              setPosition({
                                ...position,
                                to: e.target.value,
                              });
                              localStorage.setItem(
                                "position",
                                JSON.stringify({
                                  ...position,
                                  to: e.target.value,
                                })
                              );
                            }}
                          >
                            {dates.map((item) => (
                              <option key={item}>{item}</option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </FormControl>
                      </Box>
                    </HStack>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          {/* <HStack justifyContent={"end"} mt="0.5em">
            <IoMdAddCircle size={"1.5em"} color={"blue"} />
          </HStack> */}
        </Box>
        <VStack
          gap={"0.5em"}
          w={{
            base: "full",
            md: "80%",
          }}
          mt="2em"
          mb={"4em"}
        >
          <Button
            // bg="blue.500"
            w="full"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"blue.500"}
            _hover={{
              bg: "blue.500",
              color: "white",
              borderColor: "blue.500",
            }}
            borderStyle={"solid"}
            borderColor={"blue.400"}
            borderWidth={"2px"}
            as={Link}
            to={"/onboarding/social"}
            onClick={(e) => saveCertificate(e)}
            //   bg={useColorModeValue("white", "gray.900")}
          >
            Next
          </Button>
          <Button
            w="full"
            bg="blue.500"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"white"}
            _hover={{
              bg: "blue.200",
            }}
            as={Link}
            to={"/onboarding/photo"}
          >
            Back
          </Button>
        </VStack>
      </VStack>
      <Box
        w="50%"
        justifyContent={"start"}
        alignContent={"center"}
        //Hidden on small screens
        display={{
          base: "none",
          md: "flex",
        }}
      >
        <Image src={image} />
      </Box>
    </Center>
  );
};

export default Certification;
