import React from "react";
import { Outlet } from "react-router-dom";
import { CreateTPProvider } from "../../store/Context";

const CreateTrainingProgramLayout = () => {
  return (
    <CreateTPProvider>
      <Outlet />
    </CreateTPProvider>
  );
};

export default CreateTrainingProgramLayout;
