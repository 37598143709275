import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import LiahtutorProfile from "./LiahtutorProfile/LiahtutorProfile";
import PrivacySettings from "./PrivacySettings/PrivacySettings";
import ProfilePicture from "./ProfilePicture/ProfilePicture";

interface TabProps {
  title: string;
  active: boolean;
  onSelect: (title: string) => void;
}

function Tab({ title, active, onSelect }: TabProps) {
  const backgroundColor = useColorModeValue(
    "rgba(255, 255, 255, 0.6)",
    "gray.800"
  );
  return (
    <Box
      p="1rem"
      backgroundColor={active ? backgroundColor : "transparent"}
      borderRadius="md"
      _hover={{
        cursor: "pointer",
        backgroundColor,
      }}
      onClick={() => onSelect(title)}
    >
      {title}
    </Box>
  );
}

function Profile() {

  // Mapping between route parameters and tab titles
  // const tabMapping: Record<string, string> = {
  //   "me": "Account security",
  //   "notifications": "Notifications settings",
  //   "messages": "Message settings",
  //   "close": "Close account",
  // };

  const [activeTab, setActiveTab] = useState<string>("Liahtutor profile");

  const handleTabSelect = (tabTitle: string) => {
    setActiveTab(tabTitle);
  };

  return (
    <Box textAlign="center" m="0 auto">
      <Box h="2vh" />
      <Flex
        fontSize="sm"
        border="1px solid gainsboro"
        w="fit-content"
        borderRadius="md"
        m="0 auto"
        boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
      >
        <Tab
          title="Liahtutor profile"
          active={activeTab === "Liahtutor profile"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Profile picture"
          active={activeTab === "Profile picture"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Privacy settings"
          active={activeTab === "Privacy settings"}
          onSelect={handleTabSelect}
        />
      </Flex>
      <Box h="2vh" />
      {activeTab === "Liahtutor profile" && <LiahtutorProfile />}
      {activeTab === "Privacy settings" && <PrivacySettings />}
      {activeTab === "Profile picture" && <ProfilePicture />}
    </Box>
  );
}

export default Profile;
