import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

export default function LoadingScreen() {
  return (
    <Center border="1px solid rgba(255, 255, 255, 0.3)" borderRadius="xl" background="rgba(239, 239, 246, 0.2)" boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)" backdropFilter="blur(5px)" h="90vh" w="90vw" >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue"
        size="xl"
      />
    </Center>
  );
}
