import React, { Component } from "react";
import empty from "../../../assets/images/empty-mailbox-v2.png";
import {
  Box,
  Button,
  Checkbox,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

function MessageSettings() {
  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="left"
    >
      
      <Box mt="1rem" mb="1rem">
        <Checkbox
          fontSize="sm"
          borderRadius="md"
          maxW="50vw"
          border="1px solid gainsboro"
          p="1rem"
          size="md"
          colorScheme="blue"
        >
          <Text fontWeight={600}>Turn off direct messaging</Text>
          <Text>
            When you turn off direct messages, you will no longer be able to
            send or receive direct messages as an tutor.
          </Text>
        </Checkbox>
      </Box>
      <Button variant="primary">Save</Button>
    </Box>
  );
}

export default MessageSettings;
