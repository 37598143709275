import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import emptySearch from "../../../assets/images/empty-search.png";
import { FaInfoCircle } from "react-icons/fa";

export default function Reviews() {
  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="100%"
      fontSize="sm"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      // m="0 auto"
      textAlign="center"
    >
      <Flex
        justifyContent="space-between"
        w="100%"
        // m="0 auto"
        fontSize="sm"
      >
        <Flex alignItems="center" gap="1rem">
          <Checkbox>Not answered</Checkbox>
          <Checkbox>Has a comment</Checkbox>
          <Flex alignItems="center" gap="1rem" minW="max-content">
            <Text w="fit-content">Rating: </Text>
            <Select maxW="7vw" fontSize="sm" variant="flushed" fontWeight={600}>
              <option value="onestar">
                <Checkbox>1 star</Checkbox>
              </option>
              <option value="twostar">
                <Checkbox>2 star</Checkbox>
              </option>
              <option value="threestar">
                <Checkbox>3 star</Checkbox>
              </option>
              <option value="fourstar">
                <Checkbox>4 star</Checkbox>
              </option>
              <option value="fivestar">
                <Checkbox>5 star</Checkbox>
              </option>
            </Select>
          </Flex>
          <Flex w="fit-content" alignItems="center" gap="1rem">
            <Text minW="4vw">Sort by : </Text>
            <Select
              maxW="10vw"
              fontSize="sm"
              variant="flushed"
              fontWeight={600}
            >
              <option value="newest-first">Newest first</option>
              <option value="oldest-first">Oldest first</option>
            </Select>
          </Flex>
        </Flex>
        <Button variant="primaryOutline" fontSize="sm">
          Export to CSV...
        </Button>
      </Flex>

      <Box  mt="2rem" border="1px solid gainsboro" borderRadius="md" p="1rem">
        <Flex alignItems="center" gap="1rem">
          <FaInfoCircle size="30px" />
          <Text fontWeight={700}>
            It can take up to 48 hours for approved student ratings to show on
            your training program landing page.
          </Text>
        </Flex>
      </Box>
      <Text mt="2rem">No reviews found</Text>
    </Box>
  );
}
