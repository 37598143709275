// import React, { useState } from "react";
import { makeId } from "../../app/functions";
// import { CreateLessonState } from "../ModuleSimple/ModuleSimple";
// import { addCourse, deleteModule } from "../../features/trainingProgram";
import LinesEllipsis from "react-lines-ellipsis";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

//Images
import TPImg from "../../assets/images/ImageFolder.jpg";
import { RootState } from "../../store/store";

import { Box, Button, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import DisplayAddedCourses from "./DisplayAddedCourses";
import CreateCoursesForm from "./CreateCoursesForm";
import CreateCourseHeader from "./CreateCourseHeader";
import CreateTrainingProgramContext from "../../store/Context";

interface Props {
  currentTrainingProgram: {
    title: string;
    price: string | number;
    image: File;
    Course: Course[];
    id: string;
  } | null;
}

const CreateCourse = () => {
  const dispatch = useAppDispatch();
  const Context = useContext(CreateTrainingProgramContext);
  const trainingProgram = Context?.program;

  // const trainingData = useAppSelector(
  //   (state: RootState) => state.trainingProgramData.data
  // );
  const [wantModule, setWantModule] = useState("No");

  const [name, setName] = useState<string>("");
  const [toAddCourse, setToAddCourse] = useState(false);
  const [canAdd, setCanAdd] = useState(true);
  const [description, setDescription] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  // const [module, setModule] = useState<CreateModuleSingleState>({
  //   name: "",
  //   description: "",
  // });
  const toast = useToast();

  const handleDeleteModule = (moduleName: string) => {
    // dispatch(deleteModule(moduleName));
    toast({
      title: `Module: ${moduleName} deleted`,
      description: "Your Module has been successfully deleted",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };
  const [lessonNotes, setLessonNotes] = useState<string[]>([]);
  const handleNotesChange = (notes: string[]) => {
    setLessonNotes(notes);
    setLemon({
      ...lemon,
      content: {
        ...lemon.content,
        notes: lessonNotes,
      },
    });
  };
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  // Function to add a new quiz question
  const addQuestion = (newQuestion: Quiz) => {
    setQuizzes((prevQuizzes) => [...prevQuizzes, newQuestion]);
  };

  // Function to delete a quiz question by index
  const deleteQuestion = (index: number) => {
    setQuizzes((prevQuizzes) => {
      if (index === -1) {
        // Adding a new question
        return [
          ...prevQuizzes,
          { question: "", options: [], answer: "", possibleAnswers: [] },
        ];
      } else {
        // Deleting an existing question
        return prevQuizzes.filter((_, i) => i !== index);
      }
    });
  };
  const [toSetContent, setToSetContent] = useState(false);
  // const [canAdd, setCanAdd] = useState(true);
  const [displayBuilder, setDispBuilder] = useState(false);
  const [lemon, setLemon] = useState<CreateLessonState>({
    id: "",
    name: "",
    content: {
      notes: [],
      quizzes: [],
      videos: [],
    },
  });
  const [lemonArr, setLemonArr] = useState<CreateLessonState[]>([]);
  const [numLessons, setNumLessons] = useState(1);
  function handleAddALesson() {
    setLemon({
      ...lemon,
      id: makeId(),
      content: {
        ...lemon.content,
        quizzes: quizzes,
      },
    });
    setLemon({
      ...lemon,
      content: {
        ...lemon.content,
        notes: lessonNotes,
      },
    });
    setLemonArr([lemon, ...lemonArr]);
    console.log(lemon);
    setLessonNotes([]);
    setQuizzes([]);
    setLemon({
      id: "",
      name: "",
      content: {
        notes: [],
        quizzes: [],
        videos: [""],
      },
    });
    // setNumLessons(numLessons + 1);
  }
  const [editing, setEditing] = useState(false);
  function handleEditLesson(name: string) {
    const toEdit = lemonArr.find((l) => l.name === name);
    console.log(toEdit);
    if (toEdit) {
      setEditing(true);
      setLessonNotes(toEdit.content.notes);
      setQuizzes(toEdit.content.quizzes);
      setLemon({
        id: makeId(),
        name: toEdit.name,
        content: {
          notes: toEdit.content.notes,
          quizzes: toEdit.content.quizzes,
          videos: toEdit.content.videos,
        },
      });
    } else {
      throw new Error("Cannot find this");
    }
  }
  function handleSaveLesson(name: string) {}
  const handleCreateModule = () => {
    // Dispatch an action to add a new course to the store
    // const moduleSingleData = {
    //   name: module.name,
    //   description: module.description,
    //   Lesson: [
    //     {
    //       name: "",
    //       content: { quizzes: [], notes: [], videos: [] },
    //     },
    //   ],
    // };
  };

  const handleAddCourse = () => {
    // Dispatch an action to add a new course to the store
    if (!name || !description || !selectedImage) {
      toast({
        title: "Add Course Failed",
        description: "All fields are required",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    // const courseSingleData: Course = {
    //   name,
    //   image: selectedImage,
    //   description,
    //   module: [
    //     {
    //       name: "",
    //       description: "",
    //       Lesson: [
    //         {
    //           id: "",
    //           name: "",
    //           content: { quizzes: [], notes: [], videos: [] },
    //         },
    //       ],
    //     },
    //   ],
    // };
    // const dispatchThis = {
    //   courseD: courseSingleData,
    //   specifictrainingDataTitle: trainingData[0].title,
    // };
    // dispatch(addCourse(dispatchThis));
    // Clear the form fields and state
    setName("");
    setDescription("");
    setSelectedImage(null);
    setToAddCourse(true);
    setCanAdd(!canAdd);
  };

  return (
    <Box>
      {Context?.program.image && <CreateCourseHeader />}
      {toAddCourse && <DisplayAddedCourses setCanAdd={setCanAdd} />}
      <Box w="full" display={canAdd ? "block" : "none"} pt="1rem">
        <CreateCoursesForm
          addQuestion={addQuestion}
          quizzes={quizzes}
          deleteQuestion={deleteQuestion}
          setName={setName}
          setDescription={setDescription}
          setSelectedImage={setSelectedImage}
          wantModule={wantModule}
          setWantModule={setWantModule}
          name={name}
          description={description}
          selectedImage={selectedImage}
          handleAddALesson={handleAddALesson}
          handleEditLesson={handleEditLesson}
          handleSaveLesson={handleSaveLesson}
          editing={editing}
          lessonNotes={lessonNotes}
          setEditing={setEditing}
          // setModule={setModule}
          // module={module}
          lemon={lemon}
          setLemon={setLemon}
          displayBuilder={displayBuilder}
          handleNotesChange={handleNotesChange}
          setDispBuilder={setDispBuilder}
          lemonArr={lemonArr}
          setLemonArr={setLemonArr}
        />
        {/* <Button
          disabled={!name || !description || !selectedImage || canAdd === false}
          leftIcon={<AddIcon />}
          variant="solid"
          onClick={handleAddCourse}
        >
          Add
        </Button> */}
      </Box>
    </Box>
  );
};

export default CreateCourse;
