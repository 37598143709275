import React, { useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import CourseList from "../CourseSimple/CourseSimple";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../store/store";
// import { addCourse } from "../../features/trainingProgram";

interface DisplayAddedCoursesProps {
  setCanAdd: React.Dispatch<React.SetStateAction<boolean>>;
}
const DisplayAddedCourses = (props: DisplayAddedCoursesProps) => {
  // const trainingData = useAppSelector(
  //   (state: RootState) => state.trainingProgramData.data
  // );
  const { setCanAdd } = props;

  return (
    <Box w="full">
      {/* <CourseList trainingProgramName={trainingData[0].title} /> */}
      <Button backgroundColor="telegram.200" onClick={() => setCanAdd(true)}>
        Create Another Course
      </Button>
    </Box>
  );
};

export default DisplayAddedCourses;
