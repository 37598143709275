/* eslint-disable react-hooks/rules-of-hooks */
"use client";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { user } from "../../interfaces/user";
import AuthService from "../../../services/auth.service";
import { useEffect, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Image,
  useBreakpointValue,
  HStack,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import "../Login/loginStyles.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LiahLogo from "../../../assets/images/Liah.a2f10f1b.svg";
import { Logo } from "../../../components/common/Logo";

function SimpleCard() {
  const navigate = useNavigate();
  const toast = useToast();
  const [redirect, setRedirect] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // Define Yup validation schema
  const validationSchema = Yup.object({
    tel: Yup.number().required("Required"),
    name: Yup.string(),
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
  });

  useEffect(() => {
    const currentUser: user = AuthService.getCurrentUser();
    if (currentUser) {
      setRedirect("/userzone");
      navigate(redirect);
    }
  }, [redirect]);
  const handleSignup = (formValue: {
    tel: number;
    name: string;
    email: string;
    password: string;
  }) => {
    const { tel, name, email, password } = formValue;
    setMessage("");
    setLoading(true);
    AuthService.signup(tel, name, email, password).then(
      (response) => {
        toast({
          title: "Account created.",
          description: "We've created your account for you.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setRedirect("/userzone");
      },
      (error) => {
        // Handle error
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSuccessful(false);
        setMessage(resMessage);
        toast({
          title: "Failled to Create",
          description: message,
          status: "error",
          duration: 11000,
          isClosable: true,
        });
      }
    );
    setLoading(false);
  };
  // .
  return (
    <Formik
      initialValues={{ tel: 0, name: "", email: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSignup}
    >
      <Form>
        <Flex
          minH={"100vh"}
          align={"center"}
          justify={"center"}
          bg={useColorModeValue("gray.50", "gray.800")}
        >
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Stack align={"center"}>
              <Logo />

              <Text as="h1" fontWeight={700} fontSize={"4xl"}>
                Sign up
              </Text>
              <Text fontSize={"lg"} color={"gray.600"}>
                to enjoy all of our cool{" "}
                <Text color={"blue.400"}>features</Text> ✌️
              </Text>
            </Stack>
            <Box
              rounded={"lg"}
              bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
            >
              <Stack spacing={4}>
                <HStack>
                  <Box>
                    <FormControl id="tel" isRequired>
                      <FormLabel>Phone</FormLabel>
                      <Field
                        placeholder="Phone number"
                        type="phone"
                        name="tel"
                        as={Input}
                      />
                      <ErrorMessage
                        name="tel"
                        component="div"
                        className="error"
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="name">
                      <FormLabel>Name</FormLabel>
                      <Field type="text" name="name" as={Input} />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </FormControl>
                  </Box>
                </HStack>

                <FormControl id="email" isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Field type="email" name="email" as={Input} />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </FormControl>

                <FormControl id="password" isRequired>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      as={Input}
                    />
                    <InputRightElement h={"full"}>
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </FormControl>

                <Stack spacing={10} pt={2}>
                  <Button
                    type="submit"
                    isLoading={loading}
                    loadingText="Submitting"
                    size="lg"
                    fontSize="sm"
                    color={"white"}
                    bg={"#082D84"}
                    _hover={{ bg: "#1944ab" }}
                    onClick={() => navigate("/onboarding")}
                  >
                    Sign up
                  </Button>
                </Stack>

                <Flex fontSize="sm" pt={6} justifyContent="space-between">
                  <Text align={"center"}>Already a user? </Text>
                  <Text as={Link} color={"blue.400"} to="/signin">
                    Login
                  </Text>
                </Flex>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Form>
    </Formik>
  );
}

export default function Signup() {
  return (
    <>
      <SimpleCard />
    </>
  );
}
