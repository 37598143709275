import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useReducer, useState } from "react";
import { Searchbar } from "../../Searchbar/Searchbar";
import { useLocation, useParams } from "react-router-dom";
import VideoCard from "../../video/VideoCard";
import testVideo from "../../../assets/videos/buildTheFuture.mp4";
import LessonSection from "./LessonSection";
import QuizCard from "../../ui/QuizCard";
import { useAppSelector } from "../../../app/hooks";
import Notfound from "../../../Pages/NotFound/Notfound";
import { BsPencil } from "react-icons/bs";
import ActionModal from "../../Modal/ActionModal";
import { Card } from "../../../assets/styles/components/cardStyles";
import Quiz from "../../ui/Quiz";
import QuillEditor from "../../QuillEditor/QuillEditor";
import DOMPurify from "dompurify";

type Props = {};

const Videos = [
  "introduction to react",
  "promises in react",
  "fetching data in react",
  "react hooks",
  "react router",
  "react redux",
  "react context",
  "react testing",
  "react typescript",
];

const reducer = (
  state: {
    videos: number;
    quizzes: number;
  },
  action: {
    type: string;
    action: string;
  }
) => {
  switch (action.action) {
    case "next": {
      if (action.type === "videos") {
        return { ...state, videos: state.videos + 6 };
      } else if (action.type === "quiz") {
        return { ...state, quizzes: state.quizzes + 6 };
      } else return state;
    }
    case "previous": {
      if (action.type === "videos") {
        return { ...state, videos: state.videos - 6 };
      } else if (action.type === "quiz") {
        return { ...state, quizzes: state.quizzes - 6 };
      }
      return state;
    }
    default:
      return state;
  }
};
const LessonDetail = (props: Props) => {
  const location = useLocation();
  const { programTitle, lessonTitle } = useParams<{
    programTitle: string;
    lessonTitle: string;
  }>();

  const toast = useToast();

  const [question, setQuestion] = useState<Quiz[]>();

  // const trainingProgram = useAppSelector(
  //   (state) => state.trainingProgramData.trainingPrograms
  // );
  const [lessonVideos, setLessonVideos] = useState<string[]>([]);
  var lesson: Lesson;
  //A reducers that handles the pagination of the videos and quizzes and handles the next and previous buttons
  const [currentPage, setCurrentPage] = useReducer(reducer, {
    videos: 0,
    quizzes: 0,
  });

  const routeState = location.state as {
    lesson: Lesson;
    moduleTitle: string;
    courseTitle: string;
  };
  const bgColor = useColorModeValue("blue.500", "blue.300");
  lesson = routeState.lesson;
  const [notes, setNotes] = useState(lesson.notes[0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edit, setEdit] = useState("");
  if (!routeState) return <Notfound />;

  const handleNext = (type: string) => {
    if (type === "videos") {
      //Return if the current page is the last page
      if (currentPage.videos % lessonVideos.length > 0) return;
      console.log(currentPage.videos, lessonVideos.length);
      setCurrentPage({
        action: "next",
        type: type,
      });
    } else if (type === "quiz") {
      if (currentPage.quizzes % lesson.quizzes.length > 0) return;
      setCurrentPage({
        action: "next",
        type: type,
      });
    }
  };
  // const handleNoteEdit = () => {};
  const handlePrevious = (type: string) => {
    if (type === "videos") {
      if (currentPage.videos === 0) return;
      setCurrentPage({
        action: "previous",
        type: type,
      });
    } else if (type === "quiz") {
      if (currentPage.quizzes === 0) return;
      setCurrentPage({
        action: "previous",
        type: type,
      });
    }
  };
  const handleDropVideo = (id: number) => {
    // const newLessons = lessonVideos.filter((_, index) => index !== id);
    const newLessons = lessonVideos.filter((_, index) => index !== id);
    setLessonVideos(newLessons);
  };

  const handleEdit = (text?: string) => {
    setIsModalOpen(true);
    console.log(text);
    setEdit(text || "");
  };
  const handleSaveNotes = (notes: string[]) => {
    // console.log(notes);
    setNotes(notes[0]);
  };
  const handleSave = () => {
    setIsModalOpen(false);
    toast({
      title: "Saved",
      description: "Your changes have been saved.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  return (
    <Box>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <ModalHeader></ModalHeader>
          <ModalBody>
            <Input value={edit} onChange={(e) => setEdit(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            {/* <Button
              colorScheme="green"
              mr={3}
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Cancel
            </Button> */}
            <Button colorScheme="green" onClick={handleSave}>
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        my="2em"
        w="full"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Flex gap={"0.5em"}>
          <Text color={"gray.400"} size={"lg"}>
            {lesson.title}
          </Text>
          <BsPencil
            onClick={() => handleEdit(lesson.title)}
            cursor={"pointer"}
          />
        </Flex>
      </Flex>{" "}
      <LessonSection
        content={
          <VideoCard
            key={"video"}
            dropVideo={handleDropVideo}
            id={2}
            videoFile={testVideo}
            cardHeight={{
              base: "auto",
              md: "full",
            }}
            cardWidth={{
              base: "auto",
              md: "full",
            }}
          />
        }
      />
      <LessonSection
        title="Notes"
        content={
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notes) }}
          ></div>
        }
        toggleSection={
          <QuillEditor
            onNotesChange={handleSaveNotes}
            width="100%"
            text={notes}
          />
        }
        justify="center"
      />
      <LessonSection
        // title="Quizzes"
        next={handleNext.bind(null, "quiz")}
        previous={handlePrevious.bind(null, "quiz")}
        content={lesson.quizzes.map((q) => (
          <Quiz
            key={q.question}
            possibleAnswers={q.possibleAnswers}
            question={q.question}
            answer={q.answer}
          />
        ))}
        // handleClick={() => handleEdit("quiz")}
        isPagination={lesson.quizzes.length > 6}
        currentPagination={1}
        footer={
          <Flex w="fit-content" gap="1em">
            {" "}
            <Button
              variant={"solid"}
              w={{
                base: "full",
                md: "8em",
              }}
              bg={bgColor}
              textColor={"white"}
            >
              Save
            </Button>
            <Button
              variant={"solid"}
              w={{
                base: "full",
                md: "8em",
              }}
              bg={bgColor}
              textColor={"white"}
            >
              Publish
            </Button>
          </Flex>
        }
      />
    </Box>
  );
};

export default LessonDetail;
