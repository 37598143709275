import {
  Box,
  Checkbox,
  Flex,
  Image,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import emptySearch from "../../../assets/images/empty-search.png";

export default function Assignments() {
  return (
    <Box
      p="2rem"
      background={useColorModeValue("rgba(255, 255, 255, 0.06)", "rgba(255, 255, 255, 0.06)")}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="center"
    >
      <Flex
        alignItems="center"
        gap="1rem"
        w="fit-content"
        m="0 auto"
        fontSize="sm"
      >
        <Checkbox>Unread(0)</Checkbox>
        <Flex alignItems="center" gap="1rem" minW="max-content">
          <Text w="fit-content">Sharing Preference: </Text>
          <Select maxW="7vw" fontSize="sm" variant="flushed" fontWeight={600}>
            <option value="all">All(0)</option>
            <option value="tutor-only">Tutor only(0)</option>
            <option value="public">Public(0)</option>
          </Select>
        </Flex>
        <Flex w="fit-content" alignItems="center" gap="1rem">
          <Text>Feedback type : </Text>

          <Select fontSize="sm" maxW="10vw" variant="flushed" fontWeight={600}>
            <option value="all">All(0)</option>
            <option value="no-feedback">No Feedback</option>
            <option value="student-only">Student only(0)</option>
            <option value="tutor-only">Tutor only(0)</option>
            <option value="student-and-tutor">
              Student and Tutor(0)
            </option>
          </Select>
        </Flex>
        <Flex w="fit-content" alignItems="center" gap="1rem">
          <Text minW="4vw">Sort by : </Text>
          <Select maxW="10vw" fontSize="sm" variant="flushed" fontWeight={600}>
            <option value="newest-first">Newest first</option>
            <option value="oldest-first">Oldest first</option>
          </Select>
        </Flex>
      </Flex>

      <Stack alignItems="center" m="0 auto">
        <Image w="400px" src={emptySearch} alt="No Q&A" />
        <Text fontWeight={700}>No results</Text>
      </Stack>
      <Text fontSize="sm">Try a different filter</Text>
    </Box>
  );
}
