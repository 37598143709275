import Img from "./creator.jpg";

import React, { useContext, useEffect, useReducer, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { AddIcon, CheckIcon, LinkIcon, MinusIcon } from "@chakra-ui/icons";
import QuizCard from "../../ui/QuizCard";
import CardUi from "../../ui/CardLayout";
// import DOMPurify from "dompurify";

import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Collapse,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Image,
  SimpleGrid,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  color,
  HTMLChakraComponents,
  useConst,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  AccordionItem,
  Accordion,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  Stepper,
  StepDescription,
  StepTitle,
  StepSeparator,
  Step,
  useSteps,
  SlideFade,
  useColorModeValue,
} from "@chakra-ui/react";
import CreateTrainingProgramContext from "../../../store/Context";
import Lesson from "../../Lesson/Lesson";
import { StdioNull } from "child_process";
import VideoCard from "../../video/VideoCard";

type Props = {};

const data = {
  title: "Training Program 1",
  description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rem similiquelaudantium unde atque consequatur, rerum doloremque. Asperiores
  laboriosam impedit eaque, totam laudantium est magnam dicta tempore
  culpa doloribus, maiores suscipit doloremque at fugit aut quod numquam
  ducimus quae veritatis maxime nulla assumenda sed odit reiciendis?
  Placeat velit modi repellendus quam.`,
  price: 50000,
  courses: [
    {
      title: "Course 1",
      description: "This is a module",
      image: null,
      modules: [
        {
          title: "Module 1",
          description: "This is a module",
          lessons: [
            {
              title: "Lesson 1",
              description: "This is a lesson",
              notes: ["note 1", "note 2"],
              quizzes: [
                {
                  question: "Question 1",
                  answer: "Answer 1",
                  possibleAnswers: [
                    "answer 1",
                    "answer 2",
                    "answer 3",
                    "answer 4",
                  ],
                  options: [
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                  ],
                },
                {
                  question: "Question 2",
                  answer: "Answer 2",
                  possibleAnswers: [
                    "answer 1",
                    "answer 2",
                    "answer 3",
                    "answer 4",
                  ],
                  options: [
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                  ],
                },
              ],
            },
            {
              title: "Lesson 2",
              description: "This is a lesson",
              notes: ["note 1", "note 2"],
              quizzes: [
                {
                  question: "Question 1",
                  answer: "Answer 1",
                  options: [
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                  ],
                  possibleAnswers: [
                    "answer 1",
                    "answer 2",
                    "answer 3",
                    "answer 4",
                  ],
                },
                {
                  question: "Question 2",
                  answer: "Answer 2",
                  options: [
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                  ],
                  possibleAnswers: [
                    "answer 1",
                    "Answer 2",
                    "answer 3",
                    "answer 4",
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "Module 2",
          description: "This is a module",
          lessons: [
            {
              title: "Lesson 1",
              description: "This is a lesson",
              notes: ["note 1", "note 2"],
              quizzes: [
                {
                  question: "Question 1",
                  answer: "Answer 1",
                  options: [
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                  ],
                  possibleAnswers: [
                    "answer 1",
                    "Answer 1",
                    "answer 3",
                    "answer 4",
                  ],
                },
                {
                  question: "Question 2",
                  answer: "Answer 2",
                  options: [
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                  ],
                  possibleAnswers: [
                    "answer 1",
                    "Answer 2",
                    "answer 3",
                    "answer 4",
                  ],
                },
              ],
            },
            {
              title: "Lesson 2",
              description: "This is a lesson",
              notes: ["note 1", "note 2"],
              quizzes: [
                {
                  question: "Question 1",
                  answer: "Answer 1",
                  options: [
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                  ],
                  possibleAnswers: [
                    "Answer 1",
                    "Answer 2",
                    "answer 3",
                    "answer 4",
                  ],
                },
                {
                  question: "Question 2",
                  answer: "Answer 2",
                  options: [
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                    {
                      text: "answer 1",
                    },
                  ],
                  possibleAnswers: [
                    "Answer 2",
                    "Answer 2",
                    "answer 3",
                    "answer 4",
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

// interface TrainingProgram {
//   title: string;
//   description: string;
//   modules: {
//     title: string;
//     description: string;
//     lessons: Lesson[];
//   };
// }

const PreviewtTrainingProgram = (props: Props) => {
  const [toggleDescription, setToggleDescription] = useState(false);
  const Context = useContext(CreateTrainingProgramContext);
  const handleToggle = () => {
    setToggleDescription(!toggleDescription);
  };
  const [isOpen, setIsopen] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);

  const showCourse = (courseTitle: string) => {
    setIsopen(!isOpen);
    const course = Context?.program.courses.find(
      (course) => course.title === courseTitle
    );
    if (!course) return;
    setSelectedCourse(course);
  };

  return (
    <Container minW={{ base: "full", md: "70vw", lg: "60em" }}>
      {/* Compont that preview all the data */}
      <Flex
        w="100%"
        h={{ base: "8em", md: "15vh" }}
        // bgImage="/creator.jpg"
        bgPosition="center"
        bgRepeat="no-repeat"
        backgroundImage={
          Context?.program.image
            ? URL.createObjectURL(Context?.program.image)
            : `url(${Img})`
        } //URL(Context?.program.image)
        bgSize="cover"
        roundedTop={"lg"}
        p="1rem"
        // alignSelf={"center"}
        filter={"brightness(0.8)"}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">
          {Context?.program.title}
        </Text>
      </Flex>
      <Box
        w="100%"
        h="100%"
        border="1px"
        borderColor={useColorModeValue("gray.200", "gray.700")}
        roundedBottom={"lg"}
        p="1rem"
      >
        <Box color={"gray.400"} fontSize={"lg"}>
          <Collapse startingHeight={50} in={toggleDescription}>
            {Context?.program.description}
          </Collapse>
          <Flex justifyContent={"flex-end"}>
            <Button size="sm" onClick={handleToggle} mt="1rem">
              Show {toggleDescription ? "More" : "Less"}
            </Button>
          </Flex>
        </Box>
        <Box bg={useColorModeValue("white", "gray.900")}>
          <Text fontSize={"2xl"}>Courses</Text>
          <SimpleGrid
            pt="10px"
            spacing={4}
            borderRadius={"lg"}
            p="0.5em"
            bg={useColorModeValue("white", "gray.900")}
            templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          >
            {Context?.program.courses.map((course, index) => {
              return (
                <Card
                  key={index}
                  _hover={{
                    boxShadow: "lg",
                  }}
                  p="0"
                  // bg="white"
                >
                  <CardBody>
                    <Image
                      h="100%"
                      w="100%"
                      borderRadius="md"
                      objectFit="cover"
                      // boxShadow="md"
                      src={URL.createObjectURL(Context?.program?.image!)}
                      alt={`trainingprogram`}
                    />
                    <Text>{course.title}</Text>
                  </CardBody>
                  <CardFooter display={"flex"} w="full" justifyContent={"end"}>
                    <Button onClick={showCourse.bind(null, course.title)}>
                      View More
                    </Button>
                  </CardFooter>
                </Card>
              );
            })}
          </SimpleGrid>
        </Box>
        <br />
        {selectedCourse && (
          <CardUi // bg={useColorModeValue("white", "gray.900")}
          >
            <SlideFade in={isOpen} offsetY="20px">
              <Box
                mb="1em"
                borderBottom={"1px"}
                borderColor="gray.50"
                pb="0.5em"
              >
                {selectedCourse?.description}
              </Box>
              <Text>Modules</Text>
              {selectedCourse?.modules.map((module, index) => {
                return (
                  <Accordion allowMultiple>
                    <AccordionItem border="none">
                      {({ isExpanded }) => (
                        <>
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                {module.title}
                              </Box>
                              {isExpanded ? (
                                <MinusIcon fontSize="12px" />
                              ) : (
                                <AddIcon fontSize="12px" />
                              )}
                            </AccordionButton>
                          </h2>

                          <AccordionPanel pb={4} pl={"1.5em"}>
                            {module.lessons.map((lesson) => (
                              <AccordionItem w="full">
                                <AccordionButton w="100%" px="0" border="0px">
                                  <Lesson lessonTitle={lesson.title} />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                  <Flex gap="0.5em" py="0.5em">
                                    {lesson.videos.map((v, index) => (
                                      <VideoCard
                                        dropVideo={(index) => {}}
                                        id={index}
                                        videoFile={v}
                                      />
                                    ))}
                                  </Flex>
                                  <Text
                                    py="0.5em"
                                    borderBottom={"1px"}
                                    borderColor={"gray.300"}
                                  >
                                    Quizzes
                                  </Text>
                                  <SimpleGrid
                                    pt="10px"
                                    spacing={4}
                                    borderRadius={"lg"}
                                    p="0.5em"
                                    // bg={useColorModeValue("white", "gray.900")}
                                    templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                                  >
                                    {lesson.quizzes.map((q) => (
                                      <QuizCard
                                        possibleAnswers={q.possibleAnswers}
                                        question={q.question}
                                      />
                                    ))}
                                  </SimpleGrid>
                                </AccordionPanel>
                              </AccordionItem>
                            ))}
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItem>
                  </Accordion>
                );
              })}
            </SlideFade>
          </CardUi>
        )}
      </Box>
    </Container>
  );
};

export default PreviewtTrainingProgram;
