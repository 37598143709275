// >> src/features/trainingProgram.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { Content, Course, Lesson, Module, TrainingProgram } from "../types";
// import { postTrainingProgramData } from "../api/TrainingProgram";

export interface GlobalState {
  trainingPrograms: TrainingProgram[]; // Array of training program(s) data
  loading: boolean; // Loading state flag
  error: string | null; // Error message
}

export interface addCourseInterface {
  courseD: Course;
  specifictrainingDataTitle: string;
}
export interface deleteCoursmeInterface {
  courseName: string;
}
interface updateCourseModuleLessonInterface {
  contentD: Lesson;
  moduleN: string;
}
const program = [
  {
    title: "Data Science",
    description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rem similiquelaudantium unde atque consequatur, rerum doloremque. Asperiores
  laboriosam impedit eaque, totam laudantium est magnam dicta tempore
  culpa doloribus, maiores suscipit doloremque at fugit aut quod numquam
  ducimus quae veritatis maxime nulla assumenda sed odit reiciendis?
  Placeat velit modi repellendus quam.`,
    image: null,
    category: "web-development",
    price: 50000,
    published: {
      status: "published",
      date: new Date(),
    },
    courses: [
      {
        title: "AWS for absolute begineers",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "JavasCript ES6",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Node.js + Express.js with MongoDB/Mongose",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "HTML 5 and CSS 3 including Animation",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "React.js + Redux with TypeScript and Intro Next.js",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Photography",
    description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rem similiquelaudantium unde atque consequatur, rerum doloremque. Asperiores
  laboriosam impedit eaque, totam laudantium est magnam dicta tempore
  culpa doloribus, maiores suscipit doloremque at fugit aut quod numquam
  ducimus quae veritatis maxime nulla assumenda sed odit reiciendis?
  Placeat velit modi repellendus quam.`,
    image: null,
    category: "web-development",
    price: 50000,
    published: {
      status: "published",
      date: new Date(),
    },
    courses: [
      {
        title: "AWS for absolute begineers",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "JavasCript ES6",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Node.js + Express.js with MongoDB/Mongose",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "HTML 5 and CSS 3 including Animation",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "React.js + Redux with TypeScript and Intro Next.js",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Web Development",
    description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rem similiquelaudantium unde atque consequatur, rerum doloremque. Asperiores
  laboriosam impedit eaque, totam laudantium est magnam dicta tempore
  culpa doloribus, maiores suscipit doloremque at fugit aut quod numquam
  ducimus quae veritatis maxime nulla assumenda sed odit reiciendis?
  Placeat velit modi repellendus quam.`,
    image: null,
    category: "web-development",
    price: 50000,
    published: {
      status: "published",
      date: new Date(),
    },
    courses: [
      {
        title: "AWS for absolute begineers",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "JavasCript ES6",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Node.js + Express.js with MongoDB/Mongose",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "HTML 5 and CSS 3 including Animation",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "React.js + Redux with TypeScript and Intro Next.js",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Graphic Design",
    description: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rem similiquelaudantium unde atque consequatur, rerum doloremque. Asperiores
  laboriosam impedit eaque, totam laudantium est magnam dicta tempore
  culpa doloribus, maiores suscipit doloremque at fugit aut quod numquam
  ducimus quae veritatis maxime nulla assumenda sed odit reiciendis?
  Placeat velit modi repellendus quam.`,
    image: null,
    category: "graphic-design",
    price: 50000,
    published: {
      status: "published",
      date: new Date(),
    },
    courses: [
      {
        title: "Course 1",
        description: "This is a Coures",
        image: null,
        modules: [
          {
            title: "Module 1",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                    options: [],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
          {
            title: "Module 2",
            description: "This is a module",
            lessons: [
              {
                title: "Lesson 1",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 1",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
              {
                title: "Lesson 2",
                description: "This is a lesson",
                notes: ["note 1", "note 2"],
                quizzes: [
                  {
                    question: "Question 1",
                    answer: "Answer 1",
                    options: [],
                    possibleAnswers: [
                      "Answer 1",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                  {
                    question: "Question 2",
                    answer: "Answer 2",
                    options: [],
                    possibleAnswers: [
                      "Answer 2",
                      "Answer 2",
                      "answer 3",
                      "answer 4",
                    ],
                  },
                ],
                videos: [
                  "introduction to react",
                  "promises in react",
                  "fetching data in react",
                  "react hooks",
                  "react router",
                  "react redux",
                  "react context",
                  "react testing",
                  "react typescript",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export interface updateCourseWithModulesInterface {
  moduleD: Module;
  courseName: string;
} //GlobalState
// Define the initial state
const initialState: GlobalState = {
  trainingPrograms: [...program],
  loading: true,
  error: null,
};

// Create the jobData slice
export const trainingProgramDataSlice = createSlice({
  name: "trainingProgramData",
  initialState,
  reducers: {
    addNewTrainingProgram: (state, action: PayloadAction<TrainingProgram>) => {
      const data = action.payload;

      const trainingProgramExist = state.trainingPrograms.find(
        (elt) => elt.title === data.title && elt.price === data.price
      );
      if (trainingProgramExist) {
        // console.log("aLREADYeCIST");
        return;
      }
      // console.log(data);
      state.trainingPrograms = [...state.trainingPrograms, data];
      state.loading = false;
    },
    updateTrainingProgram: (
      state,
      action: PayloadAction<{
        trainingProgram: TrainingProgram;
        trainingProgramName: string;
      }>
    ) => {
      const { trainingProgram, trainingProgramName } = action.payload;
      //update training program
      const trainingProgramExist = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgramExist) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName ? trainingProgram : elt
          ),
        };
      }
    },

    deleteTrainingProgram: (
      state,
      action: PayloadAction<{
        trainingProgram: TrainingProgram;
        trainingProgramName: string;
      }>
    ) => {
      const { trainingProgramName } = action.payload;
      //delete training program
      const trainingProgramExist = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgramExist) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.filter(
            (elt) => elt.title !== trainingProgramName
          ),
        };
      }
    },
    addCourse: (
      state,
      action: PayloadAction<{ Course: Course; trainingProgramName: string }>
    ) => {
      const { Course, trainingProgramName } = action.payload;
      //Add new module to the training program
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? { ...elt, course: [...elt.courses, Course] }
              : elt
          ),
        };
      }
    },
    deleteCourse: (
      state,
      action: PayloadAction<{ Course: Course; trainingProgramName: string }>
    ) => {
      const { Course, trainingProgramName } = action.payload;
      //delete course from the training program
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? {
                  ...elt,
                  course: elt.courses.filter(
                    (elt) => elt.title !== Course.title
                  ),
                }
              : elt
          ),
        };
      }
    },
    updateCourse: (
      state,
      action: PayloadAction<{ Course: Course; trainingProgramName: string }>
    ) => {
      const { Course, trainingProgramName } = action.payload;
      //update course from the training program
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? {
                  ...elt,
                  course: elt.courses.map((elt) =>
                    elt.title === Course.title ? Course : elt
                  ),
                }
              : elt
          ),
        };
      }
    },
    //Add a module in a course
    addModule: (
      state,
      action: PayloadAction<{
        Module: Module;
        courseTitle: string;
        trainingProgramName: string;
      }>
    ) => {
      const { Module, courseTitle, trainingProgramName } = action.payload;
      //Add new module to the training program
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? {
                  ...elt,
                  course: elt.courses.map((elt) =>
                    elt.title === courseTitle
                      ? {
                          ...elt,
                          module: elt.modules
                            ? [...elt.modules, Module]
                            : [Module],
                        }
                      : elt
                  ),
                }
              : elt
          ),
        };
      }
    },
    //Update a module in a course
    updateModule: (
      state,
      action: PayloadAction<{
        Module: Module;
        courseTitle: string;
        trainingProgramName: string;
      }>
    ) => {
      const { Module, courseTitle, trainingProgramName } = action.payload;
      //update module from the training program
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? {
                  ...elt,
                  course: elt.courses.map((elt) =>
                    elt.title === courseTitle
                      ? {
                          ...elt,
                          module: elt.modules
                            ? elt.modules.map((elt) =>
                                elt.title === Module.title ? Module : elt
                              )
                            : [Module],
                        }
                      : elt
                  ),
                }
              : elt
          ),
        };
      }
    },
    //Delete a module in a course
    deleteModule: (
      state,
      action: PayloadAction<{
        moduleTitle: string;
        courseTitle: string;
        trainingProgramName: string;
      }>
    ) => {
      const { moduleTitle, courseTitle, trainingProgramName } = action.payload;
      //delete module from the training program
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram && trainingProgram.courses) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? {
                  ...elt,
                  course: elt.courses.map((elt) =>
                    elt.title === courseTitle
                      ? {
                          ...elt,
                          module: elt.modules
                            ? elt.modules.filter(
                                (elt) => elt.title !== moduleTitle
                              )
                            : [],
                        }
                      : elt
                  ),
                }
              : elt
          ),
        };
      }
    },
    //Add a lesson in a course with a module
    addLesson: (
      state,
      action: PayloadAction<{
        Lesson: Lesson;
        moduleTitle: string;
        courseTitle: string;
        trainingProgramName: string;
      }>
    ) => {
      const { Lesson, moduleTitle, courseTitle, trainingProgramName } =
        action.payload;
      //Add new lesson to a module
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram) {
        // console.log("trainingProgram", trainingProgram);
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? {
                  ...elt,
                  course: elt.courses.map((elt) =>
                    elt.title === courseTitle
                      ? {
                          ...elt,
                          module: elt.modules
                            ? elt.modules.map((elt) =>
                                elt.title === moduleTitle
                                  ? {
                                      ...elt,
                                      lesson: elt.lessons
                                        ? [...elt.lessons, Lesson]
                                        : [Lesson],
                                    }
                                  : elt
                              )
                            : [],
                        }
                      : elt
                  ),
                }
              : elt
          ),
        };
      }
    },
    //Update a lesson in a course with a module
    updateLesson: (
      state,
      action: PayloadAction<{
        Lesson: Lesson;
        moduleTitle: string;
        courseTitle: string;
        trainingProgramName: string;
      }>
    ) => {
      const { Lesson, moduleTitle, courseTitle, trainingProgramName } =
        action.payload;
      //update lesson from a module
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram) {
        // console.log("trainingProgram", trainingProgram);
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? {
                  ...elt,
                  course: elt.courses.map((elt) =>
                    elt.title === courseTitle
                      ? {
                          ...elt,
                          module: elt.modules
                            ? elt.modules.map((elt) =>
                                elt.title === moduleTitle
                                  ? {
                                      ...elt,
                                      lesson: elt.lessons
                                        ? elt.lessons.map((elt) =>
                                            elt.title === Lesson.title
                                              ? Lesson
                                              : elt
                                          )
                                        : [Lesson],
                                    }
                                  : elt
                              )
                            : [],
                        }
                      : elt
                  ),
                }
              : elt
          ),
        };
      }
    },
    //Delete a lesson in a course with a module
    deleteLesson: (
      state,
      action: PayloadAction<{
        lessonTitle: string;
        moduleTitle: string;
        courseTitle: string;
        trainingProgramName: string;
      }>
    ) => {
      const { lessonTitle, moduleTitle, courseTitle, trainingProgramName } =
        action.payload;
      //delete lesson from the training program
      const trainingProgram = state.trainingPrograms.find(
        (elt) => elt.title === trainingProgramName
      );
      if (trainingProgram && trainingProgram.courses) {
        return {
          ...state,
          trainingPrograms: state.trainingPrograms.map((elt) =>
            elt.title === trainingProgramName
              ? {
                  ...elt,
                  course: elt.courses.map((elt) =>
                    elt.title === courseTitle
                      ? {
                          ...elt,
                          module: elt.modules
                            ? elt.modules.map((elt) =>
                                elt.title === moduleTitle
                                  ? {
                                      ...elt,
                                      lesson: elt.lessons
                                        ? elt.lessons.filter(
                                            (elt) => elt.title !== lessonTitle
                                          )
                                        : [],
                                    }
                                  : elt
                              )
                            : [],
                        }
                      : elt
                  ),
                }
              : elt
          ),
        };
      }
    },
  },
});

//Export actions
export const {
  // addNewTrainingProgram,a
  addNewTrainingProgram,
  deleteLesson,
  deleteModule,
  deleteTrainingProgram,
  updateLesson,
  updateModule,
  updateTrainingProgram,
  addCourse,
  addLesson,
  addModule,
  deleteCourse,
  updateCourse,
} = trainingProgramDataSlice.actions;
// Export the reducer function
export default trainingProgramDataSlice.reducer;
