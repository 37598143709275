import { Box, Center, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";

type Props = {};

const ProgramDetailLayout = (props: Props) => {
  return (
    // <Box bg="gray.50" rounded={"lg"}>
    //   <Outlet />
    // </Box>
    <Center
      // py={6}
      // pos={hasBgunPutingCourse ? "relative" : "inherit"}
      right="0"
      w="full"
    >
      <Box
        className="animate__animated animate__fadeIn"
        // minW={{
        //   base: "145px",
        //   lg: step === 1 ? "max" : "75%",
        // }}
        // w={
        //   step === 1
        //     ? "max"
        //     : isSave && complete1 && step !== 4
        //     ? {
        //         base: "full",
        //         lg: "fit-content",
        //       }
        //     : "70%"
        // }
        // w='500'
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue("white", "gray.900")}
        boxShadow="rgba(0, 0, 0, 0.05) 0px 0px 0px 1px"
        rounded={"md"}
        w={{ base: "full", md: "90%" }}
        p={6}
        overflow="visible"
      >
        <Outlet />
      </Box>
    </Center>
  );
};

export default ProgramDetailLayout;
