import { Helmet } from "react-helmet-async";
import Hero from "../components/Hero/Hero";
import Carousel from "../components/Carousel/Carousel";
import SeeAndBuild from "../components/SeeAndBuild/SeeAndBuild";
import LargeWithLogoCentered from "../components/Footer/Footer";
import Newsletter from "../components/Newsletter/Newsletter";
import { Box, VStack } from "@chakra-ui/react";
import Collaborators from "../components/Collaborators/Collaborators";
import { HeaderRoutes } from "../App";

export default function Landing() {
  return (
    <Box>
      {" "}
      <HeaderRoutes />
      <VStack spacing={8}>
        <Helmet>
          <title>Homepage | Liahtutor</title>
          <meta
            name="description"
            content="Tutor platform for LiahStudy E-learning"
          />
        </Helmet>
        <Hero />
        <Carousel />
        <SeeAndBuild />
        <Collaborators />
        <Newsletter />
        {/* Footer */}
        <LargeWithLogoCentered />
        {/* <CallToActionWithAnnotation /> */}
      </VStack>
    </Box>
  );
}
