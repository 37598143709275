import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  Image,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import emptySearch from "../../../assets/images/empty-search.png";

export default function NotificationsSettings() {
  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="left"
    >
      <Text fontWeight={600}>As an tutor, I want to receive:</Text>
      <Box mt="1rem" mb="1rem">
      <Checkbox fontSize="sm" borderRadius="md" maxW="50vw" border="1px solid gainsboro" p="1rem" defaultChecked size="md" colorScheme="blue">
        <Text fontWeight={600}>
          Helpful resources and important updates related to being an tutor
          on Liahtutor.
        </Text>
        <Text>
          To adjust this preference by course, leave this box checked and go to
          ‘Training Program Settings’ on the Program management dashboard to opt in or out
          of specific notifications.
        </Text>
      </Checkbox>
      </Box>
      <Button variant="primary">Save</Button>
    </Box>
  );
}
