import { Box, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react";
import React from "react";
import { BsPencil } from "react-icons/bs";

type Props = {
  question: string;
  possibleAnswers: string[];
  answer?: string;
};

const Quiz = (props: Props) => {
  const { possibleAnswers, question, answer: a } = props;
  return (
    <Box
      w="full"
      rounded={"lg"}
      borderWidth="1px"
      borderColor={"gray.200"}
      borderStyle={"solid"}
    >
      {" "}
      <Flex
        alignItems={"center"}
        w="full"
        borderBottomWidth="1px"
        borderColor={"gray.200"}
        borderStyle={"solid"}
      >
        <Text p="0.5em">{question}</Text>
        <BsPencil cursor={"pointer"} />
      </Flex>
      <Box>
        <RadioGroup>
          <Flex direction="column" gap={"1em"}>
            {possibleAnswers.map((answer,i) => (
              <Box
                borderBottomWidth="1px"
                borderColor={"gray.200"}
                borderStyle={"solid"}
                p="0.5em"
                key={i}
              >
                <Radio value={answer}>{answer}</Radio>
              </Box>
            ))}
          </Flex>
        </RadioGroup>
      </Box>{" "}
    </Box>
  );
};

export default Quiz;
