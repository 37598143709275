import {
  Box,
  Card,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  question: string;
  possibleAnswers: string[];
  answer?: string;
};

const QuizCard = (props: Props) => {
  const { possibleAnswers, question, answer } = props;
  return (
    <Card
      p="0.5em"
      rounded="lg"
      _hover={{
        boxShadow: "md",
      }}
      // bg={useColorModeValue("white", "gray.900")}
    >
      <Text mb="0.5em">{question}</Text>
      <Box>
        <RadioGroup
          isDisabled={answer ? true : false}
          defaultChecked={answer ? true : false}
          value={answer ? answer : undefined}
        >
          <Stack spacing={5} direction="column">
            {possibleAnswers.map((answer) => (
              <Radio colorScheme="red" value={answer} key={answer}>
                {answer}
              </Radio>
            ))}
            {<hr />}
          </Stack>
        </RadioGroup>
      </Box>
    </Card>
  );
};

export default QuizCard;
