import {
  Box,
  Center,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Filters from "../FIlters/Filters";
import Pagination from "../Paginations/Pagination";
import TrainingProgramCard from "./TrainingProgramCard";
import { useAppSelector } from "../../app/hooks";
import { Link } from "react-router-dom";

type Props = {};

const sortOptions = [
  { value: "newest", label: "Newest" },
  { value: "oldest", label: "Oldest" },
  { value: "most-popular", label: "Most Popular" },
  { value: "least-popular", label: "Least Popular" },
  { value: "published", label: "Published" },
  { value: "unpublished", label: "Unpublished" },
  { value: "a-z", label: "A-Z" },
  { value: "z-a", label: "Z-A" },
];

const TrainingPrograms = (props: Props) => {
  const allPrograms = useAppSelector(
    (state) => state.trainingProgramData.trainingPrograms
  );
  const [first, setFirst] = useState(0);
  const [programs, setPrograms] = useState<TrainingProgram[]>(allPrograms);
  const [searchText, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState<string | undefined>();

  const handleSearchTerm = (text: string) => {
    setSearchTerm(text);
  };

  //   const handleFilter = (category: string) => {
  //     if (category === "all") {
  //       setPrograms(allPrograms);
  //     } else {
  //       const filteredPrograms = allPrograms.filter(
  //         (program) => program.category === category
  //       );
  //       setPrograms(filteredPrograms);
  //     }
  //   };

  useEffect(() => {
    const filteredPrograms = allPrograms.filter((program) =>
      program.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setPrograms(filteredPrograms);
  }, [searchText, allPrograms]);

  const handleNext = () => {
    //Each page shows 10 programs
    if (first + 10 < programs.length) {
      setFirst(first + 10);
    }
  };
  const handelPrevious = () => {
    if (first - 10 >= 0) {
      setFirst(first - 10);
    }
    console.log(first);
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setSortBy('newest');
  };

  return (
    <Box>
      <Filters
        handleSearchTerm={handleSearchTerm}
        sortBy={sortBy}
        sortOptions={sortOptions}
        textValue={searchText}
      />
      <Grid
        templateColumns={
          programs.length
            ? {
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
                xl: "repeat(4, 1fr)",
              }
            : { base: "repeat(1, 1fr)" }
        }
        gap={"1.5em"}
      >
        {programs.length ? (
          programs?.slice(first, first + 10).map((program) => {
            // if (program.published.status === "published") {
            return (
              <GridItem
                key={program.id}
                as={Link}
                to={`/program/${program.title.split(" ").join("-")}`}
              >
                <TrainingProgramCard
                  key={program.title}
                  numberOfCourse={program.courses.length}
                  category={program.category.split("-").join(" ")}
                  // image={program.image}
                  programTitle={program.title}
                  cardWidth={{
                    base: "unset",
                    md: "unset",
                  }}
                />
              </GridItem>
            );
            // }
          })
        ) : (
          <Center
            w="full"
            flexDir={"column"}
            h={{
              base:'60vh',
              md: "70vh",
            }}
          >
            <Text textAlign={'center'}>
              No Program Found for{" "}
              <Box as="span" textColor={"blue.400"} fontStyle={"italic"}>
                {searchText}
              </Box>{" "}
            </Text>
            <Text
              fontSize={"sm"}
              cursor={"pointer"}
              textColor={"blue.400"}
              onClick={handleClearFilter}
            >
              Reset Filter
            </Text>
          </Center>
        )}
      </Grid>
      {programs.length ? (
        <Pagination
          currentPagination={first / 3 + 1 > 0 ? Math.floor(first / 10 + 1) : 1}
          next={handleNext}
          previous={handelPrevious}
        />
      ) : null}
    </Box>
  );
};

export default TrainingPrograms;
