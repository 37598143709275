import React, { Component } from "react";
import empty from "../../../assets/images/empty-mailbox-v2.png";
import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Students() {
  return (
    <Box
      p="2rem"
      fontSize="sm"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="center"
    >
      <Text>No students yet...</Text>
      <Text>
        Once you publish your training program, come here to learn about your
        students.
      </Text>

      <Button mt="1rem" variant="primary" px="30px" as={Link} to="/userzone">
        Go to Dashboard
      </Button>
    </Box>
  );
}

export default Students;
