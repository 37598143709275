import React, { Component } from "react";
import empty from "../../../assets/images/empty-mailbox-v2.png";
import { Box, Image, Stack, Text, useColorModeValue } from "@chakra-ui/react";

function Messages() {
  return (
    <Box
      p="2rem"
      background={useColorModeValue("rgba(255, 255, 255, 0.06)", "rgba(255, 255, 255, 0.06)")}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="center"
    >
      <Stack alignItems="center" m="0 auto">
        <Image w="400px" src={empty} alt="No Q&A" />
        <Text fontWeight={700}>No new messages</Text>
      </Stack>
      <Box m="0 auto" w="40%">
        <Text fontSize="sm">
          Direct messages are for you to communicate with your students or other
          tutors privately. Here’s where you’ll see them.
        </Text>
      </Box>
    </Box>
  );
}

export default Messages;
