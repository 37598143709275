import React, {
  ReactElement,
  createContext,
  useReducer,
  useState,
} from "react";

const initialvalue: State = {
  isSave: false,
  progress: 0,
  step: 0,
  program: {
    description: "",
    image: null,
    title: "",
    price: 0,
    courses: [],
    category: "",
    published: {
      status: 'unpublished',
      date: null,
    },
  },
  dispatch: () => null,
};

interface State {
  isSave: boolean;
  progress: number;
  step: number;
  program: TrainingProgram;
  dispatch: (payload: TrainingProgramAction) => void;
}

interface TrainingProgramAction {
  type: string;
  payload: any;
}
interface Props {
  children: React.ReactNode;
}
const CreateTrainingProgramContext = createContext<State | null>(null);
function reducer(state: State, action: TrainingProgramAction) {
  const { type, payload } = action;
  switch (type) {
    case "saveProgram": {
      const { program } = payload;
      return { ...state, program };
    }
    case "addCourse": {
      const { course } = payload;
      const courseExist = state.program.courses.find(
        (elt) => elt.title === course.title
      );
      if (courseExist) return state;

      //Avoid adding a course with the same title and return the state
      return {
        ...state,
        program: {
          ...state.program,
          courses: [...state.program.courses, course],
        },
      };
    }
    case "addModule": {
      const { module, courseTitle }: { module: Module; courseTitle: string } =
        payload;
      //Check if the module already exist
      const course = state.program.courses.find(
        (elt) => elt.title === courseTitle
      );
      const moduleExist = course?.modules?.find(
        (elt) => elt.title === module.title
      );
      if (moduleExist) return state;

      return {
        ...state,
        program: {
          ...state.program,
          courses: state.program.courses.map((course) => {
            if (course.title === courseTitle) {
              return {
                ...course,
                modules: [...course.modules!, module],
              };
            }
            return course;
          }),
        },
      };
    }
    case "addLesson": {
      const {
        lesson,
        courseTitle,
        moduleTitle,
      }: {
        lesson: Lesson;
        courseTitle: string;
        moduleTitle: string;
      } = payload;
      //Check if the lesson already exist
      const course = state.program.courses.find(
        (elt) => elt.title === courseTitle
      );
      const module = course?.modules?.find((elt) => elt.title === moduleTitle);
      console.log(lesson);
      const lessonExist = module?.lessons?.find(
        (elt) => elt.title === lesson.title
      );
      if (lessonExist) return state;
      return {
        ...state,
        program: {
          ...state.program,
          courses: state.program.courses.map((course) => {
            if (course.title === courseTitle) {
              return {
                ...course,
                modules: course.modules?.map((module) => {
                  if (module.title === moduleTitle) {
                    return { ...module, lessons: [...module.lessons!, lesson] };
                  }
                  return module;
                }),
              };
            }
            return course;
          }),
        },
      };
    }

    case "quizQuestion": {
      const { question } = payload;
      return { ...state, program: { ...state.program, question } };
    }
    case "quizAnswer": {
      const { answer } = payload;
      return { ...state, program: { ...state.program, answer } };
    }
    case "quizPossibleAnswers": {
      const { possibleAnswers } = payload;
      return { ...state, program: { ...state.program, possibleAnswers } };
    }

    case "save": {
      const { save } = payload;
      return { ...state, isSave: save };
    }
    case "progress": {
      const { value } = payload;
      console.log(value);

      // const increament = 100 / 3;
      return {
        ...state,
        progress: value,
      };
    }
    case "step": {
      const { step } = payload;
      return { ...state, step };
    }

    default: {
      return state;
    }
  }
}

export const CreateTPProvider: React.FC<Props> = ({ children }) => {
  const [programState, programDispatch] = useReducer(reducer, initialvalue);
  const dispatch = ({ type, payload }: TrainingProgramAction) => {
    programDispatch({ type, payload });
  };

  return (
    <CreateTrainingProgramContext.Provider
      value={{
        isSave: programState.isSave,
        progress: programState.progress,
        step: programState.step,
        program: programState.program,
        dispatch,
      }}
    >
      {children}
    </CreateTrainingProgramContext.Provider>
  );
};

export default CreateTrainingProgramContext;
