import { CheckIcon } from "@chakra-ui/icons";
import { Flex, IconButton } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

type Props = {
  lessonTitle: string;
  lesson?: Lesson;
  path?: string | undefined;
  courseTitle?: string;
  moduleTitle?: string;
};

const Lesson = ({
  lessonTitle,
  lesson,
  path,
  courseTitle,
  moduleTitle,
}: Props) => {
  return (
    <Flex
      // key={index}
      as={path ? Link : undefined}
      state={{ lesson, courseTitle, moduleTitle }}
      to={path}
      h="3em"
      rounded="lg"
      bg="blue.50"
      p="0.5em"
      fontSize={{ base: "sm", md: "md" }}
      color="gray.500"
      mb="0.5em"
      w="full"
      alignItems={"center"}
      onClick={() => {
        scroll.scrollToTop();
      }}
    >
      <IconButton
        colorScheme="teal"
        aria-label="Call Segun"
        size="sm"
        icon={<CheckIcon />}
      />{" "}
      {lessonTitle}
    </Flex>
  );
};

export default Lesson;
