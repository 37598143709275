import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { ChakraProvider, Box, Grid } from "@chakra-ui/react";
import "./App.css";
import NavWithSubnavigation from "./components/Navbar/Navbar";
import Landing from "./Pages/Landing.page";
import Login from "./Pages/auth/Login/Login.page";
import { liahtutorTheme } from "./assets/styles/theme";
import Signup from "./Pages/auth/Signup/Signup.page";
import UserZone from "./Pages/UserZone/UserZone";
import UserHome from "./Pages/UserZone/UserHome/UserHome";
import "animate.css";
import CreateTrainingProg from "./components/CreateTrainingProg/CreateTrainingProg";
import LearnAboutCreation from "./components/LearnAboutCreation/LearnAboutCreation";
import Communication from "./components/Communication/Communication";
import Performance from "./components/Performance/Performance";
import Account from "./components/Account/Account";
import Profile from "./components/Profile/Profile";
import CreateTrainingProgramLayout from "./components/ui/CreateTrainingProgramLayout";
import PreviewtTrainingProgram from "./components/CreateTrainingProg/previewtTrainingProram/PreviewtTrainingProgram";
import ProgramDetailLayout from "./layouts/ProgramDetailLayout";
import VideoUploader from "./components/video/VideoCard";
import Layouttest from "./components/Layout/Layouttest";
import VideoCard from "./components/video/VideoCard";
import LessonFields from "./components/CreateTrainingProg/LessonFields";
import { useAppSelector } from "./app/hooks";
import Overley from "./components/Modal/Modal";
import TrainingProgramDetail from "./components/Programs/TrainingProgramDetail";
import Notfound from "./Pages/NotFound/Notfound";
import LessonDetail from "./components/Programs/Lesson/LessonDetail";
import TrainingPrograms from "./components/Programs/TrainingPrograms";
import AuthLayout from "./layouts/AuthLayout";
import Onboarding from "./Pages/auth/onboarding/Onboarding";
import Photo from "./Pages/auth/onboarding/components/Photo";
import Certification from "./Pages/auth/onboarding/components/Certification";
import Social from "./Pages/auth/onboarding/components/Social";
import Bio from "./Pages/auth/onboarding/components/Bio";
import Review from "./Pages/auth/onboarding/components/Review";
export const HeaderRoutes = () => {
  const location = useLocation();

  const excludedPaths = [
    // "/signin",
    // "/signup",
    "/userzone",
    "/profile",
    "/account/",
    "/communication",
    "/performance",
    "/create-program",
    "/learn-about-creation",
    "/forgot-password",
    "/program/",
  ];

  const shouldRenderHeader = !excludedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  // Render LandingHeader if shouldRenderHeader is true, otherwise null
  return shouldRenderHeader ? <NavWithSubnavigation /> : null;
};
export const App = () => {
  // Component to conditionally render the LandingHeader based on route

  const isModal = useAppSelector((state) => state.utility.isModalOpen);

  return (
    <HelmetProvider>
      <ChakraProvider theme={liahtutorTheme}>
        <Router>
          <Box fontFamily="Montserrat" textAlign="center" fontSize="xl">
            <Grid p={0}>
              {isModal && <Overley isOpen={isModal} onClose={() => {}} />}{" "}
              <Routes>
                <Route element={<Landing />} path="/" />
                <Route element={<AuthLayout />}>
                  <Route element={<Signup />} path="signup" />
                  <Route element={<Login />} path="signin" />
                  <Route element={<Onboarding />} path="onboarding">
                    <Route element={<Photo />} index />
                    <Route element={<Photo />} path="photo" />
                    <Route element={<Certification />} path="certification" />
                    <Route element={<Social />} path="social" />
                    <Route element={<Bio />} path="bio" />
                    <Route element={<Review />} path="review" />
                  </Route>
                </Route>
                <Route element={<UserZone />}>
                  <Route element={<UserHome />} path="/userzone" />
                  <Route
                    element={<CreateTrainingProgramLayout />}
                    path="create-program"
                  >
                    <Route index element={<CreateTrainingProg />} />
                  </Route>
                  {/* 
                  <Route element={<Layouttest />} path="/layouttest">
                    <Route
                      element={
                        <LessonFields
                          courseTitle="testCoures"
                          moduleTitle="testModule"
                        />
                      }
                      index
                    ></Route>
                  </Route> */}
                  <Route element={<ProgramDetailLayout />} path="/program">
                    <Route index element={<TrainingPrograms />} />
                    <Route
                      element={<TrainingProgramDetail />}
                      path=":programTitle"
                    />
                    {window.screen.width > 768 ? (
                      <Route element={<TrainingProgramDetail />}>
                        <Route
                          element={<LessonDetail />}
                          path=":programTitle/:lessonTitle"
                        />{" "}
                      </Route>
                    ) : (
                      <Route
                        element={<LessonDetail />}
                        path=":programTitle/:lessonTitle"
                      />
                    )}
                  </Route>
                  <Route
                    element={<LearnAboutCreation />}
                    path="learn-about-creation"
                  />
                  <Route element={<Communication />} path="communication" />
                  <Route element={<Performance />} path="performance" />
                  <Route
                    element={<LearnAboutCreation />}
                    path="learn-about-creation"
                  />
                  <Route element={<Account />} path="account/:setting" />
                  <Route element={<Profile />} path="profile" />
                </Route>
                <Route element={<Notfound />} path="*" />
              </Routes>
              {/* </Box> */}
            </Grid>
          </Box>
        </Router>
      </ChakraProvider>
    </HelmetProvider>
  );
};
