import React, { useContext, useState } from "react";
import {
  Box,
  Flex,
  Stack,
  Input,
  Textarea,
  Text,
  Image,
  RadioGroup,
  Radio,
  Center,
  Button,
  Heading,
  useToast,
} from "@chakra-ui/react";
import ImageUploader from "../ImageUploader/ImageUploader";
import { FaCodeBranch, FaLemon, FaSave } from "react-icons/fa";
import { AddIcon } from "@chakra-ui/icons";
import AddModule from "./module/AddModule";
import CreateTrainingProgramContext from "../../store/Context";
import { number } from "yup";

type Props = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  selectedImage: File | null;
  setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>;
  wantModule: string;
  setWantModule: React.Dispatch<React.SetStateAction<string>>;
  lemon: CreateLessonState;
  setLemon: React.Dispatch<React.SetStateAction<CreateLessonState>>;
  lemonArr: CreateLessonState[];
  setLemonArr: React.Dispatch<React.SetStateAction<CreateLessonState[]>>;
  displayBuilder: boolean;
  setDispBuilder: React.Dispatch<React.SetStateAction<boolean>>;
  lessonNotes: string[];
  handleNotesChange: (notes: string[]) => void;
  quizzes: Quiz[];
  addQuestion: (newQuestion: Quiz) => void;
  deleteQuestion: (index: number) => void;
  handleAddALesson: () => void;
  handleEditLesson: (name: string) => void;
  handleSaveLesson: (name: string) => void;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  // setModule: React.Dispatch<React.SetStateAction<CreateModuleSingleState>>;
  // module: CreateModuleSingleState;
};

const CreateCoursesForm = (props: Props) => {
  const {
    name,
    setName,
    description,
    selectedImage,
    setSelectedImage,
    wantModule,
    setWantModule,
    lemon,
    setLemon,
    displayBuilder,
    setDispBuilder,
    lessonNotes,
    handleNotesChange,
    quizzes,
    addQuestion,
    deleteQuestion,
    handleAddALesson,
    handleEditLesson,
    handleSaveLesson,
    editing,
    // setModule,
    lemonArr,
    // module,
  } = props;
  const Context = useContext(CreateTrainingProgramContext);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDescription, setDescription] = useState("");
  const [courseImage, setCourseImage] = useState<File | null>(null);
  const toast = useToast();
  const [course, setCourse] = useState<Course | null>(null);
  const [onCourseSave, setOnCourseSave] = useState(false);

  const handleModule = (e: any) => {
    //make sure the user has saved the course before adding a module
    if (
      courseTitle === "" ||
      courseDescription === "" ||
      courseImage === null ||
      !onCourseSave
    )
      return toast({
        title: "Course not saved",
        description: "Please save the course before adding a module",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    setWantModule(e);
  };
  const handleSaveCourse = () => {
    setOnCourseSave(true);
    //Check if the use has saved the course before adding a module else give a toast message
    if (courseTitle === "" || courseDescription === "" || courseImage === null)
      return toast({
        title: "Course not saved",
        description: "Please fill in all the fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    setCourse({
      title: courseTitle,
      description: courseDescription,
      image: courseImage,
      modules: [],
    });
    Context?.dispatch({
      payload: {
        course: {
          title: courseTitle,
          description: courseDescription,
          image: courseImage,
          lesson: [],
          modules: [],
        },
      },
      type: "addCourse",
    });
    toast({
      title: "Course Saved",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };
  return (
    <Box w="full">
      <Heading fontWeight="bold">Create a Course</Heading>
      <Text fontSize="sm" color={"gray.400"} maxW={{ base: "auto", lg: "90%" }}>
        Transform your insights into a valuable resource. Build, customize, and
        launch your course in just a few straightforward steps.
      </Text>
      <Box mt="1rem" mb="1rem">
        <>
          <Flex
            flexDir="column"
            justifyContent="space-between"
            alignItems="left"
            gap="2rem"
          >
            <Stack minW="25%">
              <Input
                // value={name}
                onChange={(e) => setCourseTitle(e.target.value)}
                w="100%"
                minH="59px"
                fontSize="sm"
                type="text"
                placeholder="Enter the name of your course"
              />
            </Stack>
            <Stack minW="70%">
              <Textarea
                // value={description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setDescription(e.target.value)
                }
                w="100%"
                fontSize="sm"
                minH="100px"
                placeholder="Describe the course, what it entails and more"
              />
            </Stack>
            <Center
              borderRadius="lg"
              p="1em"
              border="1px"
              borderColor="gray.200"
            >
              <ImageUploader
                onImageSelect={setCourseImage}
                selectImage={() => void 0}
              />
            </Center>
            <Flex>
              {/* Button to save the coure */}
              <Button
                mt="1rem"
                ml="auto"
                colorScheme="blue"
                variant="solid"
                onClick={handleSaveCourse}
              >
                Save Course
              </Button>
            </Flex>
            {/* Module Entering Block */}
            <Flex
              flexDirection="column"
              borderRadius="md"
              p="1rem"
              boxShadow="rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px"
            >
              <Flex gap="1rem" alignItems="center">
                <Flex
                  alignItems="center"
                  borderRadius="50%"
                  bg="gainsboro"
                  boxShadow="rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px"
                  justifyContent="center"
                  height="2rem"
                  width="2rem"
                >
                  <FaCodeBranch />
                </Flex>
                <Text fontWeight={400} fontSize="21px">
                  Module(s)
                </Text>
              </Flex>
              <Text mt="1rem" fontSize="12px">
                Add a Module? *
              </Text>
              <RadioGroup
                mt="1rem"
                onChange={(e) => handleModule(e)}
                value={wantModule}
              >
                <Stack fontSize="14px" gap="1rem" direction="row">
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Stack>
              </RadioGroup>

              {wantModule === "Yes" && (
                <>
                  <AddModule
                    addQuestion={addQuestion}
                    deleteQuestion={deleteQuestion}
                    quizzes={quizzes}
                    handleNotesChange={handleNotesChange}
                    handleSaveLesson={handleSaveLesson}
                    handleEditLesson={handleEditLesson}
                    handleAddALesson={handleAddALesson}
                    lemonArr={lemonArr}
                    lemon={lemon}
                    lessonNotes={lessonNotes}
                    // module={module}
                    editing={editing}
                    setLemon={setLemon}
                    displayBuilder={displayBuilder}
                    setDispBuilder={setDispBuilder}
                    course={course}
                    courseTitle={courseTitle}
                  />
                </>
              )}
            </Flex>
          </Flex>
        </>
      </Box>
    </Box>
  );
};

export default CreateCoursesForm;
