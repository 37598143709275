import { Box, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import misc from "../../assets/images/5534822.jpg";
import miscd from "../../assets/images/road.jpg";
const TrainingProgramCard = ({
  path,
  numberOfCourse,
  category,
  image,
  programTitle,
  cardWidth = {
    base: "25vw",
    md: "25vw",
  },
}: {
  path?: string;
  numberOfCourse?: number;
  category?: string;
  image?: string | undefined;
  programTitle?: string;
  cardWidth?: { base?: string; md?: string };
}) => {
  return (
    <Box
      boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px;"
      borderRadius="lg"
      border={`1px solid rgba(0, 0, 0, 0.16)`}
      as={path ? Link : undefined}
      to={path}
      _hover={{
        cursor: "pointer",
        transform: "scale(1.005)",
        boxShadow:
          "rgba(38, 70, 159, 0.02) 0px 1px 3px 0px, rgba(12, 74, 135, 0.15) 0px 0px 0px 1px",
      }}
      p="1rem"
      w={cardWidth}
      h={{
        base: "35vh",
        md: "fit-content",
      }}
    >
      <Text fontSize="sm" color="primary">
        {category || "Digital Marketing"}
      </Text>
      <Text fontSize="lg" fontWeight={600}>
        {programTitle || "Digital Marketing For Beginners"}
      </Text>
      <Box borderRadius="md" mt=".5rem" mb=".5rem">
        <Image
          borderRadius="md"
          height="100%"
          src={image || misc}
          alt="Training Program illustration"
        />
      </Box>
      <Text fontSize="sm">has {numberOfCourse} course(s)</Text>
    </Box>
  );
};

export default TrainingProgramCard;
