import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import empty from "../../../assets/images/empty-mailbox-v2.png";

export default function TrafficAndConversion() {
  return (
    <Box
      p="2rem"
      fontSize="sm"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="center"
    >
      <Flex
        mb="2rem"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid gainsboro"
      >
        <Text>To learn more, select a training program</Text>
        <Flex w="fit-content" float="right" gap="1rem" alignItems="center">
          <Select
            w="fit-content"
            fontSize="sm"
            variant="flushed"
            fontWeight={600}
          >
            <option value="last12months">Last 12 months</option>
            <option value="last7days">Last 7 days</option>
            <option value="alltime">All time</option>
          </Select>
        </Flex>
      </Flex>
      <Text>No training programs yet...</Text>
      <Text>
        Once you publish your training program, come here to learn about your
        traffic & conversion.
      </Text>

      <Button mt="1rem" variant="primary">
        Go to Dashboard
      </Button>
    </Box>
  );
}
