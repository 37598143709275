import {
  Text,
  Box,
  Image,
  Flex,
  Stack,
  HStack,
  Select,
  Button,
  Avatar,
  useBreakpointValue,
  AvatarGroup,
  useColorModeValue,
} from "@chakra-ui/react";
import miscd from "../../assets/images/road.jpg";
import { avatars } from "../CourseSimple/AddingModule";
import { FaDotCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Pagination from "../Paginations/Pagination";
import { useAppSelector } from "../../app/hooks";
import { useState } from "react";
import Filters from "../FIlters/Filters";
import TrainingProgramCard from "../Programs/TrainingProgramCard";

function HomeOfTrainings() {
  const programs = useAppSelector(
    (state) => state.trainingProgramData.trainingPrograms
  );
  const [first, setFirst] = useState(0);

  const handleNext = () => {
    //Each page shows 3 programs
    if (first + 3 < programs.length) {
      setFirst(first + 3);
    }
  };
  const handelPrevious = () => {
    if (first - 3 >= 0) {
      setFirst(first - 3);
    }
    console.log(first);
  };

  const avatarSize = useBreakpointValue({ base: "sm", md: "md" });
  return (
    <Box
      background={useColorModeValue("rgba(255, 255, 255, 0.6)", "gray.800")}
      backdropFilter="blur(8px)"
      borderRadius="lg"
      h="fit-content"
      w="100%"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;"
      p={{ base: "1rem", md: "2rem" }}
    >
      <Filters />
      <Text fontWeight={700}>Created Training Programs</Text>
      <HStack mt="1rem" gap="1rem">
        {programs?.slice(first, first + 3).map((program) => {
          // if (program.published.status === "published") {
          return (
            <TrainingProgramCard
              key={program.title}
              path={`/program/${program.title.split(" ").join("-")}`}
              numberOfCourse={program.courses.length}
              category={program.category.split("-").join(" ")}
              // image={program.image}
              programTitle={program.title}
            />
          );
          // }
        })}
      </HStack>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text
          fontSize={"xs"}
          textColor={"blue.400"}
          textDecor={"underline"}
          cursor="pointer"
          as={Link}
          to="/program"
        >
          List All
        </Text>
        <Pagination
          currentPagination={first / 3 + 1 > 0 ? Math.floor(first / 3 + 1) : 1}
          next={handleNext}
          previous={handelPrevious}
        />
      </Flex>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        gap="2rem"
        mt="3rem"
        justifyContent="space-between"
      >
        <Stack>
          <Text fontWeight={700}>Local Groups Near you</Text>
          <Box
            borderRadius="md"
            p="1rem"
            border="3px inset rgba(62, 49, 212, 0.23)"
          >
            <Flex gap="1rem" alignItems="center">
              <Avatar
                key={avatars[0].name}
                name={avatars[0].name}
                border="1px solid gainsboro"
                src={avatars[0].url}
                // eslint-disable-next-line react-hooks/rules-of-hooks
                size={avatarSize}
                position={"relative"}
                zIndex={2}
                _before={{
                  content: '""',
                  width: "full",
                  height: "full",
                  rounded: "full",
                  transform: "scale(1.125)",
                  position: "absolute",
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}
              />
              <Text fontSize="sm">Cool Tutor System</Text>
            </Flex>
            <Text mt=".4rem" fontWeight={600}>
              Emotion and Experience
            </Text>
            <Flex
              gap="1rem"
              alignItems="center"
              mb="1rem"
              fontSize="10px"
              color="grey"
            >
              <Text>3 Months ago</Text>
              <FaDotCircle />
              <Text>By Obi Divine</Text>
            </Flex>
            <hr />
            <Flex mt="1rem" gap="9rem" alignItems="center">
              <Button variant="primary">Join Group</Button>
              <AvatarGroup>
                {avatars.map((avatar) => (
                  <Avatar
                    key={avatar.name}
                    name={avatar.name}
                    src={avatar.url}
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    size={useBreakpointValue({ base: "sm", md: "lg" })}
                    position={"relative"}
                    zIndex={2}
                    _before={{
                      content: '""',
                      width: "full",
                      height: "full",
                      rounded: "full",
                      transform: "scale(1.125)",
                      bgGradient: "linear(to-bl, red.400,pink.400)",
                      position: "absolute",
                      zIndex: -1,
                      top: 0,
                      left: 0,
                    }}
                  />
                ))}
              </AvatarGroup>
            </Flex>
          </Box>
          <Box
            backgroundColor={useColorModeValue("orange.50", "gray.500")}
            borderRadius="md"
            fontSize="md"
            p="1rem"
            mt="1rem"
            border="1px solid orange"
          >
            <Text fontWeight={600}>Invite your Fellow Tutors</Text>
            <Text>
              Collaborate with up to 10 colleagues on Courses and programs
            </Text>
          </Box>
        </Stack>
        <Stack>
          <Text fontWeight={700}>Latest Articles</Text>
          <Box
            w={{ base: "100%", md: "40vw" }}
            h="fit-content"
            borderRadius="md"
            p="1rem"
            border="3px inset rgba(212, 49, 198, 0.23)"
            _hover={{
              cursor: "pointer",
              transform: "scale(1.005)",
              boxShadow:
                "rgba(38, 70, 159, 0.02) 0px 1px 3px 0px, rgba(12, 74, 135, 0.15) 0px 0px 0px 1px",
            }}
          >
            <Box h="30vh">
              <Image
                borderRadius="md"
                h="30vh"
                w="100%"
                objectFit="cover"
                src={miscd}
                alt="Training Program illustration"
              />
            </Box>
            <Flex
              gap="1rem"
              mt="1rem"
              color="gray"
              fontSize="10px"
              alignItems="center"
            >
              <Box>
                <Flex gap="1rem" alignItems="center">
                  <Avatar
                    key={avatars[1].name}
                    name={avatars[1].name}
                    border="1px solid gainsboro"
                    src={avatars[1].url}
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    size={avatarSize}
                    position={"relative"}
                    zIndex={2}
                    _before={{
                      content: '""',
                      width: "full",
                      height: "full",
                      rounded: "full",
                      transform: "scale(1.125)",
                      position: "absolute",
                      zIndex: -1,
                      top: 0,
                      left: 0,
                    }}
                  />
                  <Text>By Kelvin Nabibe</Text>
                </Flex>
              </Box>
              <FaDotCircle />
              <Text>3 days ago</Text>
              <FaDotCircle />
              <Text>8 min read</Text>
            </Flex>
            <Text mt="1rem" fontWeight={600}>
              Best practices for making a Great training program
            </Text>
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
}

export default HomeOfTrainings;
