import { Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const CardLayout = ({ children }: Props) => {
  return (
    <Box
      w="full"
      h="full"
      border="1px"
      borderColor={useColorModeValue("gray.200", "gray.700")}
      bg={useColorModeValue("white", "gray.900")}
      rounded={"lg"}
      p="0.5rem"
      // bg="white"
      overflow={"hidden"}
    >
      {children}
    </Box>
  );
};

export default CardLayout;
