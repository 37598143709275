import React from "react";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import darkOffice1 from "../../../assets/images/darkOffice3.jpg";
import bench from "../../../assets/images/bench.jpg";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link, Outlet, useLocation } from "react-router-dom";
import { act } from "react-dom/test-utils";

type Props = {};

const crumbs = [
  {
    name: "Photo",
    href: "/onboarding/photo",
  },
  {
    name: "Certification",
    href: "/onboarding/certification",
  },
  {
    name: "Social",
    href: "/onboarding/social",
  },
  {
    name: "Bio",
    href: "/onboarding/bio",
  },
  {
    name: "Review",
    href: "/onboarding/review",
  },
];
const Onboarding = (props: Props) => {
  const { pathname } = useLocation();
  const active = pathname.split("/")[2];

  return (
    <Box>
      <Box
        display={{
          base: "block",
          md: " none",
        }}
        h={"3em"}
      ></Box>
      <Box
        h={{
          base: "1.5em",
          md: "2.5em",
        }}
        bg="blue.500"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          {crumbs.map((crumb) => (
            <BreadcrumbItem key={crumb.name}>
              <BreadcrumbLink
                as={Link}
                to={crumb.href}
                textDecorationColor={"white"}
              >
                <Text
                  fontSize={{
                    base: "xs",
                    md: "sm",
                  }}
                  color={
                    crumb.name.toLocaleLowerCase() === active
                      ? "white"
                      : "gray.400"
                  }
                  fontWeight={crumb.name === active ? "semibold" : "medium"}
                >
                  {crumb.name}
                </Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir={"column"}
        minH={{
          base: "100vh",
          md: "90vh",
        }}
        bg="gray.100"
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        pos={"relative"}
        backgroundImage={useColorModeValue(bench, darkOffice1)}
      >
        <Box
          minH={{
            base: "105%",
            md: "75%",
          }}
          h="fit-content"
          w="fit-content"
          rounded={"lg"}
          pos={"relative"}
          bg={useColorModeValue("white", "gray.900")}
        >
          <Outlet />
          <Center
            gap={"0.5em"}
            pos={"absolute"}
            w="full"
            bottom={{
              base: "0.5em",
              md: "0.5em",
            }}
            p="0.5em"
          >
            {crumbs.map((crumb) => (
              <Box
                key={crumb.name}
                h={"16px"}
                w="16px"
                rounded={"full"}
                as={Link}
                to={crumb.href}
                bg={
                  crumb.name.toLowerCase() === active ? "blue.500" : "gray.300"
                }
              ></Box>
            ))}
          </Center>
        </Box>
        <Center
          gap={"0.5em"}
          // pos={"absolute"}
          w="full"
          bottom={{
            base: "0.5em",
            md: "0",
          }}
          p="0.5em"
        >
          <Text
            fontSize={{
              base: "xs",
              md: "sm",
            }}
            color={useColorModeValue("gray.500", "white")}
          >
            Privacy Policy | Terms of Service | © {new Date().getFullYear()}{" "}
            Liah Technologies
          </Text>
        </Center>{" "}
      </Box>
    </Box>
  );
};

export default Onboarding;
