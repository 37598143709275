import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Pagination from "../../Paginations/Pagination";
import { BsPencil } from "react-icons/bs";

type Props = {
  next?: () => void;
  isPagination?: boolean;
  previous?: () => void;
  content: React.ReactNode;
  title?: string;
  currentPagination?: number;
  footer?: React.ReactNode;
  justify?: string;
  toggle?: boolean;
  handleClick?: (edit?: string) => void;
  toggleSection?: React.ReactNode;
};

const LessonSection = ({
  next,
  previous,
  content,
  isPagination,
  title,
  currentPagination,
  footer,
  justify = "flex-end", //default value
  toggleSection,
  handleClick,
}: Props) => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <Box pb="2em">
      {title && (
        <Flex alignItems={"center"} w="full" mb="1em">
          <Text pr="0.5em">{title}</Text>
          {title ? (
            <BsPencil
              cursor={"pointer"}
              onClick={
                toggleSection ? handleToggle : handleClick?.bind(null, title)
              }
            />
          ) : null}{" "}
        </Flex>
      )}
      <Flex wrap={"wrap"} gap={"0.5em"}>
        {content}
      </Flex>
      {toggle && (
        <Flex className="animate__animated animate__backInUp">
          {toggleSection}
        </Flex>
      )}

      <Flex justifyContent={justify} py="0.5em" w="full">
        {isPagination ? (
          <Pagination
            next={next}
            previous={previous}
            currentPagination={currentPagination}
          />
        ) : null}

        {footer}
      </Flex>
    </Box>
  );
};

export default LessonSection;
