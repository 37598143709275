import {
  Box,
  Checkbox,
  Flex,
  Image,
  Link,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import empty from "../../../assets/images/empty-mailbox-v2.png";

export default function Announcements() {
  return (
    <Box
      p="2rem"
      background={useColorModeValue(
        "rgba(255, 255, 255, 0.06)",
        "rgba(255, 255, 255, 0.06)"
      )}
      backdropFilter="blur(7px)"
      w="fit-content"
      borderRadius="lg"
      className="animate__animated animate__fadeIn"
      m="0 auto"
      textAlign="center"
    >
      <Stack alignItems="center" m="0 auto">
        <Image w="400px" src={empty} alt="No Announcements" />
        <Text fontWeight={700}>No announcements yet</Text>
      </Stack>
      <Box m="0 auto" w="40%">
        <Text fontSize="sm">
          Here’s where you can send your students a few email announcements
          every month. Use educational emails to support your students’
          learning. Use promotional emails to market your training programs.
          <Link>Learn more </Link>
        </Text>
      </Box>
    </Box>
  );
}
