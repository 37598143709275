import React, { useState } from "react";
import {
  Box,
  Input,
  Button,
  IconButton,
  Textarea,
  HStack,
  useColorModeValue,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { HiEmojiHappy } from "react-icons/hi";
import { FaMicrophone } from "react-icons/fa";

import { IoIosSend } from "react-icons/io";

import { AttachmentIcon } from "@chakra-ui/icons";

const ForumTextArea = () => {
  const [message, setMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const toast = useToast();

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      // Replace with your send message logic
      toast({
        title: "Message Sent",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setMessage("");
    }
  };

  const handleFileAttachment = () => {
    // Add functionality to attach files
    // This is a placeholder
    toast({
      title: "File Attached",
      status: "info",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box
      background={useColorModeValue(
        "white",
        "rgba(255, 255, 255, 0.06)"
      )}
      p="4"
      border={`1px solid ${isFocused ? "#3182ce" : "gainsboro"}`}
      borderRadius="md"
    >
      <Textarea
        variant="unstyled"
        placeholder="Type a message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        size="sm"
        resize="none"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        mb="2"
      />
      <HStack alignItems="center" spacing="2" justifyContent="right">
        <Tooltip label="Record a voice">
          <IconButton
            _hover={{ cursor: "pointer" }}
            variant="styled"
            as="span"
            icon={<FaMicrophone />}
            colorScheme="gray"
            aria-label="Record a voice"
          />
        </Tooltip>
        <Input
          type="file"
          display="none"
          id="file-input"
          onChange={handleFileAttachment}
        />
        <Tooltip alignItems="center" label="Attach a file">
          <label htmlFor="file-input">
            <IconButton
              _hover={{ cursor: "pointer" }}
              variant="styled"
              as="span"
              icon={<AttachmentIcon />}
              colorScheme="gray"
              aria-label="Attach File"
            />
          </label>
        </Tooltip>
        |
        <Tooltip label="Add emoji">
          <IconButton
            variant="styled"
            _hover={{ cursor: "pointer" }}
            as="span"
            icon={<HiEmojiHappy />}
            colorScheme="gray"
            aria-label="Add Emoji"
          />
        </Tooltip>
        {/* <Spacer /> */}
        <Tooltip label="Mention">
          <Button
            variant="unstyled"
            colorScheme="gray"
            aria-label="Mention User"
          >
            @
          </Button>
        </Tooltip>
        <Tooltip label="Send message">
          <IconButton
            alignItems="center"
            _hover={{ cursor: "pointer" }}
            as="span"
            variant="styled"
            icon={<IoIosSend />}
            colorScheme="blue"
            onClick={handleSendMessage}
            aria-label="Send message"
          />
        </Tooltip>
      </HStack>
    </Box>
  );
};

export default ForumTextArea;
