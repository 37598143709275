import {
  Box,
  Text,
  Button,
  Center,
  VStack,
  Image,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import image from "./assets/verify.png";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SendRequest } from "../../../../api/sendRequest";
import { Position } from "./Certification";

type Props = {};

const Review = (props: Props) => {
  const toast = useToast();
  const navigate = useNavigate();

  const requestReview = async () => {
    const certificate = JSON.parse(
      localStorage.getItem("certificate") || "{}"
    ) as {
      category: string;
      certificate: string;
      description: string;
      institution: string;
      position: Position[];
    };
    const position = JSON.parse(
      localStorage.getItem("position") as string
    ) as Position;
    certificate.position.push(position);
    const bio = localStorage.getItem("bio") || "";
    const social = JSON.parse(localStorage.getItem("social") || "{}") as {
      x: string;
      facebook: string;
      linkedin: string;
      web: string;
      youtube: string;
    };
    const photo = localStorage.getItem("photo") || "";
    const formData = new FormData();

    formData.append("certificate", JSON.stringify(certificate));
    formData.append("bio", bio);
    formData.append("social", JSON.stringify(social));
    formData.append("photo", photo);

    const res = await SendRequest({
      method: "POST",
      url: "/request-review",
      data: formData,
    });

    if (res.error) {
      toast({
        status: "error",
        title: "Fail",
        description: res.error.message,
      });
    }
    // navigate("/");
  };

  return (
    <Center
      flex={"flex"}
      className="animate__animated animate__fadeIn"
      pt={"2em"}
    >
      {" "}
      <VStack
        w={{
          base: "90%",
          md: "50%",
        }}
      >
        <Box
          as="p"
          fontSize={{
            base: "sm",
            md: "md",
          }}
          color="gray.500"
          w={{
            base: "75%",
            md: "50%",
          }}
        >
          We will review your account and let you know how to proceed. This
          process should take a maximium of 2 working days from the time of
          submission
        </Box>
        <Box
          as="p"
          fontSize={{
            base: "sm",
            md: "md",
          }}
          color="gray.500"
          w={{
            base: "75%",
            md: "50%",
          }}
        >
          If this take longer than stated, reach us at <br />
          <Text
            color={"blue.500"}
            as="a"
            href="mailto:request@liahservices.com"
          >
            request@liahservices.com
          </Text>
        </Box>
        <VStack
          gap={"0.5em"}
          w={{
            base: "full",
            md: "50%",
          }}
          mt="2em"
          mb={"4em"}
        >
          <Button
            // bg="blue.500"
            w="full"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"blue.500"}
            _hover={{
              bg: "blue.500",
              color: "white",
              borderColor: "blue.500",
            }}
            borderStyle={"solid"}
            borderColor={"blue.400"}
            borderWidth={"2px"}
            bg={useColorModeValue("white", "gray.900")}
            onClick={requestReview}
          >
            Request for Review
          </Button>
          <Button
            as={Link}
            to={"/onboarding/bio"}
            w="full"
            bg="blue.500"
            shadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
            color={"white"}
            _hover={{
              bg: "blue.200",
            }}
          >
            Back
          </Button>
        </VStack>
      </VStack>
      <Box
        w="50%"
        justifyContent={"start"}
        alignContent={"center"}
        //Hidden on small screens
        display={{
          base: "none",
          md: "flex",
        }}
      >
        <Image
          src={image}
          h={{
            base: "300px",
            md: "500px",
          }}
          w={{
            base: "300px",
            md: "500px",
          }}
        />
      </Box>
    </Center>
  );
};

export default Review;
