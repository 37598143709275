"use client";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { user } from "../../interfaces/user";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Image,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import "./loginStyles.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LiahLogo from "../../../assets/images/Liah.a2f10f1b.svg";
import authService from "../../../services/auth.service";
import { useState, useEffect } from "react";
import { Logo } from "../../../components/common/Logo";
import LoadingScreen from "./../../../components/common/LoadingScreen";

function SimpleCard() {
  const toast = useToast();
  const [redirect, setRedirect] = useState("/userzone");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  // Define Yup validation schema
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });

  useEffect(() => {
    const currentUser: user = authService.getCurrentUser();
    if (currentUser) {
      setRedirect("/userzone");
    }
  }, [redirect]);
  // Handle form submission
  const handleSignin = (formValue: { email: string; password: string }) => {
    setLoading(true);
    const { email, password } = formValue;
    setMessage("");
    authService.signin(email, password).then(
      (response) => {
        toast({
          title: "Sign In Success",
          description: "Welcome back.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSuccessful(true)
      },
      (error) => {
        // Handle error
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSuccessful(false);
        setMessage(resMessage);
        toast({
          title: "Error in sigin in",
          description: message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
    setLoading(false);
  };

  if (successful) {
    return <Navigate to={redirect} />;
  }
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSignin}
    >
      <Form>
        <Flex
          minH={"100vh"}
          align={"center"}
          justify={"center"}
          // bg={useColorModeValue("gray.50", "gray.800")}  
        >
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Stack align={"center"}>
              <Logo />

              <Text as="h1" fontWeight={700} fontSize={"4xl"}>
                Sign in to your account
              </Text>
              <Text fontSize={"lg"} color={"gray.600"}>
                to enjoy all of our cool{" "}
                <Text color={"blue.400"}>features</Text> ✌️
              </Text>
            </Stack>
            <Box
              rounded={"lg"}
              // bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
            >
              <Stack spacing={4}>
                <FormControl id="email">
                  <FormLabel>Email address</FormLabel>
                  <Field type="email" name="email" as={Input} />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </FormControl>
                {loading && (
                  <Box className="animate__animated animate__fadeInLeftBig" pos="fixed" top="5%" left="5%" zIndex={5}>
                    <LoadingScreen />
                  </Box>
                )}
                <FormControl id="password">
                  <FormLabel>Password</FormLabel>
                  <Field type="password" name="password" as={Input} />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </FormControl>
                <Stack spacing={10}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    fontSize="sm"
                    alignItems="center"
                    justify={"space-between"}
                  >
                    <Checkbox>Remember me</Checkbox>
                    <Text
                      as={Link}
                      to="/forgot-password"
                      textDecor="underline"
                      color={"red.400"}
                    >
                      Forgot password?
                    </Text>
                  </Stack>
                  <Button
                    type="submit"
                    isLoading={loading}
                    // onClick={() => setLoading(true)}
                    loadingText="Loading"
                    colorScheme="teal"
                    variant="primary"
                    spinnerPlacement="end"
                  >
                    Sign in
                  </Button>
                </Stack>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  fontSize="sm"
                  alignItems="center"
                  justify={"space-between"}
                >
                  <Text>Don't yet have an account?</Text>
                  <Text as={Link} to="/signup" color={"blue.400"}>
                    Create one
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Form>
    </Formik>
  );
}

export default function Login() {
  return (
    <>
      <SimpleCard />
    </>
  );
}
