import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AccountSecurity from "./AccountSecurity/AccountSecurity";
import NotificationsSettings from "./NotificationsSettings/NotificationsSettings";
import MessageSettings from "./MessageSettings/MessageSettings";
import CloseAccount from "./CloseAccount/CloseAccount";

interface TabProps {
  title: string;
  active: boolean;
  onSelect: (title: string) => void;
}

function Tab({ title, active, onSelect }: TabProps) {
  const backgroundColor = useColorModeValue(
    "rgba(255, 255, 255, 0.6)",
    "gray.800"
  );
  return (
    <Box
      p="1rem"
      backgroundColor={active ? backgroundColor : "transparent"}
      borderRadius="md"
      _hover={{
        cursor: "pointer",
        backgroundColor,
      }}
      onClick={() => onSelect(title)}
    >
      {title}
    </Box>
  );
}

function Account() {
  const { setting } = useParams();

  // Mapping between route parameters and tab titles
  const tabMapping: Record<string, string> = {
    "me": "Account security",
    "notifications": "Notifications settings",
    "messages": "Message settings",
    "close": "Close account",
  };

  const defaultActiveTab = "Account security"; // Provide a default tab
  const [activeTab, setActiveTab] = useState<string>(
    tabMapping[setting || defaultActiveTab] // Use the default value if `setting` is undefined
  );

  const handleTabSelect = (tabTitle: string) => {
    setActiveTab(tabTitle);
  };

  return (
    <Box textAlign="center" m="0 auto">
      <Box h="2vh" />
      <Flex
        fontSize="sm"
        border="1px solid gainsboro"
        w="fit-content"
        borderRadius="md"
        m="0 auto"
        boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;"
      >
        <Tab
          title="Account security"
          active={activeTab === "Account security"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Notifications settings"
          active={activeTab === "Notifications settings"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Message settings"
          active={activeTab === "Message settings"}
          onSelect={handleTabSelect}
        />
        <Tab
          title="Close account"
          active={activeTab === "Close account"}
          onSelect={handleTabSelect}
        />
      </Flex>
      <Box h="2vh" />
      {activeTab === "Account security" && <AccountSecurity />}
      {activeTab === "Message settings" && <MessageSettings />}
      {activeTab === "Notifications settings" && <NotificationsSettings />}
      {activeTab === "Close account" && <CloseAccount />}
    </Box>
  );
}

export default Account;
